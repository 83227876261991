import React from "react";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import moment from "moment";

function WfsTable({
  setOpenAddWfsDialog,
  setOpenEditWfsDialog,
  setPageNumber,
  setPageSize,
  setFilterLabel,
  setFilterSearchText,
  setSortLabel,
  setSortOrder,
  wfsList,
  setSelectedRowForEdit,
}) {
  ////Functions
  const handleWfsFilterClose = ({ value, searchText }) => {
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };

  const handleWfsSortClose = ({ value, searchText }) => {
    setSortLabel(value);
    setSortOrder(searchText);
  };
  // Add Wfs details
  const handleAddWfs = () => {
    setOpenAddWfsDialog(true);
  };
  // Edit Wfs details
  const handleWfsRowClick = (index, row) => {
    setSelectedRowForEdit(row);
    setOpenEditWfsDialog(true);
  };

  return (
    <>
      <CustomTable
        tableHead={[
          "Company",
          "Facility",
          "Incharge",
          "PAN",
          "Phone",
          "Created",
          "Orders",
        ]}
        tableData={wfsList}
        tableDataKey={[
          "wfs_name",
          "facility_name",
          "wfs_incharge.name",
          "wfs_details_json.company_pan_number",
          "wfs_incharge.phone_number",
          {
            rowClickEnable: true,
            component: (props) => {
              console.log("propss", props);
              return moment(props?.row?.created_at).format("DD-MM-YYYY");
            },
          },

          {
            component: ({ row }) => {
              return <CustomButton label="Order" />;
            },
          },
        ]}
        filter={true}
        filterSelectOption={[
          { value: "wfs_name", label: "Company" },
          { value: "phone_number", label: "Phone" },
        ]}
        handleFilterClose={handleWfsFilterClose}
        sort={true}
        sortSelectOption={[
          { value: "created_at_sort", label: "Created at" },
          { value: "wfs_name_sort", label: "WFS name" },
          { value: "facility_name_sort", label: "Facility name" },
        ]}
        handleSortClose={handleWfsSortClose}
        addButton={true}
        handleOnClickAddButton={handleAddWfs}
        tableRowClickFunction={handleWfsRowClick}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      />
    </>
  );
}

export default WfsTable;
