import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function CustomSelectForTable({
  name,
  placeHolder,
  input = [],
  handleChange,
  getNameAndValueOnChange,
  defaultValue,
  variant = "standard",
  sx,
}) {
  const handleOnChange = (event) => {
    getNameAndValueOnChange(name, event.target.value);
  };
  return (
    <FormControl sx={{ ...sx }} variant={variant} size="small">
      <InputLabel id="demo-select-small-label">
        {placeHolder || name}
      </InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        name={placeHolder || name}
        value={defaultValue}
        label={placeHolder}
        onChange={getNameAndValueOnChange ? handleOnChange : handleChange}
      >
        {input?.map(({ value, menuItem }, index) => {
          return <MenuItem value={index}>{menuItem}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

export default CustomSelectForTable;
