import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  TextField,
  DialogActions,
  Drawer,
  Box,
} from "@mui/material";
import {
  GetCreateBatch,
  UpdateCreateBatch,
} from "../../Redux/Actions/adddCreateBatchAction";
import { useDispatch } from "react-redux";
import appStyle from "../../AppMeta/appStyle";
import CustomButton from "../../Components/CustomComp/CustomButton";

export function EditDialogBatch({ open, onClose, item }) {
  const dispatch = useDispatch();

  //1.useState
  const [quantity, setQuantity] = React.useState("");
  const [batchId, setBatchId] = React.useState("");

  //2.useEffect
  React.useEffect(() => {
    console.log("item");
    console.log(item);
    setQuantity(item.quantity);
    setBatchId(item.batch_id);
  }, []);

  const handleUpdate = () => {
    const reqObj = {
      batch_id: batchId,
      quantity: quantity,
    };
    console.log("ReqObj");
    console.log(reqObj);
    dispatch(UpdateCreateBatch(reqObj));
    dispatch(GetCreateBatch());
  };
  return (
    <div>
      <Drawer
        open={open}
        anchor="right"
        PaperProps={{
          sx: {
            width: 250, // Set the width here
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              m: 1,
              textAlign: "center",
              fontWeight: 800,
              fontSize: "20px",
            }}
          >
            Batch ID: {batchId}
          </Typography>

          <TextField
            margin="dense"
            label="Edit Quantity"
            fullWidth
            variant="standard"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            sx={{ m: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CustomButton handleClick={onClose} label="Cancel" color="error" />
            <CustomButton handleClick={handleUpdate} label="Cancel" />
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}
