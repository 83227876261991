/**
 * @author Janarthanan
 * @description This file contains all app fonts style
 * @date 18/10/24
 * @version 0.0.1 - Initial Release
 * @param {}
 * @returns {UI og Authetication Page with LogIn & SignUp Components}
 */

import appStyle from "./appStyle";

const appFonts = {
  // tableHeadFont: "bold",      //See appStyle.js for table font styling
  // tableCellFont: "text",
  title: "title",
  navbarMenu: "subTitle",
  subTitle: "subTitle",
  cardTitle: "bold",
  tablePrimaryButtonVarient: "contained",
  tablePrimaryButtonColor: "secondary",
  tableSecondaryButtonVarient: "contained",
  tableSecondaryButtonColor: "secondary",
  tableButtonSize: "small",
};

export default appFonts;
