import { Box, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import WfsTable from "./WfsTable";
import CustomDialog from "../../../Components/CustomComp/CustomDialog";
import AddWfsForm from "./AddWfsForm";
import EditWfsForm from "./EditWfsForm";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { wfsApis } from "../../../Redux/Actions/wfsAction";
// import { GetWFS } from "../../../Redux/Actions/wfsDetailsAction";

function WFSindex() {
  //hooks
  const dispatch = useDispatch();
  const wfsList = useSelector((state) => state.wfsDetails.wfsList).data;

  //state variables
  const [openAddWfsDialog, setOpenAddWfsDialog] = useState(false);
  const [openEditWfsDialog, setOpenEditWfsDialog] = useState(false);

  //states for tables
  const [pageNumber, setPageNumber] = useState();
  const [pageSize, setPageSize] = useState();
  const [filterLabel, setFilterLabel] = useState("");
  const [filterSearchText, setFilterSearchText] = useState("");
  const [sortLabel, setSortLabel] = useState();
  const [sortOrder, setSortOrder] = useState("ASC");
  const [selectedRowForEdit, setSelectedRowForEdit] = useState({});

  ///Functions
  const getWfs = () => {
    dispatch(
      HandleApiActions({
        ...wfsApis.get_wfs_list,
        params: {
          page_number: pageNumber,
          page_size: pageSize,
          ...(filterSearchText !== "" && { [filterLabel]: filterSearchText }),
          ...(sortLabel !== undefined && { [sortLabel]: sortOrder }),
        },
        show_toast: false,
      })
    );
  };

  //useEffects
  useEffect(() => {
    getWfs();
  }, [pageNumber, pageSize, filterSearchText, sortOrder, sortLabel]);

  return (
    <Box>
      {/* View wfs */}
      <WfsTable
        setOpenAddWfsDialog={setOpenAddWfsDialog}
        setOpenEditWfsDialog={setOpenEditWfsDialog}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        setFilterLabel={setFilterLabel}
        setFilterSearchText={setFilterSearchText}
        setSortLabel={setSortLabel}
        setSortOrder={setSortOrder}
        wfsList={wfsList}
        setSelectedRowForEdit={setSelectedRowForEdit}
      />

      {/* Add wfs */}
      <Drawer
        open={openAddWfsDialog}
        anchor="right"
        PaperProps={{
          sx: {
            width: 450, // Set the width here
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
          },
        }}
        handleClose={() => {
          setOpenAddWfsDialog(false);
        }}
      >
        <AddWfsForm setOpenAddWfsDialog={setOpenAddWfsDialog} getWfs={getWfs} />
      </Drawer>

      {/* Edit wfs */}
      <Drawer
        open={openEditWfsDialog}
        anchor="right"
        PaperProps={{
          sx: {
            width: 500, // Set the width here
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
          },
        }}
        handleClose={() => {
          setOpenEditWfsDialog(false);
        }}
      >
        <EditWfsForm
          setOpenEditWfsDialog={setOpenEditWfsDialog}
          getWfs={getWfs}
          selectedRowForEdit={selectedRowForEdit}
        />
      </Drawer>
    </Box>
  );
}

export default WFSindex;
