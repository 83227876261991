import React, { createContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Box,
  useTheme,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { useSelector } from "react-redux";
import { appColors } from "../../AppMeta/appColors";
import CustomDialogMoreDetailsWaterFilling from "../../Components/CustomComp/CustomDialogMoreDetailsWaterFilling";
import { EditDialogBatch } from "./EditDialogBatch";

import {
  GetCreateBatch,
  StartBatch,
  StopBatch,
} from "../../Redux/Actions/adddCreateBatchAction";
import { useDispatch } from "react-redux";
import { CancelDialogBatch } from "./CancelDialogBatch";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import CustomScannerDialog from "../../Components/CustomComp/CustomScannerDialog";
import { ScannerDialog } from "./ScannerDialog";
import { tokens } from "../../AppMeta/theme";
import BadgeIcon from "@mui/icons-material/Badge";
import appStyle from "../../AppMeta/appStyle";
import appFonts from "../../AppMeta/appFonts";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomTable from "../../Components/CustomComp/CustomTable";
import CustomButton from "../../Components/CustomComp/CustomButton";
import ScanningDialog from "../../Components/CustomComp/ScanningDialog";

export const UserContext = createContext();

export default function BatchDetailsTable({ batchList, setDrawOpenProp }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const batchDetails = useSelector((state) => state.batchData);
  console.log(wfsDetails);
  console.log(batchList);

  //1.useState
  const [openCustomDialog, setOpenCustomDialog] = React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState("");
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [batchData, setBatchData] = React.useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [openLinkDialog, setOpenLinkDialog] = React.useState(false);
  const [currentScanned, setCurrentScanned] = React.useState(
    JSON.parse(localStorage.getItem("current_scanned_codes"))
  );
  // Scanning new
  const isBatch = true;
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [scanOrderId, setScanOrderId] = React.useState(null);
  const [requestObject, setRequestObject] = React.useState({});
  const [validateExpired, setValidateExpired] = React.useState([]);
  const [submitBtnFlg, setSubmitBtnFlg] = React.useState(false);
  const [scannedCount, setScannedCount] = React.useState(0);
  const [ownerDetails, setOwnerDetails] = React.useState([]);
  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] =
    React.useState(false);
  const [validData, setValidData] = React.useState([]);
  const [invalidData, setInvalidData] = React.useState([]);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [selectedBatchId, setSelectedBatchId] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [filterLabel, setFilterLabel] = React.useState("device_id");
  const [filterSearchText, setFilterSearchText] = React.useState("");
  //2.useEffect Hook
  React.useEffect(() => {
    if (currentScanned?.length) {
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScanned)
      );
    }
    // const current_scanned_stored = JSON.parse(localStorage.getItem("current_scanned_codes")) || []
  }, [currentScanned]);
  React.useEffect(() => {
    // dispatch(GetCreateBatch()).then(() => {
    //   console.log("Batch Data");
    //   console.log(batchDetails);
    // });
    dispatch(
      GetCreateBatch({
        page_number: 1,
        page_size: 25,
        [filterLabel]: filterSearchText || undefined,
      })
    );
  }, [pageNumber, pageSize, filterSearchText]);

  React.useEffect(() => {
    console.log("batchDetails");
    console.log(batchDetails);
    setBatchData(batchDetails?.batchData);
  }, [batchDetails]);

  //Pagination for table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = (data) => {
    console.log("dataaasdd", data);
    setEditDialogOpen(false);
    setCancelDialogOpen(false);
    //setCurrentItem(null); // Clear the current item when dialog is closed
  };
  const handleEditClick = (details) => {
    console.log("Details");
    console.log(details);
    setEditDialogOpen(true);
    setCurrentItem(details);
  };

  const handleCancelClick = (details) => {
    console.log("Cancel", details);
    console.log(cancelDialogOpen);
    setCancelDialogOpen(true);
    setCurrentItem(details);
  };

  const handleShowMore = (details) => {
    console.log("details");
    console.log(details);
    setOpenCustomDialog(true);
    setMoreDetails(details);
  };
  const handleStartBatch = (batch) => {
    console.log("batchh");
    console.log(batch);
    setSelectedBatchId(batch.batch_id);
    setOpenConfirmationDialog(true);
  };

  const handleStopBatch = (batch) => {
    console.log("-----batchh------", batch);
    setSelectedBatchId(batch.batch_id);
    setOpenConfirmationDialog(true);
  };
  const handleLinking = (data) => {
    // setOpenLinkDialog(true);
    console.log("----data--------", data);
    setOpenScanningDialog(true);
    setScanQtyMax(data?.quantity - data?.linked_container_qty);
    setScanOrderId(data?.batch_id);
  };

  const handleBatchDetailFilter = ({ value, searchText }) => {
    console.log(value, searchText);
    setFilterLabel(value);
    setFilterSearchText(searchText);
  };
  const handleBatchDetailSort = ({ value, searchText }) => {
    console.log(value, searchText);
  };

  const handleClosestartbatch = () => {
    setOpenConfirmationDialog(false);
  };

  const handleStartConfirmDialog = () => {
    // setOpenConfirmationDialog(false);

    const reqObj = {
      batch_id: selectedBatchId,
    };
    console.log("ReqObj");
    console.log(reqObj);
    dispatch(StartBatch(reqObj));
    setOpenConfirmationDialog(false);
    setTimeout(() => {
      dispatch(GetCreateBatch());
    }, 1000);
  };
  const handleStopConfirmDialog = () => {
    console.log("------setIsConfirmed--------");
    const reqObj = {
      batch_id: selectedBatchId,
    };
    console.log("ReqObj");
    console.log(reqObj);
    dispatch(StopBatch(reqObj));
    setOpenConfirmationDialog(false);
    setTimeout(() => {
      dispatch(GetCreateBatch());
    }, 1000);
    // setOpenConfirmationDialog(false);
  };
  const handleClosestopbatch = () => {
    setOpenConfirmationDialog(false);
  };

  const handleRestDialogClose = () => {
    setOpenConfirmationDialog(false);
  };
  const handleRestConfirmDialog = () => {
    localStorage.removeItem("current_scanned_codes");
    setCurrentScanned({});
    setScannedCount(0);
    setOpenConfirmationDialog(false);
  };
  return (
    <Box>
      <CustomTable
        tableHead={[
          "Batch ID",
          "Batch Quantity",
          "Batch Started",
          "Batch Stopped",
          "Capacity",
          "Material",
          "Link",
          "Batch Status",
          "More Details",
          "Cancel",
        ]}
        tableData={batchData}
        tableDataKey={[
          "batch_id",
          "quantity",
          {
            description: "Is Batch Started",
            rowClickEnable: true,
            component: ({ row }) => {
              return row.is_batch_started ? "Started" : "Not Started";
            },
          },
          {
            description: "Is Batch Stopped",
            rowClickEnable: true,
            component: ({ row }) => {
              return row.is_batch_stopped ? "Yes" : "No";
            },
          },
          "container_capacity",
          "container_material",
          {
            description: "Link",
            component: ({ row }) => {
              return (
                <Box>
                  {row?.linked_container_qty !== row?.quantity &&
                  row.is_batch_started === false &&
                  row.is_batch_stopped === false ? (
                    <CustomButton
                      handleClick={() => handleLinking(row)}
                      label={"LINK"}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              );
            },
          },
          {
            description: "Batch status",
            component: ({ row }) => {
              console.log("---Batch status---", row);
              return (
                <Box sx={appStyle.general.button.editAndCancelButton.box}>
                  {row.is_batch_started === false && (
                    <CustomButton
                      handleClick={() => handleStartBatch(row)}
                      label={"START"}
                    />
                  )}

                  {row.is_batch_stopped === false && (
                    <CustomButton
                      handleClick={() => handleStopBatch(row)}
                      color="error"
                      label={"STOP"}
                    />
                  )}
                </Box>
              );
            },
          },
          {
            description: "More Details",
            component: ({ row }) => {
              return (
                <Box sx={appStyle.general.button.editAndCancelButton.box}>
                  <CustomButton
                    handleClick={() => {
                      handleShowMore(row);
                    }}
                    label={"MORE"}
                  />
                </Box>
              );
            },
          },
          {
            description: "Cancel",
            component: ({ row }) => {
              return (
                <Box sx={appStyle.general.button.editAndCancelButton.box}>
                  <CustomButton
                    handleClick={() => handleCancelClick(row)}
                    color="error"
                    label={"Cancel"}
                  />
                </Box>
              );
            },
          },
        ]}
        filter={true}
        filterSelectOption={[{ value: "batch_id", label: "Batch ID" }]}
        handleFilterClose={handleBatchDetailFilter}
        sort={true}
        sortSelectOption={[{ value: "created_at", label: "Created at" }]}
        handleSortClose={handleBatchDetailSort}
        addButton={true}
        handleOnClickAddButton={() => {
          setDrawOpenProp(true);
        }}
        tableRowClickFunction={(index, row) => {
          handleEditClick(row);
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      />

      {openCustomDialog && (
        <CustomDialogMoreDetailsWaterFilling
          openCustomDialog={openCustomDialog}
          setOpenCustomDialog={setOpenCustomDialog}
          title="More details"
          moreDetails={moreDetails}
        />
      )}
      {editDialogOpen && (
        <EditDialogBatch
          open={editDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
          onClose={handleClose}
          item={currentItem}
        />
      )}
      {cancelDialogOpen && (
        <CancelDialogBatch
          open={cancelDialogOpen}
          onClose={handleClose}
          setCancelDialogOpen={setCancelDialogOpen}
          item={currentItem}
        />
      )}
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          open={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          dialogContent={"Are you sure want to START batch?"}
          setIsConfirmed={setIsConfirmed}
          onClose={handleClosestartbatch}
          onConfirm={handleStartConfirmDialog}
        />
      )}

      {openConfirmationDialog && (
        <CustomConfirmationDialog
          open={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          dialogContent={"Are you sure want to STOP batch?"}
          setIsConfirmed={setIsConfirmed}
          onClose={handleClosestopbatch}
          onConfirm={handleStopConfirmDialog}
        />
      )}
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          open={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          dialogContent={"Are you sure you want to link from the beginning?"}
          setIsConfirmed={setIsConfirmed}
          onClose={handleRestDialogClose}
          onConfirm={handleRestConfirmDialog}
        />
      )}
      {/* {openLinkDialog && (
        <ScannerDialog
          open={openLinkDialog}
          setOpenLinkDialog={setOpenLinkDialog}
          currentScanned={currentScanned}
          setCurrentScanned={setCurrentScanned}
          validData={validData}
          invalidData={invalidData}
          setInvalidData={setInvalidData}
          setValidData={setValidData}
        />
      )} */}
      <ScanningDialog
        setOpenScanningDialog={setOpenScanningDialog}
        openScanningDialog={openScanningDialog}
        scannedQrBarcode={scannedQrBarcode}
        setScannedQrBarcode={setScannedQrBarcode}
        currentScanned={currentScanned}
        setCurrentScanned={setCurrentScanned}
        scanQtyMax={scanQtyMax}
        scanOrderId={scanOrderId}
        scannedCount={scannedCount}
        setScannedCount={setScannedCount}
        setRequestObject={setRequestObject}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        isBatch={isBatch}
        validateExpired={validateExpired}
        setSubmitBtnFlg={setSubmitBtnFlg}
      />
    </Box>
  );
}
