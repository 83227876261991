/**
 * @author Vinay
 * @date 14-11-2024
 * @description Spinner Reducer -> Based on the Spinner Action it change the state of the spinner variable in store
 */
import actionTypes from "../actionTypes";
const initialState = {
  error: "",
  msg: "",
  isLoading: false,
  loadingCount: 0,
  totalLoadingCounts: 0,
};
const spinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SPINNER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SPINNER_LOADED:
      return {
        ...state,
        isLoading: false,
        loadingCount: state.loadingCount + 1,
      };
    default:
      return state;
  }
};

export default spinnerReducer;
