import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
import moment from "moment";

function AddIotDeviceDialog(props) {
  console.log("props", props);

  const handleAddIot = (data) => {
    console.log("---form data----", data);
    props.handleAddIot(data);
  };
  const handleCloseAddIot = () => {
    props.handleCloseAddIot();
  };

  return (
    <Drawer
      open={props.openAddIotDialog}
      anchor="right"
      PaperProps={{
        sx: {
          width: 250, // Set the width here
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
        },
      }}
    >
      <Typography align="center" sx={{ fontWeight: 800, fontSize: "25px" }}>
        ADD IOT DEVICE
      </Typography>
      <CustomForm
        data={[
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Device Id",
            defaultValue: props.selectedIotDetails?.device_id,
            name: "device_id",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: props.selectedIotDetails?.device_id ? true : false,
            disabled: props.selectedIotDetails?.device_id ? true : false,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Batch Number",
            defaultValue: props.selectedIotDetails?.batch_number,
            name: "batch_number",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
            disabled: props.selectedIotDetails?.batch_number ? true : false,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Line Id",
            defaultValue: props.selectedIotDetails?.line_id,
            name: "line_id",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
            disabled: props.selectedIotDetails?.line_id ? true : false,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Sim Card Number",
            defaultValue: props.selectedIotDetails?.sim_number,
            name: "sim_number",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
            disabled: props.selectedIotDetails?.sim_number ? true : false,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Mft Date",
            defaultValue:
              props.selectedIotDetails?.mft_date || moment().format(),
            name: "mft_date",
            // type: "date",
            textFieldType: "date",
            isHintRequired: true,
            isRequired: true,
            disabled: props.selectedIotDetails?.mft_date ? true : false,
          },
          {
            inputType: appMeta.inputFieldTypes.SWITCH,
            placeHolder: { on: "Active", off: "Deactive" },
            name: "is_active",
            defaultValue: props.selectedIotDetails?.is_active || false,
            disabled: props.selectedIotDetails?.is_active ? true : false,
          },
        ]}
        handleSubmit={handleAddIot}
        handleClose={handleCloseAddIot}
      />
    </Drawer>
  );
}

export default AddIotDeviceDialog;
