import { Box, Typography } from "@mui/material";

import React from "react";

function filterResults(results) {
  let filteredResults = [];
  for (var i = 0; i < results.length; ++i) {
    if (i === 0) {
      filteredResults.push(results[i]);
      continue;
    }

    if (results[i].decodedText !== results[i - 1].decodedText) {
      filteredResults.push(results[i]);
    }
  }
  return filteredResults;
}

const ResultContainerTable = ({ data }) => {
  const results = filterResults(data);
  return (
    <Box>
      {results.map((result, i) => {
        console.log(result);
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Scanned Code: {result.decodedText}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Code : {result.result.format.formatName}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const ResultContainerPlugin1 = (props) => {
  // const results = filterResults(props.results);
  const results =
    props.scanType === "camera" ? filterResults(props.results) : props.results;
  console.log(results.length);
  props.setScanCount(results.length);
  return (
    <div className="Result-container">
      {/* <div className="Result-header">Scanned results ({results.length})</div> */}
      <div className="Result-section">
        {/* <ResultContainerTable data={results} /> */}
      </div>
    </div>
  );
};

export default ResultContainerPlugin1;
