import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import TwentyLiter from "../../../../Assests/FOM/bookwater-story.png";
import CurrencyRupee from "@mui/icons-material/CurrencyRupee";
import { useTheme } from "@mui/styles";
import CratesImg from "../../../../Assests/FOM/Supply Crate.webp";
import TwentyLiter1 from "../../../../Assests/FOM/bookwaternewcan.png";
import { tokens } from "../../../../AppMeta/theme";
import WaterCan from "../../../../AppMeta/AppImages/WaterCanImg.png";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const Card = ({ name, rate, count, onClickMinus, onClickPlus, capacity }) => {
  const theme = useTheme();
  const colors = tokens(theme?.palette?.mode);

  const handleChangeButton = () => {
    setButtonChange((prevState) => !prevState);
  };
  const [buttonChange, setButtonChange] = React.useState(false);
  return (
    <Box
      mt="15px"
      backgroundColor={colors.primary[400]}
      sx={{
        width: { xs: "100%", sm: "350px" },
        height: "100px",
        boxShadow: 3,
        borderRadius: "10px",
        display: "inline-flex",
      }}
    >
      <img src={WaterCan} style={{ padding: "5px" }} />
      <Box sx={{ padding: "10px", flexGrow: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          {name}
        </Typography>
        <Typography variant="h5"> &#8377;{rate}</Typography>
        {buttonChange && (
          <IconButton
            onClick={handleChangeButton}
            aria-label="delete"
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        )}
        {!buttonChange && (
          <>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Ordered Qty: {capacity}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export function OrderSummary({
  setShowProductsDetails,
  showProductsDetails,
  productName,
  price,
  priceDetails,
  setShowCratesDetails,
  showCratesDetails,
}) {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    console.log("Price Details");
    console.log(priceDetails);
  }, []);

  return (
    <>
      <Box>
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              // margin: "20px",
              fontSize: { xs: "1rem", md: "1.2rem" },
              fontWeight: 900,
            }}
          >
            Order Summary
          </Typography>
        </Box>
        {/* <Box
          sx={{
            width: "350px",
            marginLeft: "5px",
            fontSize: "20px",
          }}
        >
          <Button
            sx={{
              fontSize: "1.1rem",
              fontWeight: 600,
            }}
            variant="filled"
            size="small"
            onClick={() => setShowProductsDetails(!showProductsDetails)}
            endIcon={
              showProductsDetails ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpRoundedIcon />
              )
            }
          >
            Product Details
          </Button>
        </Box>
        {showProductsDetails && (
          <>
            <Box>
              <Card
                name={productName}
                rate={priceDetails.total_product_price}
                capacity={priceDetails.order_qty}
              />
            </Box>
          </>
        )} */}
        <Box
          sx={{
            marginLeft: "5px",
            marginTop: "20px",
          }}
        >
          <Button
            sx={{
              fontSize: "1.1rem",
              fontWeight: 600,
            }}
            variant="filled"
            size="small"
            onClick={() => setShowCratesDetails(!showCratesDetails)}
            endIcon={
              showCratesDetails ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpRoundedIcon />
              )
            }
          >
            Product Details
          </Button>
        </Box>

        {showCratesDetails && (
          <>
            {priceDetails.crates.map((crate) => (
              <React.Fragment key={crate.id}>
                <Box>
                  <Card
                    name={crate.name}
                    rate={crate.total_price}
                    capacity={crate.order_qty}
                  />
                </Box>
              </React.Fragment>
            ))}
          </>
        )}
      </Box>
    </>
  );
}
