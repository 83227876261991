import appStyle from "../AppMeta/appStyle";

export const appColors = {
  primary: "white",
  secondary: "#3f51b5",
};
export const buttons = {
  greenDefault: appStyle.general.button.blueButton,
  greenHover: "#92bbf9",
};
{
  /*background: 'linear-gradient(214deg, rgba(255,254,254,1) 64%, rgba(8,144,226,1) 71%, rgba(51,183,251,1) 75%, rgba(51,217,251,1) 79%, rgba(255,255,255,1) 83%, rgba(51,146,251,1) 89%, rgba(0,14,255,1) 93%, rgba(0,14,255,1) 93%, rgba(255,255,255,1) 98%)'*/
}
