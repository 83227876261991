import { Box, Typography, Divider, Button, useTheme } from "@mui/material";
import CurrencyRupee from "@mui/icons-material/CurrencyRupee";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tokens } from "../../../../AppMeta/theme";
import { useDispatch } from "react-redux";
import {
  CreateFacilityAction,
  GetFacilityAction,
} from "../../../../Redux/Actions/facilityAction";
import CustomButton from "../../../../Components/CustomComp/CustomButton";

export function PriceDetails({
  priceDetails,
  returnCount,
  selectedProduct,
  selectedCrates,
  count,
  totalPrize,
  handleCloseNewOrder,
  price,
  productCgst,
  productSgst,
  totalCratesPrize,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [totalProductPrize, setProductPrize] = React.useState(0);
  const [totalCratePrize, setTotalCratePrize] = React.useState(0);
  console.log("totalCratePrize", totalCratePrize);
  React.useEffect(() => {
    console.log("priceDetails", priceDetails);
    setProductPrize(
      (priceDetails.total_product_price * (productCgst + productSgst)) / 100 +
        priceDetails.total_product_price
    );
    setTotalCratePrize(
      priceDetails?.crates?.map(
        (data) => data.total_price + productCgst * productSgst
      )
    );
  }, []);

  const handleClickPlaceOrder = () => {
    const reqObj1 = {
      container_master_id: selectedProduct.product_details.id,
      container_material: selectedProduct.product_details.material,
      container_capacity: selectedProduct.product_details.capacity,
      order_qty: count,
      return_qty: returnCount,
      payment_type: 100,
      payment_method: 0,
      po_file: "",
      crates: Object.keys(selectedCrates).map((crateId) => {
        const crate = selectedCrates[crateId];
        console.log("crate");
        console.log(crate);
        return {
          name: crate.name,
          order_qty: crate.quantity,
        };
      }),
    };
    const reqObj = {
      facility_id: 28,
      order_id: "FCORD001",
      order_type: 100,
      pincode: "123456",
      booking_date: "2024-11-25T10:30:00Z",
      delivery_date: "2024-11-27T15:00:00Z",
      final_price: 1500.5,
      delivey_status: 0,
      //   "delivery_address": {
      //     "address_line1": "123 Main St",
      //     "address_line2": "Suite 101",
      //     "city": "Mumbai",
      //     "state": "Maharashtra",
      //     "country": "India",
      //     "zip_code": "400001"
      //   },
      payment_type: 2,
      reject_reason: "",
      payment_details: {
        transaction_id: "TXN123456789",
        payment_gateway: "PayTM",
      },
      booking_type: "standard",
      delivery_challan: "CHLN98765",
      country: "INDIA",
      products: [
        { product_id: 11, quantity: 20 },
        { product_id: 10, quantity: 2 },
      ],
    };

    console.log("reqObj");
    console.log(reqObj);
    if (reqObj.order_qty > 0) {
      dispatch(CreateFacilityAction(reqObj));
      setTimeout(() => {
        dispatch(GetFacilityAction());
      }, 1000);
      handleCloseNewOrder();
    } else {
      console.log("---selsect----");
      toast.error("Enter Proper Quantity", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    // toast.success("Order Placed", {
    //   position: "top-right",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
  };

  return (
    // <>
    //   <Box
    //   >
    //      <Box
    //       sx={{
    //         alignItems: "center",
    //         justifyContent: "center",
    //         display: "flex",
    //       }}
    //     >
    //       <Typography
    //         sx={{
    //           textAlign: "center",
    //           margin: "20px",
    //           fontSize: { xs: "1rem", md: "1.5rem" },
    //           fontWeight: "bold",
    //         }}
    //       >
    //         Price Details
    //       </Typography>
    //     </Box>
    //     <Box sx={{ padding: "10px" }}>
    //       <Box
    //         sx={{
    //           justifyContent: "space-between",
    //           alignSelf: "flex-end",
    //           display: "flex",
    //         }}
    //       >
    //         <Typography
    //           sx={{ fontWeight: "bold",  fontSize: {xs:"0.8rem",md:"1.2rem"}, }}
    //           variant="subtitle1"
    //         >
    //           Total Products Price
    //         </Typography>

    //         <Box
    //           sx={{
    //             alignSelf: "flex-end",
    //             display: "flex",
    //           }}
    //         >
    //           <CurrencyRupee sx={{ marginTop: "5px" }} />
    //           <Typography
    //             sx={{
    //               fontSize: {xs:"0.8rem",md:"1.2rem"},
    //               marginTop: "5px",
    //               textAlign: "right",
    //               fontWeight: "bold",
    //             }}
    //           >
    //             {priceDetails.total_product_price}
    //           </Typography>
    //         </Box>
    //       </Box>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //         }}
    //       >
    //         <Typography sx={{ fontSize: {xs:"0.7rem",md:"1.1rem"} }} variant="subtitle1">
    //           Total Products Quantity
    //         </Typography>
    //         <Typography
    //           sx={{ fontWeight: "bold", fontSize: {xs:"0.7rem",md:"1.1rem"} }}
    //           variant="subtitle1"
    //         >
    //           {priceDetails.order_qty}
    //         </Typography>
    //       </Box>
    //     </Box>

    //     {priceDetails.crates.map((crate) => (
    //       <React.Fragment key={crate.id}>
    //         <Box sx={{ padding: "10px" }}>
    //           <Box
    //             sx={{
    //               justifyContent: "space-between",
    //               alignSelf: "flex-end",
    //               display: "flex",
    //             }}
    //           >
    //             <Typography
    //               sx={{ fontWeight: "bold",  fontSize: {xs:"0.8rem",md:"1.2rem"} }}
    //               variant="subtitle1"
    //             >
    //               Total Crates Price
    //             </Typography>

    //             <Box
    //               sx={{
    //                 alignSelf: "flex-end",
    //                 display: "flex",
    //               }}
    //             >
    //               <CurrencyRupee sx={{ marginTop: "5px" }} />
    //               <Typography
    //                 sx={{
    //                   fontSize: {xs:"0.8rem",md:"1.2rem"},
    //                   marginTop: "5px",
    //                   textAlign: "right",
    //                   fontWeight: "bold",
    //                 }}
    //               >
    //                 {crate.total_price}
    //               </Typography>
    //             </Box>
    //           </Box>
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "space-between",
    //             }}
    //           >
    //             <Typography sx={{ fontSize: {xs:"0.7rem",md:"1.1rem"} }} variant="subtitle1">
    //               Total Crates Quantity
    //             </Typography>
    //             <Typography
    //               sx={{ fontWeight: "bold",  fontSize: {xs:"0.7rem",md:"1.1rem"} }}
    //               variant="subtitle1"
    //             >
    //               {crate.order_qty}
    //             </Typography>
    //           </Box>
    //         </Box>

    //         <Divider />
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             margin: "10px",
    //           }}
    //         >
    //           <Typography
    //             sx={{ fontSize: "20px", fontWeight: "bold" }}
    //             variant="subtitle1"
    //           >
    //             GRAND TOTAL
    //           </Typography>
    //           <Box sx={{display:"flex"}}>
    //             <CurrencyRupee/>
    //           <Typography
    //             sx={{ fontWeight: "bold", fontSize: "20px" }}
    //             variant="subtitle1"
    //           >
    //             {priceDetails.total_product_price + crate.total_price}
    //           </Typography>
    //           </Box>
    //         </Box>
    //       </React.Fragment>
    //     ))}

    //     <Box
    //       sx={{
    //         width: "100%",
    //         height: "10%",
    //         backgroundColor: "#27b5f2",
    //       }}
    //     >
    //       <Button
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           width: "100%",
    //           height: "100%",
    //           border: "2px solid black",
    //           "&:hover": {
    //             backgroundColor: "white", //
    //             color: "black", //
    //           },
    //           borderRadius: "10px",
    //           color: "white",
    //         }}
    //         onClick={handleClickPlaceOrder}
    //       >
    //         <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
    //           Place Order
    //         </Typography>
    //       </Button>
    //     </Box>
    //   </Box>
    // </>
    <Box
      sx={{
        marginRight: "20px",
      }}
    >
      <Typography
        variant="h5"
        ml="5px"
        sx={{ fontWeight: 800, textAlign: "center", fontSize: "18px" }}
      >
        Price Breakup
      </Typography>

      <Box
        // height="300px"
        width="300px"
        backgroundColor={colors.primary[400]}
        borderRadius="10px"
      >
        {/* <Box
          backgroundColor={colors.primary[400]}
          // height="100px"
          m="10px"
          borderRadius="10px"
        >
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Total Products Price
            </Typography>
            <Typography variant="h5"> &#8377;{totalProductPrize}</Typography>
          </Box>
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              {count} * &#8377;{price}
            </Typography>
            <Typography variant="h6">
              &#8377;{priceDetails.total_product_price}
            </Typography>
          </Box>
          <Box
            display="flex"
            p="0px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">CGST</Typography>
            <Typography variant="h6">{productCgst}&#x25;</Typography>
          </Box>
          <Box
            display="flex"
            p="0px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">SGST</Typography>
            <Typography variant="h6">{productSgst}&#x25;</Typography>
          </Box>

          <Divider sx={{ border: "1px solid black" }} />
       
        </Box> */}

        <Box
          backgroundColor={colors.primary[400]}
          // height="100px"
          m="10px"
          borderRadius="10px"
        >
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Total Product Price
            </Typography>
            <Typography variant="h5">
              {" "}
              &#8377;
              {/* {priceDetails.crates.map(
                (data) => data.total_price + productCgst * productCgst
              )} */}
              {parseInt(totalCratePrize) || 0}
            </Typography>
          </Box>
          {priceDetails.crates.map((crate, idx) => {
            return (
              <Box key={idx}>
                <Box
                  display="flex"
                  p="10px 10px 0px 10px"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">
                    {crate.order_qty} * &#8377;{crate.price}
                  </Typography>
                  <Typography variant="h6">
                    &#8377;{crate.total_price}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  p="0px 10px 0px 10px"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">CGST</Typography>
                  <Typography variant="h6">{productCgst}&#x25;</Typography>
                </Box>
                <Box
                  display="flex"
                  p="0px 10px 0px 10px"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">CGST</Typography>
                  <Typography variant="h6">{productCgst}&#x25;</Typography>
                </Box>
              </Box>
            );
          })}

          {/* <Box
            display="flex"
            p="0px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">CGST</Typography>
            <Typography variant="h6">{productCgst}&#x25;</Typography>
          </Box>
          <Box
            display="flex"
            p="0px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h6">SGST</Typography>
            <Typography variant="h6">{productSgst}&#x25;</Typography>
          </Box> */}

          <Divider sx={{ border: "1px solid black" }} />
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="bold">
              Grand Total
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              &#8377;
              {parseInt(totalProductPrize ? totalProductPrize : 0) +
                parseInt(totalCratePrize ? totalCratePrize : 0)}
            </Typography>
          </Box>
          {/* <Box display="flex" p="0px 10px 0px 10px" justifyContent="space-between">
            <Typography variant='h6' color={colors.blueAccent[500]}>Discount</Typography>
            <Typography variant='h6' color={colors.blueAccent[500]}>&#8377;70</Typography>
          </Box> */}
          <Box
            display="flex"
            p="10px 10px 0px 10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="bold">
              To Pay
            </Typography>
            <Typography variant="h5" fontWeight="bold">
              &#8377;
              {parseInt(totalProductPrize ? totalProductPrize : 0) +
                parseInt(totalCratePrize ? totalCratePrize : 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Box
        display="flex"
        justifyContent="center"
        backgroundColor={colors.blueAccent[400]}
        width="300px"
        mt="10px"
        height="200"
        boxShadow={2}
        borderRadius="10px"
        onClick={handleClickPlaceOrder}
      >
        <Button color="primary">Place An Order</Button>
      </Box> */}
      <Box sx={{ display: "flex", justifyContent: "center", width: "300px" }}>
        <CustomButton
          handleClick={handleClickPlaceOrder}
          label="Place An Order"
          color="primary"
          variant="contained"
          size="large"
          borderRadius="20px"
          style={{ width: "300px" }}
        />
      </Box>
    </Box>
  );
}
