import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import BookWaterIcon from "../../Assests/FOM/bw_logo.png";
import { AccountCircle } from "@mui/icons-material";

export const sideBarData = [
  {
    title: "HOME",
    path: "/dashboard/home",
    icon: <HomeTwoToneIcon />,
  },
  {
    title: "SKU",
    path: "/dashboard/products",
    icon: <AddShoppingCartRoundedIcon />,
  },

  {
    title: "USERS MANAGEMENT",
    path: "/dashboard/user-management",
    icon: <ManageAccountsRoundedIcon />,
  },
  {
    title: "PROFILE",
    path: "/dashboard/profile",
    icon: <AccountCircle />,
  },
  {
    title: "WATER STATION",
    path: "/dashboard/water-station",
    icon: <CorporateFareRoundedIcon />,
  },

  {
    title: "FACILITY ORDERS",
    path: "/dashboard/facility-order-management",
    icon: <ListAltRoundedIcon />,
  },
  {
    title: "ORGANIZATION ORDERS",
    path: "/dashboard/organisation-order-management",
    icon: <ListAltRoundedIcon />,
  },
  {
    title: "All Orders(Organization)",
    path: "/dashboard/organization_orders_List",
    icon: <ListAltRoundedIcon />,
  },
  {
    title: "BOOKWATER ORDERS",
    path: "/dashboard/bookwater-order-management",
    icon: <ListAltRoundedIcon />,
  },
  {
    title: "QR/BARCODES",
    path: "/dashboard/codesManagement",
    icon: <QrCodeRoundedIcon />,
  },

  {
    title: "REWARDS AND OFFERS",
    path: "/dashboard/organization",
    icon: <LocalOfferRoundedIcon />,
  },
  {
    title: "REPORTS",
    path: "/dashboard/organization",
    icon: <SummarizeRoundedIcon />,
  },
  {
    title: "SUPPORT",
    path: "/dashboard/organization",
    icon: <HelpCenterRoundedIcon />,
  },
  {
    title: "SETTINGS",
    path: "/dashboard/settings",
    icon: <SettingsRoundedIcon />,
  },
  {
    title: "RECYCLE ORDERS",
    path: "/dashboard/recycleOrders",
    icon: <ListAltRoundedIcon />,
  },
  {
    title: "API DOCUMENTATION",
    path: "/dashboard/api_documentation",
    icon: <ListAltRoundedIcon />,
  },
];
