import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Box,
  CircularProgress,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import { appColors } from "../../../AppMeta/appColors";

export function CratesDetails({
  openOrderDetailsDialog,
  setOpenOrderDetailsDialog,
  moreDetails,
}) {
  const handleClose = () => {
    setOpenOrderDetailsDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openOrderDetailsDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Crates
            </Typography>
            <TableContainer>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: appColors.secondary,
                    borderRadius: "20px",
                  }}
                >
                  <TableRow sx={{ borderRadius: "20px" }}>
                    <TableCell>Crates ID</TableCell>
                    <TableCell>Container Capacity</TableCell>
                    <TableCell>Container Material</TableCell>
                    <TableCell>Received Quantity</TableCell>
                    <TableCell>Submitted Quantity</TableCell>
                    <TableCell>Order Quantity</TableCell>
                    <TableCell>Return Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {moreDetails?.crates?.map((crate) => (
                    <TableRow>
                      <TableCell>{crate.id}</TableCell>
                      <TableCell>{crate.container_capacity}</TableCell>
                      <TableCell>{crate.container_material}</TableCell>
                      <TableCell>{crate.received_qty}</TableCell>
                      <TableCell>{crate.submitted_qty}</TableCell>
                      <TableCell>{crate.order_qty}</TableCell>
                      <TableCell>{crate.return_qty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
