import { Box } from "@mui/material";
import React, { useEffect } from "react";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import appMeta from "../../../AppMeta/appMeta";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import { wfsApis } from "../../../Redux/Actions/wfsAction";
import moment from "moment/moment";
import userManagementApis from "../../../Redux/Actions/userManagement";

function AddWfsForm({ setOpenAddWfsDialog, getWfs }) {
  //hooks
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.profileData);
  const organizationDetail = useSelector((state) => state.organizationData);
  const organizationList = organizationDetail.organizationList;

  //state

  //function
  const handleAddWfsSubmit = async (formData) => {
    console.log(formData);
    let payLoad = {
      wfs_name: formData.companyName,
      facility_id: formData.facility,
      address: {
        district: formData.village,
        pincode: formData.pincode,
        state: formData.state,
        street: formData.street,
        village: formData.district,
        country: formData.country,
        door_no: formData.doorNumber,
      },
      wfs_incharge: {
        name: formData.wfsInchargeName,
        phone_number: formData.wfsInchargePhone,
        aadhaar_number: formData.wfsInchargeAadhar,
        email: formData.wfsInchargeEmail,
      },
      wfs_details_json: {
        gst_number: formData.gstinNumber,
        company_pan_number: formData.companyPan,
        fssai_number: formData.fssaiNumber,
        isi_number: formData.isiNumber,
        aadhaar_number: formData.wfsInchargeAadhar,
      },
      is_active: true,
      updated_by: profileDetails.profile.user_id,
      updated_at: moment().format("YYYY-MM-DD"),
      created_at: moment().format("YYYY-MM-DD"),
      is_deleted: false,
      wfs_files: {
        gst_file_url: formData?.gstinFile,
        company_pan_file_url: formData?.companyPanFile,
        owner_pan_file_url: formData?.ownerPanFile,
        aadhaar_file_url: formData?.aadharFile,
        fssai_file_url: formData?.fssaiFile,
        isi_file_url: formData?.isiFile,
      },
    };

    const res = await dispatch(
      HandleApiActions({
        ...wfsApis.add_wfs,
        params: payLoad,
        show_toast: true,
      })
    );
    getWfs();
    res.error === false && setOpenAddWfsDialog(false);
  };

  const searchAndUpdateFacility = (searchText) => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_all_organization,
        params: {
          ...(searchText !== "" && { org_name: searchText }),
          ignore_app_loading_spinner: true,
        },
        show_toast: false,
      })
    );
  };

  useEffect(() => {
    searchAndUpdateFacility();
  }, []);

  return (
    <CustomForm
      data={[
        {
          title: "ADD NEW WFS",
          numberOfColumn: 1,
          data: [
            {
              inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
              optionKey: "org_name",
              outputKey: "id",
              searchAndUpdateAPI: searchAndUpdateFacility,
              input: organizationList || [],
              name: "facility",
              label: "Enter Facility Name*",
              isRequired: true,
            },
          ],
        },
        {
          title: "WFS details",
          numberOfColumn: 2,
          data: [
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Company Name",
              defaultValue: "",
              name: "companyName",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },

            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Company PAN Number",
              defaultValue: "",
              name: "companyPan",
              textFieldType: appMeta.textFieldTypes.PAN,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "ISI number",
              defaultValue: "",
              name: "isiNumber",
              textFieldType: appMeta.textFieldTypes.ISI,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "FSSAI number",
              defaultValue: "",
              name: "fssaiNumber",
              textFieldType: appMeta.textFieldTypes.FSSAI,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "GSTIN number",
              defaultValue: "",
              name: "gstinNumber",
              textFieldType: appMeta.textFieldTypes.GST,
              isHintRequired: true,
              isRequired: true,
            },
          ],
        },
        {
          title: "WFS incharge details",
          numberOfColumn: 2,
          data: [
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Name",
              defaultValue: "",
              name: "wfsInchargeName",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Phone",
              defaultValue: "",
              name: "wfsInchargePhone",
              textFieldType: appMeta.textFieldTypes.PHONE,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Aadhar Number",
              defaultValue: "",
              name: "wfsInchargeAadhar",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Email",
              defaultValue: "",
              name: "wfsInchargeEmail",
              textFieldType: appMeta.textFieldTypes.EMAIL,
              isHintRequired: true,
              isRequired: true,
            },
          ],
        },
        {
          title: "Address",
          numberOfColumn: 2,
          data: [
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Door Number",
              defaultValue: "",
              name: "doorNumber",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Street",
              defaultValue: "",
              name: "street",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Village",
              defaultValue: "",
              name: "village",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "District",
              defaultValue: "",
              name: "district",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "State",
              defaultValue: "",
              name: "state",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Pincode",
              defaultValue: "",
              name: "pincode",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Country",
              defaultValue: "",
              name: "country",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
          ],
        },
        {
          title: "Document Upload",
          numberOfColumn: 2,
          data: [
            {
              inputType: appMeta.inputFieldTypes.FILE,
              name: "fssaiFile",
              placeHolder: "FSSAI*",
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.FILE,
              name: "isiFile",
              placeHolder: "ISI*",
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.FILE,
              name: "gstinFile",
              placeHolder: "GSTIN*",
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.FILE,
              name: "companyPanFile",
              placeHolder: "Company PAN*",
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.FILE,
              name: "aadharFile",
              placeHolder: "Aadhar*",
              isRequired: true,
            },
          ],
        },
      ]}
      handleClose={() => {
        setOpenAddWfsDialog(false);
      }}
      handleSubmit={handleAddWfsSubmit}
    />
  );
}

export default AddWfsForm;
