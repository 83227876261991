import React, { useState } from "react";
import {
  Box,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  Paper,
  Table,
  Typography,
  Slide,
  TablePagination,
  Button,
  Chip,
  colors,
  useMediaQuery,
  useTheme,
  Dialog,
  Modal,
  DialogTitle,
  DialogContent,
  Avatar,
  Stack,
  DialogContentText,
  paperClasses,
  Grid,
} from "@mui/material";
import Inventory from "@mui/icons-material/Inventory2Rounded";
import AddCircle from "@mui/icons-material/AddCircleOutline";
import Filter from "@mui/icons-material/FilterAlt";
import { CheckStock } from "./CheckStock";
import { FilterOptions } from "./FilterOptions";
import { NewOrder } from "./NewOrder";
import { useDispatch, useSelector } from "react-redux";
import { GetEmptyCanAction } from "../../../../Redux/Actions/emptyNewCanAction";
import { EditDialogEmptyCanOrder } from "../EditDialogEmptyCanOrder";
import { CratesDetails } from "../cratesDetails";
import CustomConfirmationDialog from "../../../../Components/CustomComp/CustomConfirmationDialog";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Cancel from "@mui/icons-material/Cancel";
import Edit from "@mui/icons-material/Edit";
import Pending from "@mui/icons-material/Pending";
import Failed from "@mui/icons-material/SentimentVeryDissatisfied";
import Success from "@mui/icons-material/ThumbUp";
import Confirmed from "@mui/icons-material/SentimentSatisfiedAlt";
import Prepaid from "@mui/icons-material/Money";
import Postpaid from "@mui/icons-material/CreditCard";
import { tokens } from "../../../../AppMeta/theme";
import CancelIcon from "@mui/icons-material/Cancel";
import InventoryIcon from "@mui/icons-material/Inventory";
import { GetProfile } from "../../../../Redux/Actions/profile";
import {
  Close,
  CorporateFareRounded,
  Delete,
  FlashOnRounded,
  Instagram,
} from "@mui/icons-material";
import WaterCan from "../../../../AppMeta/AppImages/WaterCanImg.png";
import facitlityApis, {
  CancelFacilityAction,
  GetFacilityAction,
} from "../../../../Redux/Actions/facilityAction";
import { OutScanQrless } from "../../../../Redux/Actions/scanningActions";
import ScanningDialogQrLess from "../../../../Components/Scanning/ScanningDialogQrLess";
import ScanningDialog from "../../../../Components/CustomComp/ScanningDialog";
import appStyle from "../../../../AppMeta/appStyle";
import DisplayTitle from "../../../../Components/CustomComp/DisplayTitle";
import CustomTable from "../../../../Components/CustomComp/CustomTable";
import CustomButton from "../../../../Components/CustomComp/CustomButton";
import { HandleApiActions } from "../../../../Redux/Actions/actionHandler";

const Header = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography
        color={colors.grey[100]}
        fontWeight="bold"
        mt="20px"
        variant="h1"
      >
        Facility Order Management
      </Typography>
    </Box>
  );
};

const CheckBoxNewOrder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [showBox, setShowBox] = React.useState(false);
  const [buttonChange, setButtonChange] = React.useState(false);
  const [newOrder, setNewOrder] = React.useState(false);

  const handleClickNewOrder = () => {
    setNewOrder(!newOrder);
  };

  const handleClick = () => {
    setShowBox(!showBox);
  };
  const handleChangeButton = () => {
    setButtonChange((prevState) => !prevState);
  };
  return (
    <Box mt="10px" sx={{ display: "flex", justifyContent: "flex-end" }}>
      {/* <Button
        size="medium"
        sx={{ fontWeight: "bold" }}
        startIcon={<InventoryIcon />}
        variant="contained"
        color="info"
        onClick={handleClick}
      >
        Check Stock
        <CheckStock/>
      </Button>
      {showBox && (
        <Box
          sx={{
            position: "absolute",
            transform: "translateY(40%)",
            width: "300px",
            height: "100px",
            backgroundColor: "primary",
            boxShadow: "3",
            borderRadius: "10px",
          }}
        ></Box>
      )} */}
      <Box sx={appStyle.general.button.addButtonBox}>
        <Button
          size="medium"
          endIcon={<AddCircle />}
          variant="contained"
          sx={appStyle.general.button.addButton}
          color="secondary"
          onClick={handleClickNewOrder}
        >
          New Order
        </Button>
      </Box>
      {newOrder && (
        <NewOrder open={newOrder} handleCloseNewOrder={handleClickNewOrder} />
      )}
    </Box>
  );
};

const ViewBox = ({
  open,
  onClose,
  orderDetails,
  getPaymentType,
  getPaymentStatus,
  getPaymentMethod,
  handleClickShowMore,
  handleEditClick,
  setOrderId,
  setOpenConfirmationDialog,
  otp,
  // setOpenScanningQrlessDialog,
  // openScanningQrlessDialog,
  // outScanQty,
  // setOutScanQty,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setisEdit] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Scanning variables
  const [openScanningQrlessDialog, setOpenScanningQrlessDialog] =
    React.useState(false);
  // for QR less product outscan
  const [outScanQty, setOutScanQty] = React.useState(
    orderDetails?.new_container_qty
  );
  const [submitted, setSubmitted] = React.useState(false);

  // For QR product scanning variables
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [currentScanned, setCurrentScanned] = React.useState({});
  const [scannedCount, setScannedCount] = React.useState(0);

  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] =
    React.useState(false);
  const [requestObject, setRequestObject] = React.useState({});
  const [validateExpired, setValidateExpired] = React.useState([]);
  const isBatch = false;

  console.log("orderDetails");
  console.log(orderDetails);

  React.useEffect(() => {
    console.log("submitted");
    console.log(submitted);
    if (submitted) {
      // Hit the submit API
      const reqObj = {
        order_id: orderDetails?.ec_order_id,
        container_material: orderDetails?.container_capacity,
        container_capacity: orderDetails?.container_material,
        instock_qty: outScanQty,
      };
      console.log(reqObj);
      dispatch(OutScanQrless(reqObj));
      setTimeout(() => {
        setSubmitted(false);
      }, 1000);
    }
  }, [submitted]);

  return (
    <>
      {" "}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <Box m="20px" display="flex" justifyContent="space-between">
          <Typography fontWeight="bold" variant="h3">
            Show Details
          </Typography>
          <Button
            color="error"
            size="large"
            variant="contained"
            onClick={onClose}
          >
            <CancelIcon size="large" />
          </Button>
        </Box>
        <DialogContent>
          {isEdit ? (
            <>
              <EditDialogEmptyCanOrder
                open={isEdit}
                onClose={() => {
                  setisEdit(false);
                }}
                item={orderDetails}
              />
              <Button>Save</Button>
            </>
          ) : (
            <Box>
              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Payment Type</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {getPaymentType(orderDetails?.payment_type)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Payment Status</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {getPaymentStatus(orderDetails?.payment_status)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Payment Method</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {getPaymentMethod(orderDetails?.payment_method)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">OTP</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">{orderDetails.otp}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Quantity</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {orderDetails.new_container_qty}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Scan status</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setOpenScanningDialog(true)}
                    // onClick={() => setOpenScanningQrlessDialog(true)}
                  >
                    Start Outscan
                  </Button>
                </Grid>
              </Grid>

              <Box mt="10px" display="flex" justifyContent="space-around">
                <Button
                  onClick={() => {
                    setisEdit(true);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    setOrderId(orderDetails.id);
                    setOpenConfirmationDialog(true);
                  }}
                  variant="outlined"
                  color="error"
                >
                  Cancel Order
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      {openScanningQrlessDialog && (
        <ScanningDialogQrLess
          outScanQty={outScanQty}
          setOutScanQty={setOutScanQty}
          openScanningQrlessDialog={openScanningQrlessDialog}
          setOpenScanningQrlessDialog={setOpenScanningQrlessDialog}
          setSubmitted={setSubmitted}
        />
      )}
      {openScanningDialog && (
        <ScanningDialog
          setOpenScanningDialog={setOpenScanningDialog}
          openScanningDialog={openScanningDialog}
          scannedQrBarcode={scannedQrBarcode}
          setScannedQrBarcode={setScannedQrBarcode}
          currentScanned={currentScanned}
          setCurrentScanned={setCurrentScanned}
          // scanQtyMax={scanQtyMax}
          scanQtyMax={orderDetails?.new_container_qty}
          scanOrderId={orderDetails?.ec_order_id}
          scannedCount={scannedCount}
          setScannedCount={setScannedCount}
          setRequestObject={setRequestObject}
          setOpenLinkFromStartDialog={setOpenLinkFromStartDialog}
          validateExpired={validateExpired}
          isBatch={isBatch}
        />
      )}
    </>
  );
};

//Main Function Export
export function FacilityOrderManagement(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  //useSelector
  const orderDetails = useSelector((state) => state.productData);
  const facilityDetails = useSelector((state) => state.facilityManagementData);

  //1.useState Hooks
  const [openDialogStock, setOpenDialogStock] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState("false");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [newOrder, setNewOrder] = React.useState(false);
  const [getOrders, setGetOrders] = React.useState([]);
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [viewBoxOpen, setViewBoxOpen] = React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] =
    React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState({});
  const [currentItem, setCurrentItem] = React.useState(null);
  const [orderId, setOrderId] = React.useState("");
  const [facilityAllOrders, setFacilityAllOrders] = React.useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [filterLabel, setFilterLabel] = React.useState("");
  const [filterSearchText, setFilterSearchText] = React.useState("");
  const [sortLabel, setSortLabel] = React.useState("");
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");

  // Scanning variables
  const [openScanningQrlessDialog, setOpenScanningQrlessDialog] =
    React.useState(false);
  // for QR less product outscan
  const [outScanQty, setOutScanQty] = React.useState(null);
  //2.useEffect
  React.useEffect(() => {
    //dispatch(GetEmptyCanAction());
    dispatch(GetProfile());
  }, []);

  React.useEffect(() => {
    console.log("Get Facility Action");

    getFacilityOrders();
  }, [pageNumber, pageSize, filterSearchText, ascOrDesc]);

  const getFacilityOrders = () => {
    // dispatch(
    //   GetFacilityAction({
    //     params: {
    //       ...(filterSearchText && { [filterLabel]: filterSearchText }),
    //     },
    //   })
    // );
    console.log("--------get_facility_order-------------");
    dispatch(
      HandleApiActions({
        ...facitlityApis.get_facility_order,
        params: {
          page_number: pageNumber,
          page_size: pageSize,
          ...(filterSearchText && { [filterLabel]: filterSearchText }),
          ...(ascOrDesc && { [sortLabel]: ascOrDesc }),
        },
        show_toast: false,
      })
    );
  };

  React.useEffect(() => {
    console.log("facilityDetails");
    console.log(facilityDetails);
    if (facilityDetails.error === false) {
      console.log("facilityAllOrders", facilityAllOrders);
      setFacilityAllOrders(facilityDetails?.facilityOrder);
    }
  }, [facilityDetails]);
  // React.useEffect(() => {
  //   console.log("Get Products");
  //   console.log(productDetails);
  // },[productDetails])

  //3.Functions
  const handleClickStock = () => {
    setOpenDialogStock(true);
  };
  const handleClickNewOrder = () => {
    setNewOrder(true);
  };

  const handleClickViewBox = (item) => {
    setViewBoxOpen(true);
    setSelectedOrder(item);
  };

  const handleCloseNewOrder = () => {
    setNewOrder(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setEditDialogOpen(false);
    setCurrentItem(null); // Clear the current item when dialog is closed
  };

  const handleClickShowMore = (details) => {
    console.log("details");
    console.log(details);
    setMoreDetails(details);
    setOpenOrderDetailsDialog(true);
  };

  const handleEditClick = (item) => {
    console.log("item");
    console.log(item);
    setCurrentItem(item);
    setEditDialogOpen(true);
  };

  // const handleChangeOrders = () => {
  //   setGetOrders(data);
  // };

  const handleCancelOrdeer = (item) => {
    console.log("dataa", item);
    dispatch(CancelFacilityAction({ ec_order_id: item }));
    // dispatch(
    //   HandleApiActions({
    //     ...facitlityApis.cancel_facility_order,
    //     params: {
    //       facility_id: item,
    //     },
    //     show_toast: false,
    //   })
    // );
  };

  const getPaymentType = (code) => {
    return parseInt(code) === 100 ? (
      <Typography color="success">Prepaid</Typography>
    ) : (
      // <Chip icon={<Prepaid />} color="success" label="Prepaid" />
      <Typography color="success">Postpaid</Typography>
      // <Chip icon={<Postpaid />} color="warning" label="Postpaid" />
    );
  };

  const getPaymentMethod = (code) => {
    switch (parseInt(code)) {
      case 1:
        return "RAZORPAY";
      case 2:
        return "PAYSHARP";
      case 3:
        return "WALLET";
      case 4:
        return "POSTPAID";
      default:
        return "";
    }
  };

  const getOrderStatus = (code) => {
    switch (code) {
      case 100:
        return "Confirmed";
      case 200:
        return "COMPLETED";
      case 300:
        return "CANCELLED";
      case 400:
        return "Pending";
      case 501:
        return "ACCEPTED";
      case 502:
        return "ADMIN_REJECTED";
      default:
        return "";
    }
  };
  ///Functions for Filters
  const handleFacilityFilterClose = (filterData) => {
    console.log("Facility filter Data", filterData);
    setFilterLabel(filterData.value);
    setFilterSearchText(filterData.searchText);
    // getFacilityOrders();
  };
  const handleFacilitySortClose = (sortData) => {
    console.log("Facility Sort Data", sortData);
    setSortLabel(sortData.value);
    setAscOrDesc(sortData.searchText);
    // getAllOrganization();
  };

  const getPaymentStatus = (code) => {
    console.log(code);
    switch (parseInt(code)) {
      case 100:
        return (
          // <Chip
          //   icon={<Success />}
          //   variant="outlined"
          //   // sx={{ color: "red" }}
          //   size="medium"
          //   label="Success"
          // />
          <Typography>Success</Typography>
        );

      case 200:
        return (
          // <Chip
          //   icon={<Failed />}
          //   variant="outlined"
          //   // sx={{ color: "red" }}
          //   size="medium"
          //   label="Failed"
          // />
          <Typography>Failed</Typography>
        );
      case 300:
        // return <Chip icon={<Pending />} label="Pending" />;
        return <Typography>Pending</Typography>;
      default:
        return "";
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;

  const colors = tokens(theme.palette.mode);

  return (
    <div>
      <Box>
        {/* <Header /> */}
        {/* <DisplayTitle>Facility Order Management</DisplayTitle> */}
        {/* <CheckBoxNewOrder /> */}

        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              marginTop: "20px",
              borderRadius: "30px",
              textAlign: "start",
              backgroundColor: "#d9d9d9",
              padding: "8px",
              display: "flex",
              // width: "150px",
              height: "40px",
              cursor: "pointer",
            }}
            onClick={handleClickStock}
          >
            <Inventory sx={{ color: "black" }} />
            <Typography
              variant="h7"
              sx={{ color: "#0000ff", marginLeft: "5px" }}
            >
              Check Stock
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: "20px",
              borderRadius: "30px",
              textAlign: "start",
              backgroundColor: "#ace255",
              padding: "8px",
              display: "flex",
              // width: "150px",
              height: "40px",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#88b327",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              },
              cursor: "pointer",
            }}
            onClick={handleClickNewOrder}
          >
            <Typography
              variant="h7"
              sx={{ color: "black", marginRight: "10px", marginLeft: "10px" }}
            >
              New Order
            </Typography>
            <AddCircle sx={{ color: "black" }} />
          </Box>
        </Box> */}

        <Box>
          <CustomTable
            tableHead={[
              "Order Id",
              "Capacity",
              "Material",
              "Quantity",
              "Payment Type",
              "Payment Method",
              "Payment Status",
              "OTP",
              "Status",
              "Crates",
              "Edit",
              "Delete",
            ]}
            tableData={facilityAllOrders}
            tableDataKey={[
              "ec_order_id",
              "container_capacity",
              "container_material",
              "new_container_qty",
              {
                component: (props) => {
                  console.log("propsssss", props);
                  return getPaymentType(props.row?.payment_type);
                },
              },
              // {
              //   component: (props) => {
              //     console.log("propsssss", props);
              //     return getPaymentMethod(props.row?.payment_method);
              //   },
              // },
              "payment_method",
              {
                component: (props) => {
                  console.log("propsssss", props);
                  return getPaymentStatus(props.row?.payment_status);
                },
              },
              "otp",
              {
                component: (props) => {
                  console.log("propsssss", props);
                  return getOrderStatus(props.row?.ec_order_status);
                },
              },
              {
                type: "button",
                component: (props) => {
                  console.log("propss", props);
                  return (
                    <CustomButton
                      btnName="View"
                      size="small"
                      endIcon={<CorporateFareRounded />}
                      handleClick={() =>
                        handleClickShowMore(props?.row?.crates)
                      }
                    />
                  );
                },
              },
              {
                type: "button",
                component: (props) => {
                  return (
                    <CustomButton
                      btnName="Edit"
                      size="small"
                      startIcon={<Edit />}
                      handleClick={() => handleEditClick(props?.row)}
                    />
                  );
                },
              },
              {
                type: "button",
                component: (props) => {
                  return (
                    <CustomButton
                      btnName="cancel"
                      size="small"
                      color="error"
                      startIcon={<Close />}
                      handleClick={() =>
                        handleCancelOrdeer(props?.row?.ec_order_id)
                      }
                      // handleClick={() => {
                      //   console.log(
                      //     "ADD ORGANIZATION",
                      //     props.row,
                      //     props.row?.facility_id,
                      //     props.row?.org_name
                      //   );

                      // setFacilityId(props.row?.id);
                      // setFacilityName(props.row?.org_name);
                      // setAddOrganizationDialog(true);
                      // setIsAddFacility(false);
                      // }}
                      // disabled={props.disabled}
                    />
                  );
                },
              },
            ]}
            // tableRowClickFunction={facilityRowClick}
            filter={true}
            filterSelectOption={[
              { value: "ec_order_id", label: "ec_order_id" },
              { value: "container_capacity", label: "container_capacity" },
              { value: "container_material", label: "container_material" },
            ]}
            handleFilterClose={handleFacilityFilterClose}
            sort={true}
            sortSelectOption={[{ value: "created_at", label: "Created at" }]}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            handleSortClose={handleFacilitySortClose}
            addButton={true}
            handleOnClickAddButton={handleClickNewOrder}
          />

          {/* <TableContainer sx={appStyle.general.table.tableContainer}>
            <Table stickyHeader>
              <TableHead
                sx={{
                  backgroundColor:
                    appStyle.general.colors(colors).table.tableHeadBackground,
                  color: colors.primary[300],
                  ...appStyle.general.table.tableHead,
                }}
              >
                <TableRow>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Order ID
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Product
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Quantity
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Payment Type
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Payment Method
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Payment Status
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        OTP
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Status
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Crates
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Action
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: colors.primary[600] }}>
                {(rowsPerPage > 0
                  ? facilityAllOrders.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : facilityAllOrders
                ).map((row) => (
                  <TableRow
                    key={row.ec_master_id}
                    // onChange={handleChangeOrders}
                    onClick={() => handleClickViewBox(row)}
                  >
                    <TableCell sx={appStyle.general.table.bodyRowCell}>
                      {row.ec_order_id}
                    </TableCell>
                    <TableCell sx={appStyle.general.table.bodyRowCell}>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box>
                          <img
                            src={WaterCan}
                            alt="Water Can"
                            style={{ width: 56, height: 65 }}
                          />
                        </Box>

                        <Stack direction="column" alignItems="center">
                          <Typography sx={{ fontSize: "0.9rem" }}>
                            {row.container_capacity}
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            {row.container_material}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell sx={appStyle.general.table.bodyRowCell}>
                      <Box display="flex" flexDirection="column">
                        {row.new_container_qty}

                        {isMobile && getOrderStatus(row.ec_order_status)}
                      </Box>
                    </TableCell>

                    {!isMobile && (
                      <>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {getPaymentType(row.payment_type)}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {row.payment_method}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {getPaymentStatus(row.payment_status)}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {row.otp}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {getOrderStatus(row.ec_order_status)}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => handleClickShowMore(row)}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          <Box display="flex" justifyContent="space-evenly">
                            <Button
                              variant="contained"
                              color="secondary"
                              startIcon={<Edit />}
                              onClick={() => handleEditClick(row)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              sx={{ marginLeft: "10px" }}
                              onClick={() => {
                                setOrderId(row.id);
                                setOpenConfirmationDialog(true);
                              }}
                              startIcon={<Cancel />}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Box>

        {openDialogStock && (
          <CheckStock open={openDialogStock} close={setOpenDialogStock} />
        )}

        {newOrder && (
          <NewOrder open={newOrder} handleCloseNewOrder={handleCloseNewOrder} />
        )}
        {openConfirmationDialog && (
          <CustomConfirmationDialog
            openConfirmationDialog={openConfirmationDialog}
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            description={"Are you sure that you want to delete?"}
            setIsConfirmed={setIsConfirmed}
          />
        )}

        {openOrderDetailsDialog && (
          <CratesDetails
            openOrderDetailsDialog={openOrderDetailsDialog}
            setOpenOrderDetailsDialog={setOpenOrderDetailsDialog}
            moreDetails={moreDetails}
          />
        )}
        {editDialogOpen && (
          <EditDialogEmptyCanOrder
            open={editDialogOpen}
            onClose={handleClose}
            item={currentItem}
          />
        )}
        {viewBoxOpen && isMobile && (
          <ViewBox
            open={viewBoxOpen}
            onClose={() => setViewBoxOpen(false)}
            orderDetails={selectedOrder}
            getPaymentType={getPaymentType}
            getPaymentStatus={getPaymentStatus}
            getPaymentMethod={getPaymentMethod}
            setOrderId={setOrderId}
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            // setOpenScanningQrlessDialog={setOpenScanningQrlessDialog}
            // openScanningQrlessDialog={openScanningQrlessDialog}
            // outScanQty={outScanQty}
            // setOutScanQty={setOutScanQty}
          />
        )}
      </Box>
    </div>
  );
}
