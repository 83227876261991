/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 07-07-2024
 * @description Dialog Box to edit the User Profile details
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import appStyle from "../../AppMeta/appStyle";
import React, { useEffect, useState } from "react";
// import appTheme from "../../assets/AppTheme/appTheme";
import customConsole from "../../config/customConsole";
import CustomButton from "../../Components/CustomComp/CustomButton";

/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 07-07-2024
 * @description Dialog Box to edit the User Profile details
 * @param {header:phone/name/region, data:"",phone_codes:[], regions:[],
 *  open:true, handleClose:()=>{}, handleUpdate:()=>{}}
 */
function EditUserProfile(props) {
  const [fullname, setFullname] = useState(props.data);
  const [isError, setIsError] = useState(false);
  const [region, setRegion] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phone, setPhone] = useState("");

  let a = 5;
  let b = a++;
  console.log("ab", a + b);

  useEffect(() => {
    customConsole("-- EditUserProfile --");
    customConsole(props);
    if (props.header.toLowerCase() === "name") {
      setFullname(props.data);
    } else if (props.header.toLowerCase() === "phone") {
      const phoneDetails = props.data.split("-");
      const country = props.phone_codes.filter((data) =>
        data.includes(phoneDetails[0])
      );
      setPhoneCode(country[0]);
      setPhone(phoneDetails[1]);
    } else if (props.header.toLowerCase() === "time zone") {
      setRegion(props.data);
    }
  }, []);
  const handleOnDataChange = (event) => {
    customConsole("-- handleOnDataChange --");
    customConsole(event.target.value);
    customConsole(typeof event.target.value);
    let isValidationFailed = false;
    if (props.header.toLowerCase() === "name") {
      setFullname(event.target.value);
      if (
        !event.target.value ||
        !event.target.value.length ||
        event.target.value.length < 3
      ) {
        isValidationFailed = true;
      }
    } else if (props.header.toLowerCase() === "phone") {
      // const regex = /^\d+$/;
      // if (regex.test(event.target.value)) {
      //   setPhone(event.target.value);
      // }

      setPhone(event.target.value);

      if (
        !event.target.value ||
        !event.target.value.length ||
        event.target.value.length < 6
      ) {
        isValidationFailed = true;
      }
    }
    if (isValidationFailed && !isError) {
      setIsError(true);
    }
    if (!isValidationFailed && isError) {
      setIsError(false);
    }
  };
  const handleUpdate = () => {
    let finalObject = { current_time: moment().format() };
    if (props.header.toLowerCase() === "name") {
      finalObject = {
        ...finalObject,
        fullname: fullname.trim(),
      };
    } else if (props.header.toLowerCase() === "phone") {
      const countryCode = phoneCode.split(": ");
      finalObject = {
        ...finalObject,
        phone: {
          code: countryCode[1],
          number: phone,
        },
      };
    } else if (props.header.toLowerCase() === "time zone") {
      finalObject = {
        ...finalObject,
        region: region,
      };
    }
    props.handleUpdate(finalObject);
  };
  return (
    <Dialog open={props.open} sx={{ padding: "20px" }}>
      <DialogTitle sx={{ fontWeight: 800, color: "black", fontSize: "20px" }}>
        Editing {props.header}
      </DialogTitle>
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.header.toLowerCase() === "phone" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Select
              value={phoneCode} // Use phoneCode as the value
              onChange={(event) => setPhoneCode(event.target.value)}
              variant="standard"
              sx={{
                fontWeight: 800,
                color: "black",
                maxHeight: 100, // Adjust as needed
                overflowY: "auto",
              }}
            >
              {props.phone_codes.map((phoneCode, index) => (
                <MenuItem
                  key={index}
                  value={`${phoneCode}`} // Use phoneCode as the value
                  sx={{ fontWeight: 800, color: "black" }}
                >
                  {`${phoneCode}`}
                </MenuItem>
              ))}
            </Select>
            <TextField
              sx={{ fontWeight: "bold", color: "black" }}
              type="number"
              error={isError}
              variant="standard"
              value={phone}
              onChange={handleOnDataChange}
              helperText={isError ? "Incorrect phone number." : ""}
            />
          </Box>
        ) : props.header.toLowerCase() === "name" ? (
          <TextField
            sx={{ fontWeight: 900, color: "black", width: "220px" }}
            error={isError}
            type={"text"}
            variant="standard"
            value={fullname}
            onChange={handleOnDataChange}
            helperText={isError ? "Incorrect entry." : ""}
          />
        ) : props.header.toLowerCase() === "time zone" ? (
          <Select
            defaultValue={region}
            value={region}
            onChange={(data) => setRegion(data.target.value)}
            variant="standard"
            sx={{ fontWeight: "bold", color: "black" }}
          >
            {props.regions.map((data, index) => {
              return (
                <MenuItem
                  key={index}
                  value={data}
                  sx={{ fontWeight: "bold", color: "black" }}
                  onClick={() => setRegion(data)}
                >
                  {data}
                </MenuItem>
              );
            })}
          </Select>
        ) : null}
      </Box>
      <DialogActions>
        <CustomButton
          // sx={{
          //   borderRadius: "15px",
          //   fontSize: "10px",
          //   color: "white",
          //   fontWeight: "bold",
          //   backgroundImage: "linear-gradient(#021636,#0c4aad)",
          //   "&:hover": {
          //     backgroundImage: "linear-gradient(#6b8df2,#021636)",
          //   },
          //   // ...appTheme.commonBtnStyle,
          // }}
          // sx={appStyle.general.button.redButton}
          color="error"
          handleClick={props.handleClose}
          label={"Close"}
          autoFocus
        />
        {/* Close
        </Button> */}
        <CustomButton
          disabled={isError}
          // sx={
          // borderRadius: "15px",
          // fontSize: "10px",
          // color: "white",
          // fontWeight: "bold",
          // backgroundImage: "linear-gradient(#021636,#0c4aad)",
          // "&:hover": {
          //   backgroundImage: "linear-gradient(#6b8df2,#021636)",
          // },
          // appStyle.general.button.blueButton
          // }
          // sx={{ ...appTheme.commonBtnStyle }}
          handleClick={handleUpdate}
          autoFocus
          label={"Update"}
        />
      </DialogActions>
    </Dialog>
  );
}

export default EditUserProfile;
