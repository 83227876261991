import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddFinalProductSKU,
  GetFinalProductSKU,
} from "../../Redux/Actions/productsAction";
import { toast } from "react-toastify";
import appStyle from "../../AppMeta/appStyle";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";

function AddFinalSkuProduct({
  pageNumber,
  pageSize,
  available,
  createdAtSort,
  handleDrawerClose,
}) {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.productData);
  const [containerId, setContainerId] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [skuQty, setSkuQty] = React.useState(1);
  const [image, setImage] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [cgstPerc, setCgstPerc] = React.useState("");
  const [sgstPerc, setSgctPerc] = React.useState("");
  const [igstPerc, setIgstPerc] = React.useState("");
  const [minBookQty, setMinBookQty] = React.useState("");
  const [maxBookQty, setMaxBookQty] = React.useState("");
  const [deliveryCharges, setDeliveryCharges] = React.useState("");
  const [orderType, setOrderType] = React.useState("");
  const [bookingType, setBookingType] = React.useState("1");
  const [mrpR, setMrpR] = React.useState(1);
  const [spR, setSpR] = React.useState(1);
  const [depositR, setDepositR] = React.useState(1);
  const [refundTypeR, setRefundTypeR] = React.useState(100);
  const [refundAmountR, setRefundAmountR] = React.useState(1);
  const [mrpO, setMrpO] = React.useState(1);
  const [spO, setSpO] = React.useState(1);
  const [depositO, setDepositO] = React.useState(1);
  const [refundTypeO, setRefundTypeO] = React.useState("100");
  const [refundAmountO, setRefundAmountO] = React.useState(1);
  const [isActive, setIsActive] = React.useState(true);
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [error, setError] = React.useState("");
  const [error1, setError1] = React.useState("");
  const [itemIgst, setItemIgst] = useState("");

  React.useEffect(() => {
    console.log("productData");
    console.log(productData);
  }, [productData]);

  const handleChangeBookingType = (event) => {
    setBookingType(event.target.value);
  };

  const handleChangeOrderType = (event) => {
    setOrderType(event.target.value);
  };

  const handleAddFinalProductSubmit = (formData) => {
    const reqObj = {
      name: formData.name,
      description: formData.description,
      capacity: formData.capacity,
      material: formData.material,
      life_span: formData.lifeSpan,
      amount: refundAmountR,
      sku_qty: [formData.skuQuantity],
      image: formData.image,
      facility_price: { deposit_price: 200 },
      hsn_code: formData.hsnCode,
      cgst_perc: parseInt(formData.CGST),
      sgst_perc: parseInt(formData.SGST),
      igst_perc: parseInt(formData.IGST),
      min_book_qty: formData.minimumBookQuantity,
      max_book_qty: formData.maximumBookQuantity,
      delivery_charges: deliveryCharges,
      order_type: formData.orderType,
      booking_type: formData.bookingType,
      refill: {
        mrp:
          formData.bookingType === "1" || "3" ? parseInt(formData.refilMrp) : 1,
        sp:
          formData.bookingType === "1" || "3"
            ? parseInt(formData.refilSalePrice)
            : 1,
        deposit:
          formData.bookingType === "1" || "3"
            ? parseInt(formData.refilDeposit)
            : 1,
        refund_type:
          formData.bookingType === "1" || "3"
            ? parseInt(formData.refilManufactureType)
            : 1,
        refund_amount:
          formData.bookingType === "1"
            ? parseInt(formData.refilRefundAmount)
            : 1,
      },
      one_time_sale: {
        mrp:
          formData.bookingType === "2" || "3" ? parseInt(formData.otsMrp) : 1,
        sp:
          formData.bookingType === "2" || "3"
            ? parseInt(formData.otsSalePrice)
            : 1,
        deposit:
          formData.bookingType === "2" || "3"
            ? parseInt(formData.otsDeposit)
            : 1,
        refund_type:
          formData.bookingType === "2" || "3"
            ? parseInt(formData.OtsManufactureType)
            : 1,
        refund_amount:
          formData.bookingType === "2" || "3"
            ? parseInt(formData.otsRefundAmount)
            : 1,
      },
      is_active: true,
    };

    console.log("---ReqObj---", reqObj);
    dispatch(AddFinalProductSKU(reqObj));
    setTimeout(() => {
      dispatch(
        GetFinalProductSKU(pageNumber, pageSize, available, createdAtSort)
      );
    }, 1000);
  };

  const handleAddProduct = () => {
    if (minBookQty < maxBookQty) {
      toast.error(
        "MinBook Quantity should not be greater than MaxBook Quantity",
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else {
      setError1("");
      const reqObj = {
        name: name,
        description: description,
        capacity: capacity,
        material: material,
        life_span: lifeSpan,
        amount: refundAmountR,
        sku_qty: [skuQty],
        image: image,
        facility_price: { deposit_price: 200 },
        hsn_code: hsnCode,
        cgst_perc: parseInt(itemCgst),
        sgst_perc: parseInt(itemSgst),
        igst_perc: parseInt(itemIgst),
        min_book_qty: minBookQty,
        max_book_qty: maxBookQty,
        delivery_charges: deliveryCharges,
        order_type: orderType,
        booking_type: bookingType,
        refill: {
          mrp: parseInt(mrpR),
          sp: parseInt(spR),
          deposit: parseInt(depositR),
          refund_type: parseInt(refundTypeR),
          refund_amount: parseInt(refundAmountR),
        },
        one_time_sale: {
          mrp: parseInt(mrpO),
          sp: parseInt(spO),
          deposit: parseInt(depositO),
          refund_type: parseInt(refundTypeO),
          refund_amount: parseInt(refundAmountO),
        },
        is_active: true,
      };
      console.log("reqObj");
      console.log(reqObj);
      dispatch(AddFinalProductSKU(reqObj));
      setTimeout(() => {
        dispatch(
          GetFinalProductSKU(pageNumber, pageSize, available, createdAtSort)
        );
      }, 1000);
    }
  };

  const handleMinBookQtyChange = (event) => {
    setMinBookQty(event.target.value);
  };

  const handleMaxBookQtyChange = (event) => {
    setMaxBookQty(event.target.value);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <CustomForm
            handleSubmit={handleAddFinalProductSubmit}
            handleClose={handleDrawerClose}
            data={[
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Item Name",
                defaultvalue: "",
                name: "name",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Description",
                defaultvalue: "",
                name: "description",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Capacity",
                defaultvalue: "",
                name: "capacity",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },

              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Material",
                defaultvalue: "",
                name: "material",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Life Span",
                defaultvalue: "",
                name: "lifeSpan",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },

              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "SKU Quantity",
                defaultvalue: "",
                name: "skuQuantity",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "HSN Code",
                defaultvalue: "",
                name: "hsnCode",
                textFieldType: appMeta.textFieldTypes.TEXT,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "CGST(%)",
                defaultvalue: "",
                name: "CGST",
                textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "SGST(%)",
                defaultvalue: "",
                name: "SGST",
                textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "IGST(%)",
                defaultvalue: "",
                name: "IGST",
                textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Minimum Book Quantity",
                defaultvalue: "",
                name: "minimumBookQuantity",
                textFieldType: appMeta.textFieldTypes.NUMBER,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.INPUT,
                placeHolder: "Maximum Book Quantity",
                defaultvalue: "",
                name: "maximumBookQuantity",
                textFieldType: appMeta.textFieldTypes.NUMBER,
                isHintRequired: true,
                isRequired: true,
              },
              {
                inputType: appMeta.inputFieldTypes.SELECT,
                placeHolder: "Order Type",
                // defaultvalue: "",
                name: "orderType",
                input: [
                  {
                    menuItem: "Insta",
                    value: 100,
                  },
                  {
                    menuItem: "Regualar",
                    value: 200,
                  },
                  {
                    menuItem: "Subscription",
                    value: 300,
                  },
                  {
                    menuItem: "All types",
                    value: 400,
                  },
                ],
              },
              // {
              //   inputType: appMeta.inputFieldTypes.INPUT,
              //   placeHolder: "Description",
              //   defaultvalue: "",
              //   name: "description",
              //   textFieldType: appMeta.textFieldTypes.TEXT,
              //   isHintRequired: true,
              //   isRequired: true,
              // },
              {
                inputType: appMeta.inputFieldTypes.RADIO,
                placeHolder: "Booking type",
                input: [
                  {
                    name: "bookingType",
                    value: 1,
                    label: "Refill",
                  },
                  {
                    name: "bookingType",
                    value: 2,
                    label: "One Time Sale",
                  },
                  {
                    name: "bookingType",
                    value: 3,
                    label: "Both",
                  },
                ],
              },
              {
                conditionallyRenderData: {
                  name: "bookingType",
                  value: ["1", "3"],
                },
                title: "Refill",
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "MRP",
                    defaultvalue: "",
                    name: "refilMrp",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Sale Price",
                    defaultvalue: "",
                    name: "refilSalePrice",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Deposit",
                    defaultvalue: "",
                    name: "refilDeposit",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.SELECT,
                    placeHolder: "Manufacture Type",
                    name: "refilManufactureType",
                    input: [
                      {
                        menuItem: "Insta",
                        value: 100,
                      },
                      {
                        menuItem: "Regualar",
                        value: 200,
                      },
                      {
                        menuItem: "Subscription",
                        value: 300,
                      },
                      {
                        menuItem: "All types",
                        value: 500,
                      },
                    ],
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Refund amount",
                    defaultvalue: "",
                    name: "refilRefundAmount",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                ],
              },
              {
                conditionallyRenderData: {
                  name: "bookingType",
                  value: ["2", "3"],
                },
                title: "One time sale",
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "MRP",
                    defaultvalue: "",
                    name: "otsMrp",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Sale Price",
                    defaultvalue: "",
                    name: "otsSalePrice",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Deposit",
                    defaultvalue: "",
                    name: "otsDeposit",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.SELECT,
                    placeHolder: "Manufacture Type",
                    name: "OtsManufactureType",
                    input: [
                      {
                        menuItem: "Insta",
                        value: 100,
                      },
                      {
                        menuItem: "Regualar",
                        value: 200,
                      },
                      {
                        menuItem: "Subscription",
                        value: 300,
                      },
                      {
                        menuItem: "All types",
                        value: 500,
                      },
                    ],
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Refund amount",
                    defaultvalue: "",
                    name: "otsRefundAmount",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    // isRequired: true,
                  },
                ],
              },
              {
                title: "Upload",
                data: [
                  {
                    inputType: appMeta.inputFieldTypes.FILE,
                    name: "image",
                    placeHolder: "Product Image",
                  },
                ],
              },
            ]}
          />
          {/* <TextField
            label="Name"
            fullWidth
            value={name}
            size="small"
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            label="Capacity"
            fullWidth
            value={capacity}
            size="small"
            onChange={(event) => setCapacity(event.target.value)}
          /> */}
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="Material"
            fullWidth
            value={material}
            size="small"
            onChange={(event) => setMaterial(event.target.value)}
          />
          <TextField
            label="Life Spans"
            fullWidth
            value={lifeSpan}
            onChange={(event) => setLifeSpan(event.target.value)}
            size="small"
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="SKU Quantity"
            fullWidth
            value={skuQty[0]}
            onChange={(event) => setSkuQty(event.target.value)}
            size="small"
            type="number"
          />
          <TextField
            label="HSN Code"
            fullWidth
            value={hsnCode}
            type="number"
            size="small"
            onChange={(event) => setHsnCode(event.target.value)}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="CGST %"
            fullWidth
            size="small"
            value={itemCgst}
            onChange={(e) => setItemCgst(e.target.value)}
          />
          <TextField
            label="SGST %"
            fullWidth
            value={itemSgst}
            size="small"
            onChange={(e) => setItemSgst(e.target.value)}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="IGST %"
            fullWidth
            value={itemIgst}
            size="small"
            onChange={(e) => setItemIgst(e.target.value)}
          />
          <TextField
            label="Min Book Quantity"
            fullWidth
            type="number"
            value={minBookQty}
            size="small"
            onChange={handleMinBookQtyChange}
            inputProps={{ maxlength: 2 }}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="Max Book Quantity"
            fullWidth
            value={maxBookQty}
            size="small"
            type="number"
            error={error1 !== ""}
            helperText={error1}
            onChange={handleMaxBookQtyChange}
            inputProps={{ maxlength: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Order Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={orderType}
                label="Order Type"
                onChange={handleChangeOrderType}
              >
                <MenuItem value={100}>Insta</MenuItem>
                <MenuItem value={200}>Regualar</MenuItem>
                <MenuItem value={300}>Subscription</MenuItem>
                <MenuItem value={400}>All types</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
         
          <TextField
            label="Description"
            fullWidth
            value={description}
            size="small"
            onChange={(event) => setDescription(event.target.value)}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={bookingType}
              onChange={handleChangeBookingType}
            >
              <FormControlLabel value={1} control={<Radio />} label="Refill" />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="One Time Sale"
              />
              <FormControlLabel value={3} control={<Radio />} label="Both" />
            </RadioGroup>
          </FormControl>
        </Box> */}
        {/* {bookingType === "1" && (
          <>
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              Refill
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                label="MRP"
                fullWidth
                value={mrpR}
                size="small"
                type="number"
                onChange={(event) => setMrpR(event.target.value)}
              />
              <TextField
                label="Sale price"
                fullWidth
                value={spR}
                size="small"
                type="number"
                onChange={(event) => setSpR(event.target.value)}
              />
              <TextField
                label="Deposit"
                fullWidth
                value={depositR}
                size="small"
                type="number"
                onChange={(event) => setDepositR(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={orderType}
                    label="Order Type"
                    onChange={handleChangeOrderType}
                  >
                    <MenuItem value={100}>Insta</MenuItem>
                    <MenuItem value={200}>Regualar</MenuItem>
                    <MenuItem value={300}>Subscription</MenuItem>
                    <MenuItem value={400}>All types</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Refund Amount"
                fullWidth
                value={refundAmountR}
                size="small"
                type="number"
                onChange={(event) => setRefundAmountR(event.target.value)}
              />
            </Box>
          </>
        )} */}

        {/* {bookingType === "2" ? (
          <>
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              One time sale
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                label="MRP"
                fullWidth
                value={mrpO}
                size="small"
                onChange={(event) => setMrpO(event.target.value)}
              />
              <TextField
                label="Sale price"
                fullWidth
                value={spO}
                size="small"
                onChange={(event) => setSpO(event.target.value)}
              />
              <TextField
                label="Deposit"
                fullWidth
                value={depositO}
                size="small"
                onChange={(event) => setDepositO(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={orderType}
                    label="Order Type"
                    onChange={handleChangeOrderType}
                  >
                    <MenuItem value={100}>Insta</MenuItem>
                    <MenuItem value={200}>Regualar</MenuItem>
                    <MenuItem value={300}>Subscription</MenuItem>
                    <MenuItem value={400}>All types</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Refund Amount"
                fullWidth
                value={refundAmountO}
                size="small"
                onChange={(event) => setRefundAmountO(event.target.value)}
              />
            </Box>
          </>
        ) : (
          ""
        )} */}

        {/* {bookingType === "3" && (
          <>
            {" "}
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              Refill
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                label="MRP"
                fullWidth
                value={mrpR}
                size="small"
                onChange={(event) => setMrpR(event.target.value)}
              />
              <TextField
                label="Sale price"
                fullWidth
                value={spR}
                size="small"
                onChange={(event) => setSpR(event.target.value)}
              />
              <TextField
                label="Deposit"
                fullWidth
                value={depositR}
                size="small"
                onChange={(event) => setDepositR(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={orderType}
                    label="Order Type"
                    onChange={handleChangeOrderType}
                  >
                    <MenuItem value={100}>Insta</MenuItem>
                    <MenuItem value={200}>Regualar</MenuItem>
                    <MenuItem value={300}>Subscription</MenuItem>
                    <MenuItem value={400}>All types</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Refund Amount"
                fullWidth
                value={refundAmountR}
                size="small"
                onChange={(event) => setRefundAmountR(event.target.value)}
              />
            </Box>
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              One time sale
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <TextField
                label="MRP"
                fullWidth
                value={mrpO}
                size="small"
                onChange={(event) => setMrpO(event.target.value)}
              />
              <TextField
                label="Sale price"
                fullWidth
                value={spO}
                size="small"
                onChange={(event) => setSpO(event.target.value)}
              />
              <TextField
                label="Deposit"
                fullWidth
                value={depositO}
                size="small"
                onChange={(event) => setDepositO(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={orderType}
                    label="Order Type"
                    onChange={handleChangeOrderType}
                  >
                    <MenuItem value={100}>Insta</MenuItem>
                    <MenuItem value={200}>Regualar</MenuItem>
                    <MenuItem value={300}>Subscription</MenuItem>
                    <MenuItem value={400}>All types</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Refund Amount"
                fullWidth
                value={refundAmountO}
                size="small"
                onChange={(event) => setRefundAmountO(event.target.value)}
              />
            </Box>
          </>
        )} */}

        {/* <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddProduct}
            sx={appStyle.general.button.blueButton}
          >
            Add Final Product
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDrawerClose}
            sx={appStyle.general.button.redButton}
          >
            Close
          </Button>
        </Box> */}
      </Box>
    </div>
  );
}

export default AddFinalSkuProduct;
