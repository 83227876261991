import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

/**
 * @author : Janarthanan
 * @Date : 6/11/24
 * @description Reusable select dropdown component for choosing from a list of options.
 * @param { size, label, optionsList, selectedValue, setSelectedValue, sltFldStyle }
 * @return The rendered select dropdown component.
 */
function SelectTextField({
  size,
  defaultvalue,
  label,
  variant,
  required,
  disabled,
  optionsList,
  selectedValue,
  setSelectedValue,
}) {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 220,
        overflowX: "auto", // Allow horizontal scrolling
        // whiteSpace: "nowrap", // Prevent text from wrapping to the next line
        overflowX: "hidden", // Hide horizontal overflow
        scrollbarWidth: "thin", // Firefox
        msOverflowStyle: "none", // IE and Edge
        "&::-webkit-scrollbar": {
          height: "8px", // Adjust scrollbar height
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Scrollbar thumb color
          borderRadius: "4px", // Scrollbar thumb rounded corners
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Scrollbar track color
        },
      },
    },
  };

  // Handles change events when a new value is selected from the dropdown.
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };
  console.log("optionsList", optionsList);
  return (
    <FormControl
      sx={{ width: size }}
      variant={variant}
      // size={size}
      required={required}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        value={selectedValue}
        onChange={handleChange}
        MenuProps={menuProps}
      >
        {optionsList?.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              lineHeight: 1.5,
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectTextField;
