const cssObject = {
  box: {
    //fox for SKU pages in dashboard
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    margin: "0.5rem",
    // width: "100%",
    gap: "1rem",
  },

  sku: {
    firstLevelTab: {},
    secondLevelTab: {
      appBar: {
        marginTop: "10px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
      },
    },
  },

  general: {
    tab: {
      firstLevel: {
        fontSize: { xs: "0.7rem", md: "0.9rem" },
        fontWeight: "800",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      secondLevel: {
        fontSize: { xs: "0.7rem", md: "0.9rem" },
        // fontWeight: "bold",
      },
    },
    card: {
      title: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "18px",
      },
    },
    button: {
      addButtonBox: {
        display: "flex",
        marginRight: "15px",
        justifyContent: "flex-end",
      },
      addButton: {
        fontSize: { xs: "0.8px", md: "0.8rem" },
        fontWeight: "bold",
        background: "linear-gradient(#02173a, #0b48a7)",
        ":hover": {
          background: "linear-gradient(#0b48a7,#02173a )",
        },
      },
      editAndCancelButton: {
        // use Same for start and stop
        box: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        },
      },
      addToCartButton: {
        box: {
          position: "relative",
          // bottom: 16,
          right: 16,
          // zIndex: 1000,
          // Set a high z-index to ensure the button appears above other elements
        },
        button: {
          fontWeight: "bold",
          fontSize: "20px",
        },
      },

      editButton: {
        //Used the  same for more button
        fontSize: { xs: "0.6rem", md: "0.6rem" },
        background: "linear-gradient(#02173a, #0b48a7)",
        ":hover": {
          background: "linear-gradient(#0b48a7,#02173a )",
        },
      },

      blueButton: {
        fontSize: { xs: "0.6rem", md: "0.6rem" },
        background: "linear-gradient(#02173a, #0b48a7)",
        margin: "1rem",
        color: "white",
        ":hover": {
          background: "linear-gradient(#0b48a7,#02173a)",
        },
        "&.Mui-disabled": {
          background: "#7284b5",
          color: "#E0E1E4", // Change the text color when disabled
        },
      },
      blueButtonDarkTheme: {
        fontSize: { xs: "0.6rem", md: "0.6rem" },
        background: "linear-gradient(#1f78ff, #0b48a7)",
        margin: "1rem",
        color: "white",
        ":hover": {
          background: "linear-gradient(#0b48a7,#1f78ff)",
        },
        "&.Mui-disabled": {
          background: "#7284b5",
          color: "#E0E1E4", // Change the text color when disabled
        },
      },
      redButton: {
        fontSize: { xs: "0.6rem", md: "0.6rem" },
        color: "white",
        background: "linear-gradient(#a0130e, #ed0d0d)",
        margin: "1rem",
        ":hover": {
          background: "linear-gradient(#ed0d0d,#a0130e)",
        },
        "&.Mui-disabled": {
          background: "#6a2a2b",
          color: "#E0E1E4",
        },
      },
    },
    drawField: {
      drawFieldBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      },
    },
    table: {
      headderRowCell: {
        fontWeight: "600",
        textAlign: "start",
        fontSize: { xs: "13px", md: "15px" },
        // border: "0.5px solid #ecf0f3",
        // color: "#fff",
        // background: "linear-gradient(#02173a, #0b48a7)",
      },
      bodyRowCell: {
        fontSize: { xs: "11px", md: "12px" },
        textAlign: "start",
        // border: "0.5px solid #ecf0f3",
      },
      tableContainer: {
        borderRadius: "10px",
        maxHeight: 375,
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
      tableHead: {
        boxShadow: 0,
      },
      containerForTable: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // width: "100%",
      },
      pagination: {
        width: "100%",
      },
    },
    sideNavBar: {
      menuText: {
        fontSize: "0.8rem",
      },
    },
    colors: (colors) => {
      return {
        // bookWaterTitleColor: colors?.skyBlueAccent[100],
        otherOrganisationTitleColor: colors?.grey[100],
        sideBarMenuColor: colors?.primary[500],
        sideMenuBarActive: colors?.basic.sidebar_active,
        sideMenuBarHover: "red",
        // colors.basic.sidebar_hover,
        title: colors?.skyBlueAccent[100],
        secondarySkuTabBar: {
          background: "transparent",
          indicatorColor: colors?.blueAccent[400],
        },
        table: {
          tableHeadBackground: "#2d64e8",
        },
        pageNumberBackground: "#2D81FF",
      };
    },
    displayTitle: {
      fontSize: {
        fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem" },
        fontWeight: "800",
      },
    },
  },
};

export default cssObject;
