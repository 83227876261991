/**
 * @author : Janarthanan
 * @Date : 6/11/24
 * @description Reusable Form component.
 * @Params
 *          configConfirmation={{
              isConfirmationRequired: true,
              dialogContent: "Are you sure you want to update changes?",
            }}
              
 *         data={[
                  conditionallyRenderData: { name: "facility", value: "28" },
 *                title:'Your title'
 *               data={[
                    
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Item Name",
                    defaultValue: "",
                    name: "name",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                    {
                inputType: appMeta.inputFieldTypes.SELECT,
                placeHolder: "Manufacture Type",
                name: "manufactureType",
                input: [
                  {
                    menuItem: "Supplier/manufacturer",
                    value: "Supplier/manufacturer",
                  },
                  or use  getInputForSelectFromArray(array, menuField, valueField) from "../../Components/Utils/CustomFormUtils";
                ],
              },
              {
                    inputType: appMeta.inputFieldTypes.SWITCH,
                     placeHolder: { on: "Active", off: "Deactive" },
                    name: "SwitchisActive",
                    defaultValue: false,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.RADIO,
                    placeHolder: "Gender",
                    input: [
                      {
                        name: "gender",
                        value: "female",
                        label: "Female",
                      },
                      {
                        name: "gender",
                        value: "male",
                        label: "Male",
                      },
                    ],
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    textFieldType: appMeta.textFieldTypes.DATE,
                    name: "Date",
                    placeHolder: "DOB",
                    defaultValue: moment().format("YYYY-MM-DD"),
                  },
                  {
                    inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                    optionKey: "org_name",
                    outputKey: "id",
                    searchAndUpdateAPI: (searchText)=>{},
                    input: organizationList || [],
                    name: "facility",
                     label: "Enter Facility Name",
            },
             {
              inputType: appMeta.inputFieldTypes.FILE,
              name: "fssaiFile",
              placeHolder: "FSSAI",
            },
 *              
                  ]}
                
 *                handleSubmit={function(formData)}
                  handleClose={function}
                  hideCloseButton={true}
 * }
 * @return Return form components.
 */

import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "./CustomButton";
import {
  getNamesFromOneLevelNestedObject,
  getErrorsFromOneLevelNestedObject,
  getIsRequiredFromOneLevelNestedObject,
  getInputFieldBasedOnType,
} from "../Utils/CustomFormUtils";
import appFonts from "../../AppMeta/appFonts";
import CustomConfirmationDialog from "./CustomConfirmationDialog";

function CustomForm({
  data,
  handleSubmit,
  hideCloseButton,
  handleClose,
  configConfirmation = { isConfirmationRequired: false },
}) {
  //state
  const [formData, setFormData] = useState(
    getNamesFromOneLevelNestedObject(data)
  );
  const [errors, setErrors] = useState(getErrorsFromOneLevelNestedObject(data));
  const isRequired = getIsRequiredFromOneLevelNestedObject(data);
  const [errorText, setErrorText] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  //function for confirmation

  //functions for handle changes

  const handleInputChange = (name, value, section) => {
    console.log("--FormData---", name, value, section);
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]:
        section?.conditionallyRenderData === undefined
          ? value === ""
            ? isRequired[name]
            : false
          : section?.conditionallyRenderData?.value ===
            formData[section.conditionallyRenderData?.name]
          ? value === ""
            ? isRequired[name]
            : false
          : false,
    });
  };

  const handleCustomSubmit = () => {
    let newErrors = {};
    let hasError = false;
    Object.keys(formData).forEach((key) => {
      if (isRequired[key] && formData[key] === "") {
        newErrors[key] = "This field is required";
        hasError = true;
      }
    });
    setErrors(newErrors);
    if (!hasError) {
      setErrorText("");
      if (configConfirmation?.isConfirmationRequired) {
        console.log("--Onsubmit--", formData, isRequired);
        setConfirmationDialogOpen(true);
      } else {
        console.log("--Onsubmit--", formData, isRequired);
        handleSubmit(formData);
      }
    } else {
      console.log("--Onsubmit error--", formData, isRequired);
      setErrorText("Please fill all required fields");
    }
  };

  const loopinputData = (ele) => {
    return (
      <Grid container={ele.numberOfColumn !== 1}>
        <Grid sm={12} md={12} lg={12}>
          {ele.title !== undefined && (
            <Box
              sx={{
                marginY: "1rem",
                textAlign: "center",
              }}
            >
              <Typography variant={appFonts.cardTitle}>{ele.title}</Typography>
            </Box>
          )}
        </Grid>

        {ele.data ? (
          ele.data.map((field, index) => {
            return (
              <Grid
                sm={12}
                lg={12 / ele.numberOfColumn}
                md={12 / ele.numberOfColumn}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {getInputFieldBasedOnType(
                    field,
                    formData,
                    errors,
                    handleInputChange,
                    ele
                  )}
                </Box>
              </Grid>
            );
          })
        ) : (
          <Grid lg={12} sm={12} md={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {getInputFieldBasedOnType(
                ele,
                formData,
                errors,
                handleInputChange
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data?.map((ele) => {
        return (
          <>
            {ele.conditionallyRenderData?.value === undefined
              ? loopinputData(ele)
              : ele.conditionallyRenderData?.value.includes(
                  formData[ele.conditionallyRenderData?.name]
                ) && loopinputData(ele)}
          </>
        );
      })}
      <Box>
        {!hideCloseButton && (
          <CustomButton
            color="error"
            label={"Close"}
            handleClick={handleClose}
          />
        )}
        <CustomButton handleClick={handleCustomSubmit} label={"Submit"} />
        <Typography sx={{ color: "red" }}>{errorText}</Typography>
      </Box>
      <CustomConfirmationDialog
        open={confirmationDialogOpen}
        onClose={() => {
          setConfirmationDialogOpen(false);
        }}
        dialogTitle={configConfirmation.dialogTitle}
        dialogContent={configConfirmation.dialogContent}
        onConfirm={() => {
          handleSubmit({ ...formData, isConfirmed: true });
          setConfirmationDialogOpen(false);
        }}
      />
    </Box>
  );
}

export default CustomForm;
