/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding roles list
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  profile: {},
  address: {},
  metaData: [],
  access_to_pages: [],
  profile_pic_name: "",
  profile_pic_path: "",
};

const profileReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  // console.log(action);
  switch (action.type) {
    case actionType.GET_PROFILE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // profile: action.payload.org_facility_details,
        address: action.payload.address,
        access_to_pages: action.payload.data?.role_details?.access_to_pages,
        profile: action.payload.data,
      };
    case actionType.GET_APP_META_DATA:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        metaData: action.payload.error ? [] : action.payload.data,
      };
    case actionType.GET_PROFILE_PIC:
      console.log("action.file_path", action.payload);
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        profile_pic_path: action.payload,
      };
    case actionType.UPLOAD_PROFILE_PIC:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        profile_pic_name: action.payload.file_name
          ? action.payload.file_name
          : "",
      };
    default:
      return state;
  }
};

export default profileReducer;
