import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  TablePagination,
  Typography,
} from "@mui/material";
import { appColors } from "../../AppMeta/appColors";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@mui/material";
import { SettingsSuggestRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import {
  GetComponents,
  GetRawMaterials,
  UpdateComponents,
  UpdateRawMaterial,
} from "../../Redux/Actions/ProductComponentsAction";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomTable from "../../Components/CustomComp/CustomTable";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";

const rawMaterialsData = [
  {
    id: 1,
    name: "Plastic for Can Manufacturing",
    quantity: 100,
    unit: "Kilograms",
    description: "Material for making cans",
  },
  {
    id: 2,
    name: "Metal for Cap Manufacturing",
    quantity: 50,
    unit: "Kilograms",
    description: "Material for making caps",
  },
  {
    id: 3,
    name: "Sticker Material",
    quantity: 200,
    unit: "Sheets",
    description: "Material for printing stickers",
  },
  {
    id: 4,
    name: "Ink for Printing",
    quantity: 5,
    unit: "Liters",
    description: "Ink for printing labels",
  },
  {
    id: 5,
    name: "Cardboard for Packaging",
    quantity: 150,
    unit: "Sheets",
    description: "Material for packaging",
  },
];

const FinishedGoodsTable = ({
  allFinishedGoods,
  pageNumber,
  pageSize,
  lastPageNumber,
  createdAtSort,
  available,
  setDrawOpenProp,
  setPageNumber,
  setPageSize,
}) => {
  const dispatch = useDispatch();
  const [editRMDialog, setEditRMDialog] = React.useState(false);

  const [name, setName] = React.useState("");
  const [itemDescription, setItemDescription] = React.useState("");
  const [material, setMaterial] = React.useState("");
  const [cgst, setCgst] = React.useState("");
  const [sgst, setSgst] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getOrders, setGetOrders] = React.useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [description, setDescription] = React.useState("");
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const [rawMaterials, setRawMaterials] = React.useState(rawMaterialsData);
  const [itemMaterials, setItemMaterials] = React.useState([]);

  const [nameSearch, setNameSearch] = React.useState("");
  const [availabilityFilter, setAvailabilityFilter] = React.useState("all");
  const [error, setError] = React.useState("");
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");

  const handleSearch = () => {
    // Implement the search logic
    // You can dispatch an action to fetch filtered data based on the search criteria
    console.log("Search by Name:", nameSearch);
    dispatch(
      GetComponents(pageNumber, pageSize, available, createdAtSort, nameSearch)
    );
  };

  const handleFilterChange = (event) => {
    setAvailabilityFilter(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeOrders = () => {
    setGetOrders(allFinishedGoods);
  };

  const handleFilter = () => {
    // Implement the filter logic
    // You can dispatch an action to fetch filtered data based on the filter criteria
    console.log("Filter by Availability:", availabilityFilter);
    dispatch(GetComponents(pageNumber, pageSize, available, createdAtSort));
  };

  const handleClickRow = (material) => {
    setSelectedRow(material);
    //
    setName(material?.name);
    setItemDescription(material?.details?.description);
    setMaterial(material?.details.material);
    setItemCgst(material?.details?.tax_details?.gst.CGST);
    setItemSgst(material?.details?.tax_details?.gst.SGST);
    setItemMaterials(material?.raw_materials);
    //
    setEditRMDialog(true);
  };
  // React.useEffect(() => {
  //   setName(selectedRow?.name);
  //   setItemDescription(selectedRow?.details?.description);
  //   setMaterial(selectedRow?.details.material);
  //   setItemCgst(selectedRow?.details?.tax_details?.gst.CGST);
  //   setItemSgst(selectedRow?.details?.tax_details?.gst.SGST);
  //   setItemMaterials(selectedRow?.raw_materials);
  // }, [selectedRow]);

  // const handleUpdate = (formData) => {
  //   setName(formData?.name);
  //   setItemDescription(formData?.description);
  //   setMaterial(formData?.material);
  //   setItemCgst(formData?.CGST);
  //   setItemSgst(formData?.SGST);
  //   setItemMaterials(formData?.raw_materials);
  //   setDescription("Do you to edit the finished goods details?");
  //   setOpenConfirmationDialog(true);
  // };

  const handleUpdate = (formData) => {
    console.log("---itemMaterials---", itemMaterials);
    const reqObj = {
      id: parseInt(selectedRow.id),
      name: formData?.name,
      is_force_update: formData?.isConfirmed,
      is_available: true,
      details: {
        description: formData?.description,
        material: formData?.materialerial,
        tax_details: {
          gst: {
            CGST: formData?.CGST,
            SGST: formData?.SGST,
          },
        },
      },
      raw_materials: itemMaterials?.map((material) => ({
        id: material.id,
        quantity: parseInt(material.quantity),
        unit: material.units,
      })),
    };
    console.log(reqObj);
    dispatch(UpdateComponents(reqObj));
    setTimeout(() => {
      dispatch(GetComponents(pageNumber, pageSize, available, createdAtSort));
    }, 1000);
    setOpenConfirmationDialog(false);
    setEditRMDialog(false);
  };
  // React.useEffect(() => {
  //   if (isConfirmed) {
  //     const reqObj = {
  //       id: parseInt(selectedRow.id),
  //       name: name,
  //       is_force_update: true,
  //       is_available: true,
  //       details: {
  //         description: itemDescription,
  //         material: material,
  //         tax_details: {
  //           gst: {
  //             CGST: cgst,
  //             SGST: sgst,
  //           },
  //         },
  //       },
  //       raw_materials: itemMaterials?.map((material) => ({
  //         id: material.id,
  //         quantity: parseInt(material.quantity),
  //         units: material.unit,
  //       })),
  //     };
  //     console.log(reqObj);
  //     dispatch(UpdateComponents(reqObj));
  //     setTimeout(() => {
  //       dispatch(GetComponents(pageNumber, pageSize, available, createdAtSort));
  //     }, 1000);
  //     setOpenConfirmationDialog(false);
  //     setEditRMDialog(false);
  //   }
  // }, [isConfirmed]);
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setItemDescription(event.target.value);
  };

  //   const handleChangeUnit = (event) => {
  //     setUnit(event.target.value);
  //   };

  const handleChangeCgst = (event) => {
    setCgst(event.target.value);
  };

  const handleChangeSgst = (event) => {
    setSgst(event.target.value);
  };

  const handleQuantityChange = (quantity, materialName) => {
    setItemMaterials((prevMaterials) =>
      prevMaterials.map((material) =>
        material.name === materialName
          ? { ...material, quantity: quantity }
          : material
      )
    );
  };

  const handleItemCgstChange = (event) => {
    setItemCgst(event.target.value);
    if (event.target.value !== setItemSgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must not be greater than two digit number");
    } else {
      setItemCgst(newValue);
      setError("");
    }
  };

  const isNumeric = (value) => {
    return /^\d{0,2}$/.test(value);
  };

  const disableSubmit = () => {
    return error !== "" || itemCgst === "" || itemSgst === "";
  };

  const handleItemSgstChange = (event) => {
    setItemSgst(event.target.value);
    if (event.target.value !== setItemCgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must be a number less than or equal to 99");
    } else {
      setItemSgst(newValue);
      setError("");
    }
  };

  const handleFinishedGoodsFilterClose = (data) => {};
  const handleFinishedGoodsSortClose = (data) => {};
  const handleAddFinishedGoods = () => {
    setDrawOpenProp(true);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;
  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <TextField
          label="Search by Name"
          value={nameSearch}
          onChange={(e) => setNameSearch(e.target.value)}
          size="small"
        />
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="availability-filter-label">Availability</InputLabel>
          <Select
            labelId="availability-filter-label"
            id="availability-filter"
            value={availabilityFilter}
            label="Availability"
            onChange={handleFilterChange}
          >
            <MenuItem value={true}>Available</MenuItem>
            <MenuItem value={false}>Unavailable</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleSearch}>
          Search
        </Button>
        <Button variant="contained" onClick={handleFilter}>
          Filter
        </Button>
      </Box> */}
      <Box sx={appStyle.general.table.containerForTable}>
        <CustomTable
          tableHead={["ID", "Name", "Description", "Matreial"]}
          tableDataKey={[
            "id",
            "name",
            "details.description",
            "details.material",
          ]}
          tableData={allFinishedGoods}
          tableRowClickFunction={(index, row) => handleClickRow(row)}
          pagination={true}
          filter={true}
          filterSelectOption={[
            { value: "Roll Name", label: "role_name" },
            { value: "Full Name", label: "fullname" },
          ]}
          handleFilterClose={handleFinishedGoodsFilterClose}
          sort={true}
          sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
          handleSortClose={handleFinishedGoodsSortClose}
          addButton={true}
          handleOnClickAddButton={handleAddFinishedGoods}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
        />
        {/* <CustomFilter />
        <TableContainer
          sx={appStyle.general.table.tableContainer}
          component={Paper}
        >
          
          <Table stickyHeader>
            <TableHead
              sx={{
                backgroundColor:
                  appStyle.general.colors(colors).table.tableHeadBackground,
                color: colors.primary[300],
                ...appStyle.general.table.tableHead,
              }}
            >
              <TableRow>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  ID
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Name
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Description
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Material
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: colors.primary[600] }}>
              {(rowsPerPage > 0
                ? allFinishedGoods.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : allFinishedGoods
              ).map((row, index) => (
                <TableRow
                  key={index}
                  // onClick={() => handleClickRow(row)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.id}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.name}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.details?.description}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.details?.material}
                  </TableCell>
                  <TableCell>
                    <Button
                      color={appFonts.tablePrimaryButtonColor}
                      variant={appFonts.tablePrimaryButtonVarient}
                      sx={appStyle.general.button.editButton}
                      onClick={() => handleClickRow(row)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={appStyle.general.table.pagination}>
          <TablePagination
            rowsPerPageOptions={[25, 100, 500, 1000]}
            component="div"
            count={allFinishedGoods.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box> */}
      </Box>
      <CustomDrawer open={editRMDialog} title={"Edit Finished goods Details"}>
        <CustomForm
          configConfirmation={{
            isConfirmationRequired: true,
            dialogContent: "Are you sure you want to update changes?",
          }}
          data={[
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Name",
              defaultValue: name,
              name: "name",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Description",
              defaultValue: itemDescription,
              name: "description",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Material",
              defaultValue: material,
              name: "material",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "CGST",
              defaultValue: itemCgst,
              name: "CGST",
              textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "SGST",
              defaultValue: itemSgst,
              name: "SGST",
              textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              isHintRequired: true,
              isRequired: true,
            },
          ]}
          handleSubmit={handleUpdate}
          handleClose={() => setEditRMDialog(false)}
        />
      </CustomDrawer>
      <Dialog
      // open={editRMDialog}
      // onClose={() => setEditRMDialog(false)}
      >
        <DialogTitle>Edit Finished goods Details</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              padding: "10px",
            }}
          >
            {/* <TextField
              label="Name"
              name="name"
              value={name}
              onChange={handleChangeName}
              fullWidth
            />
            <TextField
              label="Description"
              name="Description"
              value={itemDescription}
              onChange={handleChangeDescription}
              fullWidth
            />
            <TextField
              label="Material"
              name="Material"
              value={material}
              onChange={handleChangeDescription}
              fullWidth
            /> */}
            {/* <TextField
              label="Unit"
              name="Unit"
              value={unit}
              onChange={handleChangeUnit}
              fullWidth 
            /> */}
            {/* <TextField
              label="CGST"
              name="CGST"
              fullWidth
              error={error !== ""}
              helperText={error}
              value={itemCgst}
              onChange={handleItemCgstChange}
              InputProps={{ inputProps: { maxLength: 2, min: 0 } }}
            />
            <TextField
              label="SGST"
              name="SGST"
              fullWidth
              error={error !== ""}
              helperText={error}
              value={itemCgst}
              onChange={handleItemCgstChange}
              inputProps={{ maxLength: 2, min: 0 }}
            /> */}
            {/* <FormControl variant="outlined" fullWidth> 
              <InputLabel id="materials-label">Select Raw Materials</InputLabel>
              <Select
                labelId="materials-label"
                multiple
                value={itemMaterials?.map((material) => material.id)} // Use IDs as values
                onChange={(e) => {
                  const selectedMaterialIds = e.target.value;
                  const selectedMaterials = rawMaterialsData.filter(
                    (material) => selectedMaterialIds.includes(material.id)
                  );
                  setItemMaterials(selectedMaterials);
                }}
                label="Select Raw Materials"
                renderValue={(selected) =>
                  selected
                    .map(
                      (materialId) =>
                        `${materialId} (${
                          rawMaterialsData.find(
                            (material) => material.id === materialId
                          ).unit
                        }) - ${
                          rawMaterialsData.find(
                            (material) => material.id === materialId
                          ).quantity
                        }`
                    )
                    .join(", ")
                }
              >
                {rawMaterialsData.map((material) => (
                  <MenuItem key={material.id} value={material.id}>
                    {material.id} ({material.unit}) -{" "}
                    {`Quantity - ${material.quantity}`}
                  </MenuItem>
                ))}
              </Select>
                </FormControl>*/}
            {/* Selected items table */}
            {/* {itemMaterials?.length > 0 && ( */}
            {/* // <TableContainer component={Paper} style={{ marginTop: "24px" }}>
              //   <Table>
              //     <TableHead sx={{ backgroundColor: appColors.secondary }}>
              //       <TableRow>
              //         <TableCell>Selected Item</TableCell>
              //         <TableCell>Unit</TableCell>
              //         <TableCell>Quantity/Finished Good</TableCell>
              //       </TableRow>
              //     </TableHead>
              //     <TableBody>
              //       {itemMaterials.map((item) => (
              //         <TableRow key={item.name} style={{ color: "green" }}>
              //           <TableCell>Raw material ID - {item.id}</TableCell>
              //           <TableCell>{item.units}</TableCell>
              //           <TableCell>
              //             <TextField
              //               type="number"
              //               value={item.quantity || 0}
              //               onChange={(e) =>
              //                 handleQuantityChange(e.target.value, item.name)
              //               }
              //             />
              //           </TableCell>
              //         </TableRow>
              //       ))}
              //     </TableBody>
              //   </Table>
              // </TableContainer> */}
          </Box>
          {/* Add more textfields for other fields */}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
              padding: "10px",
            }}
          >
            <Button
              disabled={disableSubmit()}
              variant="contained"
              color="secondary"
              onClick={handleUpdate}
              sx={appStyle.general.button.blueButton}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setEditRMDialog(false)}
              sx={appStyle.general.button.redButton}
            >
              Close
            </Button>
          </Box> */}
        </DialogContent>
      </Dialog>
      {/* {openConfirmationDialog && (
        <CustomConfirmationDialog
          open={openConfirmationDialog}
          // setOpenConfirmationDialog={setOpenConfirmationDialog}
          onClose={() => {
            setOpenConfirmationDialog(false);
          }}
          dialogContent={description}
          // setIsConfirmed={setIsConfirmed}
          onConfirm={handleEditOnConfirm}
        />
      )} */}
    </>
  );
};

export default FinishedGoodsTable;
