const styles = {
  gridContainer: { marginTop: "30px" },
  displayImageBox: {
    // width: "150px",
    // height: "150px",
    // borderRadius: "50%",
    // objectFit: "cover",
    width: 150,
    height: 150,
    borderRadius: "50%",
    objectFit: "cover",
    // mt: 2,
  },
  uploadImageBox: {
    backgroundColor: "none",
    height: "50px",
    width: "40px",
    borderRadius: "50%",
    marginTop: "-55px",
    marginRight: "-100px",
  },
  gi1: {
    display: "flex",
    alignItems: "center",
    marginLeft: { xs: "15px", sm: "0px" },
    justifyContent: { xs: "center", sm: "flex-start" },
  },
  profileDataBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  profileHeader: {
    fontWeight: 800,
    fontSize: "16px",
    textAlign: "left",
    minWidth: "100px",
  },
  profileContent: {
    fontSize: "16px",
    textAlign: "right",
    marginLeft: "15px",
  },
};

export default styles;
