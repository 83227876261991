import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React from "react";
import CustomButton from "../../Components/CustomComp/CustomButton";
import Html5QrcodePlugin1 from "../Home/Html5QrcodePlugin";
import QrBarcodeLinkingDialog from "./QrBarcodeLinkingDialog";
import ResultContainerPlugin1 from "./ResultContainerPlugin1";
import { LinkQrBarcodes } from "../../Redux/Actions/linkingQrBarcodesAction";
import { useDispatch } from "react-redux";

function Linking({ setOpenScannerDialog, tabvalue }) {
  const inQrBarcodeAdmin = true;

  const dispatch = useDispatch();
  const [scannedQrCode, setScannedQrCode] = React.useState("");
  const [scannedBarCode, setScannedBarCode] = React.useState("");
  const [scannedQrCodeDetails, setScannedQrCodeDetails] = React.useState("");
  const [linkQrScannedId, setLinkQrScannedId] = React.useState("");
  const [decodedResults, setDecodedResults] = React.useState([]);
  const [linkingDialogOpen, setLinkingDialogOpen] = React.useState(false);
  const [scanQrcodeDetailBtn, setScanQrCodeDetailBtn] = React.useState(false);
  const [linkQrBarcodeBtn, setLinkQrBarcodeBtn] = React.useState(false);
  const [scanCount, setScanCount] = React.useState(0);
  const [link, setLink] = React.useState(false);
  // const [alignment, setAlignment] = React.useState('Scan');
  const [scanQrFlag, setScanQrFlag] = React.useState(false);
  const [linkQrFlag, setLinkQrFlag] = React.useState(false);
  const [GenerateFlag, setGenerateFlag] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [unMountFlag, setUnMountFlag] = React.useState(false);
  const [scanOption, setScanOption] = React.useState("scanner");
  // handleQrBarcodeIdFromScanner
  const [qrBarcodeId, setQrBarcodeId] = React.useState("");
  const [qrBarcodeIdNew, setQrBarcodeIdNew] = React.useState("");
  const [linkToggle, setLinkToggle] = React.useState("linkQr");
  React.useEffect(() => {
    if (link === true) {
      const reqObj = {
        qrcode_id: scannedQrCode,
        barcode_id: scannedBarCode,
      };
      console.log("---reqObj----", reqObj);
      dispatch(LinkQrBarcodes(reqObj));
      setScannedQrCode("");
      setScannedBarCode("");
      setLinkingDialogOpen(false);
      // setScanCount(0);
      setDecodedResults([]);
      setLink(false);
      setLinkQrBarcodeBtn(false);
    }
  }, [link]);
  React.useEffect(() => {
    if (linkToggle === "linkQr" && linkQrScannedId) {
      console.log(linkQrScannedId);
      const reqObj = {
        qrcode_id: linkQrScannedId,
        // barcode_id: "",
      };
      console.log(reqObj);
      dispatch(LinkQrBarcodes(reqObj));
      setLinkQrScannedId("");
      setScannedQrCode("");
      setScannedBarCode("");
      setScanCount(0);
      setDecodedResults([]);
    }
  }, [linkToggle, linkQrScannedId]);

  React.useEffect(() => {
    console.log(linkToggle);
    console.log(scannedQrCode);
    console.log(`scannedQrCode ${scannedQrCode}`);
    if (
      linkToggle === "linkQrBarcode" &&
      scannedQrCode !== "" &&
      scannedQrCode !== undefined
    ) {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
    console.log(`scannedBarCode ${scannedBarCode}`);
    if (
      linkToggle === "linkQrBarcode" &&
      scannedBarCode !== "" &&
      scannedBarCode !== undefined
    ) {
      setLinkingDialogOpen(true);
      setLinkQrBarcodeBtn(false);
    }
  }, [scannedQrCode, scannedBarCode, linkToggle]);

  React.useEffect(() => {
    if (
      qrBarcodeIdNew === qrBarcodeId &&
      qrBarcodeId.length &&
      qrBarcodeIdNew.length > 6
    ) {
      console.log("--- qrBarcodeIdNew === qrBarcodeId ---");
      console.log(qrBarcodeIdNew);
      console.log(qrBarcodeId);
      if (tabvalue === "Scan-Qr/Barcode") {
        setScanQrCodeDetailBtn(true);
        setUnMountFlag(true);
        setScannedQrCodeDetails(qrBarcodeId);
        setDecodedResults([]);
      } else if (tabvalue === "Link-Qr/Barcode") {
        console.log("==== Data Tab2 ====");
        console.log(qrBarcodeId);
        setScannedQrCode(decodedResults[0]);
        setLinkQrScannedId(qrBarcodeId);
        setDecodedResults((prev) => [...prev, qrBarcodeId]);
        setScannedBarCode(qrBarcodeId);
      }
      setTimeout(() => {
        setQrBarcodeIdNew("");
        setQrBarcodeId("");
      }, 300);
    }
  }, [qrBarcodeId, qrBarcodeIdNew]);
  const handleLinking = () => {
    setOpenScannerDialog(true);
  };

  const handleChangeLink = (event) => {
    setLinkQrScannedId("");
    setScannedQrCode("");
    setScannedBarCode("");
    setScanCount(0);
    setDecodedResults([]);
    setLinkToggle(event.target.value);
  };

  const handleSelectScanOption = (event) => {
    console.log("-- handleSelectScanOption --");
    console.log(event.target.value);
    setScanOption(event.target.value);
    if (event.target.value === "camera") {
      setScanQrCodeDetailBtn(true);
      setScanQrFlag(true);
      setUnMountFlag(true);
    } else {
      setScanQrCodeDetailBtn(false);
      setScanQrFlag(false);
      setUnMountFlag(false);
    }
  };

  const handleQrBarcodeIdFromScanner = (event) => {
    console.log(event.target.value);
    setQrBarcodeId(event.target.value);
    setTimeout(() => {
      if (event.target.value !== qrBarcodeIdNew) {
        console.log("-- set data --");
        setQrBarcodeIdNew(event.target.value);
      } else {
        console.log("--- data is set ---");
      }
    }, 500);
  };

  const onNewScanResultQrCode = (decodedText, decodedResult) => {
    console.log(`Qr code scanned ${decodedText}`);
    if (decodedResult.result.format.formatName === "QR_CODE") {
      setScannedQrCodeDetails(decodedText);

      // dispatch(GetDetailsFromQrcode(decodedText));
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      setScannedQrCodeDetails(decodedText);
    }
    setScanQrFlag(false);
    setScanOption("scanner");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        borderRadius: "15px",
        m: "0px 10px 0px 10px",
      }}
      component={Paper}
      elevation={15}
    >
      <Box sx={{ pb: "20px" }}>
        {/* <CustomButton
          label="Start Linking"
          variant="contained"
          handleClick={handleLinking}
        /> */}
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={linkToggle}
            exclusive
            onChange={(event) => handleChangeLink(event)}
            aria-label="Platform"
          >
            <ToggleButton value="linkQr">Link Qr Code</ToggleButton>
            <ToggleButton value="linkQrBarcode">Link Qr & Barcode</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <FormControl>
          <FormLabel>Select Scanning Option</FormLabel>
          <RadioGroup
            defaultValue="scanner"
            value={scanOption}
            name="radio-buttons-group"
            sx={{ display: "flex", flexDirection: "row" }}
            onChange={handleSelectScanOption}
          >
            <FormControlLabel
              value="scanner"
              control={<Radio />}
              label="Scanner Device"
            />
            <FormControlLabel
              value="camera"
              control={<Radio />}
              label="Camera"
            />
          </RadioGroup>
        </FormControl>
        {scanOption === "camera" && tabvalue === "Scan-Qr/Barcode" ? (
          <>
            {" "}
            <Html5QrcodePlugin1
              fps={10}
              qrbox={250}
              scanQrFlag={scanQrFlag}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResultQrCode}
            />
          </>
        ) : (
          ""
        )}
        <ResultContainerPlugin1
          results={decodedResults}
          setScanCount={setScanCount}
          scanType={scanOption}
        />
        {scanOption === "scanner" &&
        (tabvalue === "Scan-Qr/Barcode" || tabvalue === "Link-Qr/Barcode") ? (
          <Box>
            <TextField
              onChange={handleQrBarcodeIdFromScanner}
              label="QR/Barcode:"
              value={qrBarcodeId}
              size="small"
              sx={{
                height: "10px",
                width: "200px",
                marginBotton: "30px",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>

      {/* <Box>
        <CustomButton label="Re-link" variant="contained" />
      </Box> */}
      <Box>
        <QrBarcodeLinkingDialog
          setLinkingDialogOpen={setLinkingDialogOpen}
          linkingDialogOpen={linkingDialogOpen}
          scannedQrCode={scannedQrCode}
          scannedBarCode={scannedBarCode}
          setLink={setLink}
          scanCount={scanCount}
          setLinkQrBarcodeBtn={setLinkQrBarcodeBtn}
          inQrBarcodeAdmin={inQrBarcodeAdmin}
        />
      </Box>
    </Box>
  );
}

export default Linking;
