import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { HandleApiActions } from "../../Redux/Actions/actionHandler";
import { useTheme } from "@emotion/react";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import appFonts from "../../AppMeta/appFonts";

function UnAuthScanPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const data = {
    soldTo: ["Sold to", "rahul"],
    containerCapacity: ["Container Capacity", "20 L"],
    containerMaterial: ["Container Material", "PET"],
    tdsRange: ["TDS Range", "500"],
    phRange: ["PH Range", "6.8"],
    mfgDate: ["Manufacture Date", "5-12-24"],
    expData: ["Expiry Date", "15-12-24"],
    reuseCount: ["Reuse Count", 2],
  };

  const getProductDetails = () => {
    dispatch(
      HandleApiActions({
        // ...profileActions.get_profile_details,
        show_toast: false,
        params: { id },
      })
    );
  };

  return (
    <Box
      sx={{
        padding: 0,
        margin: 0,
        boxSizing: 0,
        background: "#0E87CC",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "2rem",
        alignItems: "center",
      }}
    >
      <Card sx={{ width: 275 }}>
        <CardContent
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            background: "#00274D",
            boxShadow: "none",
            width: "100%",
            height: "60px",
          }}
        >
          <Box sx={{}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1px",
              }}
            >
              <img
                alt=""
                src={bwLogo}
                style={{
                  cursor: "pointer",
                  height: "26px",
                  width: "24px",
                  marginTop: "-5px",
                }}
              />
              <Typography
                sx={{ color: "white", fontWeight: 800, fontSize: "18px" }}
              >
                OOK WATER
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ width: 275 }}>
        <CardContent
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "60px",
          }}
        >
          <Box sx={{}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // gap: "1px",
              }}
            >
              <Typography variant={appFonts.cardTitle}>
                Product Details
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box
        sx={{
          width: "calc(100vw - 3rem)",
          display: "flex",
          gap: "3rem",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!isMobile &&
          Object.values(data).map((ele) => {
            return (
              <Card sx={{ width: 275, flexShrink: 0 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {ele[0]}
                  </Typography>
                  :
                  <Typography
                    gutterBottom
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {ele[1]}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        {isMobile && (
          <Card sx={{ width: 275, flexShrink: 0 }}>
            <CardContent
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                padding: "2rem",
              }}
            >
              {Object.values(data).map((ele) => {
                console.log(ele);
                return (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      gutterBottom
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {ele[0]}
                    </Typography>
                    :
                    <Typography
                      gutterBottom
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {ele[1]}
                    </Typography>
                  </Box>
                );
              })}
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
}

export default UnAuthScanPage;
