import { FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react";

function CustomSwitch({ name, checked, onChange, label }) {
  console.log("--checked--", name, checked);
  const [isChecked, setISChecked] = useState(checked);
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isChecked}
            name={name}
            onChange={(event) => {
              setISChecked(event.target.checked);
              onChange(event.target.name, event.target.checked);
            }}
          />
        }
        label={isChecked ? label.on : label.off}
      />
    </>
  );
}

export default CustomSwitch;
