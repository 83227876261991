import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import RolesManagement from "./Roles/RolesManagement";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import ApiManagement from "./ApiList/ApiManagement";
import ProvideAccessManager from "./ProvideAccess/ProvideAccessManager";
import OrganizationDetails from "./Organization/OrganizationDetails";
import AddUsers from "./AddUsersRolesOrganization/AddUsers";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OrganizationDetailsList from "./Organization/OrganizationDetailsList";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import appStyle from "../../AppMeta/appStyle";
import appData from "../../AppMeta/appData";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import { Button, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function AccessManagementTabs1() {
  const [manager, setManager] = React.useState(10);
  const [value, setValue] = React.useState(0);
  const [selectorValueProp, setSelectorValueProp] = React.useState(0);
  const [drawOpenProp, setDrawOpenProp] = React.useState(false);

  const theme = useTheme();

  // Use the useMediaQuery hook to detect small screens (max-width: 600px)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const handleChangeManager = (event) => {
  //   setManager(event.target.value);
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubSelectorChange = (event) => {
    setSelectorValueProp(event.target.value);
  };

  const handleDrawerOpen = () => {
    setDrawOpenProp(true);
  };

  React.useEffect(() => {
    console.log(isSmallScreen);
  }, [isSmallScreen]);
  return (
    <Box>
      {/* <DisplayTitle>User Manager</DisplayTitle> */}

      {/* Display tabs here */}

      {/* <Box display="flex" justifyContent="center" margin="10px">
        <FormControl sx={{ width: "50%" }}>
          <InputLabel id="demo-simple-select-label">Manager</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={manager}
            label="Manager"
            onChange={handleChangeManager}
          >
            <MenuItem value={10}>Roles Management</MenuItem>
            <MenuItem value={20}>APIs Management</MenuItem>
            <MenuItem value={30}>Provide Access</MenuItem>
            <MenuItem value={40}>Facility</MenuItem>
            <MenuItem value={50}>Add users</MenuItem>
          </Select>
        </FormControl>
      </Box> */}

      {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel htmlFor="grouped-native-select">Select</InputLabel>
          <Select
            native
            defaultValue=""
            id="grouped-native-select"
            label="Grouping"
            onChange={handleChange}
          >
            <option aria-label="None" value="" />
            <optgroup label="Roles">
              <option value={[0, 0]}>Roles Management</option>
            </optgroup>
            <optgroup label="API">
              <option value={[1, 0]}>APIs Management</option>
            </optgroup>
            <optgroup label="Access">
              <option value={[2, 1]}>Provide Access</option>
              <option value={[2, 2]}>Provided Access</option>
            </optgroup>
            <optgroup label="Facility">
              <option value={[3, 0]}>Facility</option>
            </optgroup>
            <optgroup label="Users">
              <option value={[4, 0]}>Add users</option>
            </optgroup>
          </Select>
        </FormControl> */}
      {/* Main Select Menu */}
      {/* <Box>
          <CustomSelect
            name={"User Manager"}
            input={appData.userManagerMainSelect}
            handleChange={handleChange}
            defaultValue={value}
          />
        </Box> */}
      {/* <Box>
          {value === 2 && (
            <CustomSelect
              name={"access Manager"}
              input={appData.userManagerAccessProviderSubSelect}
              handleChange={handleSubSelectorChange}
              defaultValue={selectorValueProp}
            />
          )}
        </Box> */}
      {/* <Box>
          {value === 3 && (
            <Box sx={appStyle.general.button.addButtonBox}>
              <Button
                onClick={handleDrawerOpen}
                variant="contained"
                color="secondary"
                sx={appStyle.general.button.addButton}
                endIcon={<AddIcon />}
                size="small"
              >
                ADD NEW FACILITY
              </Button>
            </Box>
          )}
        </Box> */}

      <Box
        sx={{
          //fox for SKU pages in dashboard
          display: "flex",
          gap: "1rem",
        }}
      >
        <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          background="secondary"
        >
          <Tab
            sx={appStyle.general.tab.firstLevel}
            value={0}
            label="Roles Management"
          />
          {/* <Tab
            sx={appStyle.general.tab.firstLevel}
            value={1}
            label="APIs Management"
          /> */}
          {/* <Tab sx={appStyle.general.tab.firstLevel} label="Provide Access" /> */}
          <Tab
            sx={appStyle.general.tab.firstLevel}
            value={3}
            label="Facility"
          />
          <Tab sx={appStyle.general.tab.firstLevel} value={4} label="Users" />
        </Tabs>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box>
        {value === 0 && (
          <RolesManagement
            drawOpenProp={drawOpenProp}
            setDrawOpenProp={setDrawOpenProp}
          />
        )}
        {value === 1 && (
          <ApiManagement
            drawOpenProp={drawOpenProp}
            setDrawOpenProp={setDrawOpenProp}
          />
        )}
        {/* {value === 2 && <ProvideAccessManager tabProps={selectorValueProp} />} */}

        {value === 3 && (
          <OrganizationDetails
            drawOpenProp={drawOpenProp}
            setDrawOpenProp={setDrawOpenProp}
          />
        )}
        {value === 4 && (
          <AddUsers
            drawOpenProp={drawOpenProp}
            setDrawOpenProp={setDrawOpenProp}
          />
        )}
      </Box>

      {/* <Tabs
        value={value}
        onChange={handleChange}
        variant={isSmallScreen ? "scrollable" : "standard"}
        scrollButtons={isSmallScreen ? "auto" : "off"}
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Roles Management" />
        <Tab label="APIs Management" />
        <Tab label="Provide Access" />
        <Tab label="Organization" />
        <Tab label="Add users" />
      </Tabs>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <RolesManagement />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <ApiManagement />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <ProvideAccessManager />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <OrganizationDetails />
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        <AddUsers />
      </TabPanel> */}
    </Box>
  );
}
