import React from "react";
import { Box, Drawer, Typography } from "@mui/material";
import appFonts from "../../AppMeta/appFonts";

function CustomDrawer({ open, setOpen, children, title, width = 300 }) {
  return (
    <Drawer
      open={open}
      anchor="right"
      PaperProps={{
        sx: {
          width: width, // Set the width here
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          padding: "2rem",
        },
      }}
      handleClose={() => {
        setOpen(false);
      }}
    >
      <Box>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            padding: "10px",
          }}
          variant={appFonts.cardTitle}
        >
          {title}
        </Typography>
      </Box>
      {children}
    </Drawer>
  );
}

export default CustomDrawer;
