/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Dialog box to edit the user details.
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  selectClasses,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import appMeta from "../../../AppMeta/appMeta";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  EditUserRolesDomainDetails,
  GetUserRolesDomainDetails,
} from "../../../Redux/Actions/addUsersDomainRolesAction";
import { GetRoles } from "../../../Redux/Actions/rolesAction";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CustomForm from "../../../Components/CustomComp/CustomForm";

export default function EditUserDetailsDialog({
  openEditDialog,
  setOpenEditDialog,
  selectedRow,
  pageNumber,
  pageSize,
  ascOrDesc,
  isActiveGet,
  getUsers,
  setRoleSearch,
  rolesList,
}) {
  console.log("---selectedRow---", selectedRow);
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.rolesData);
  // Variables required declared here

  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [roleName, setRoleName] = React.useState("");
  const [isForceUpdate, setIsForceUpdate] = React.useState(false);
  const [isActive, setIsActive] = React.useState(selectedRow.is_active);
  const [isDeleted, setIsDeleted] = React.useState(false);
  // Validation variables
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [isValidPhone, setIsValidPhone] = React.useState(true);
  // To check which textfield user is trying to edit
  const [isEditEmail, setIsEditEmail] = React.useState(false);
  const [isEditName, setIdEditName] = React.useState(false);
  const [isEditPhone, setIsEditPhone] = React.useState(false);
  const [isEditRoleName, setIsEditRoleName] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);
  const [lastMore, setLastMore] = React.useState(null);

  // Hitting API to Get roles list
  // React.useEffect(() => {
  //   dispatch(GetRoles(getMoreRoles, 25, true, "ASC"));
  // }, [getMoreRoles]);

  // React.useEffect(() => {
  //   console.log("---selectedRow---", selectedRow);
  //   if (selectedRow) {
  //     setEmail(selectedRow.email);
  //     setPhone(selectedRow.phone);
  //     setName(selectedRow.fullname);
  //     setIsActive(selectedRow.is_active);
  //     setRoleName(selectedRow.role_details?.role_name);
  //     setIsDeleted(selectedRow.is_deleted);
  //   }
  // }, [selectedRow]);

  // After hitting roles API trying to get the response stored in redux store
  // React.useEffect(() => {
  //   console.log(rolesData);
  //   if (rolesData.error === false) {
  //     if (rolesData?.rolesList?.data?.length > 0) {
  //       console.log(rolesData.rolesList.data);
  //       // setRolesList(rolesData.rolesList.data);
  //       setLastMore(rolesData.rolesList.last_page_number);
  //       setRolesList((prevRolesList) =>
  //         prevRolesList
  //           ? [...prevRolesList, ...rolesData.rolesList.data]
  //           : rolesData.rolesList.data
  //       );
  //     }
  //   }
  // }, [rolesData]);

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setOpenEditDialog(false);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenEditDialog(false);
  };

  const handleClickEditEmail = () => {
    setIsEditEmail(!isEditEmail);
  };
  const handleClickEditName = () => {
    setIdEditName(!isEditName);
  };
  const handleClickEditPhone = () => {
    setIsEditPhone(!isEditPhone);
  };
  const handleClickEditRolename = () => {
    setIsEditRoleName(!isEditRoleName);
  };

  const searchAndUpdateOrganization = (searchText) => {
    setRoleSearch(searchText);
  };

  // This function will hit the Update User details API with request object
  const handleUpdate = (formData) => {
    const reqObj = {
      email: formData.email,
      is_force_update: isForceUpdate,
      is_active: formData.is_active,
      name: formData.name,
      delete: formData.is_deleted,
      phone: formData.phone,
      role_name: formData.roleName,
      is_force_update: formData.isConfirmed,
    };
    console.log("---Edit user--", formData, reqObj);

    dispatch(EditUserRolesDomainDetails(reqObj));
    setTimeout(() => {
      // dispatch(
      //   GetUserRolesDomainDetails(pageNumber, pageSize, ascOrDesc, isActiveGet)
      // );
      getUsers();
    }, 1000);
    setOpenEditDialog(false);
  };

  // Jsx Starts here...
  return (
    <div>
      <Drawer
        open={openEditDialog}
        // onClose={handleClose}
        PaperProps={{
          sx: {
            width: 250, // Set the width here

            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
          },
        }}
        anchor="right"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Edit Details"}</DialogTitle> */}
        {/* <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            padding: "20px",
          }}
        > */}
        <Typography
          variant="h4"
          textAlign="center"
          m="10px"
          sx={{ fontWeight: 700 }}
        >
          Edit User
        </Typography>
        <Divider sx={{ border: "2px solid #e3e9f3" }} />
        <CustomForm
          configConfirmation={{
            isConfirmationRequired: true,
            dialogContent: "Are you sure you want to update changes?",
          }}
          handleClose={handleClose}
          handleSubmit={handleUpdate}
          data={[
            {
              numberOfColumn: 1,
              data: [
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Email",
                  defaultValue: selectedRow.email,
                  name: "email",
                  textFieldType: appMeta.textFieldTypes.EMAIL,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Name",
                  defaultValue: selectedRow.fullname,
                  name: "name",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Phone",
                  defaultValue: selectedRow.phone,
                  name: "phone",
                  textFieldType: appMeta.textFieldTypes.PHONE,
                  isHintRequired: true,
                  isRequired: true,
                },

                {
                  inputType: appMeta.inputFieldTypes.SWITCH,
                  placeHolder: { on: "Active", off: "Active" },
                  name: "is_active",
                  defaultValue: selectedRow.is_active,
                },
              ],
            },
          ]}
        />
        {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Email</InputLabel>
            <Input
              id="standard-adornment-password"
              value={email}
              // disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditEmail}
                    disabled={true}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditEmail ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditEmail && (
            <CustomInputTextField
              value={email}
              setValue={setEmail}
              placeholder="Email"
              type={appMeta.textFieldTypes.EMAIL}
              setIsValid={setIsValidEmail}
              isValid={isValidEmail}
              isHintRequired={true}
            />
          )} */}
        {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Name</InputLabel>
            <Input
              id="standard-adornment-password"
              value={name}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditName}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditName ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditName && (
            <CustomInputTextField
              value={name}
              setValue={setName}
              placeholder="Name"
              type="text"
            />
          )} */}
        {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Phone</InputLabel>
            <Input
              id="standard-adornment-password"
              value={phone}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditPhone}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditPhone ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          {isEditPhone && (
            <CustomInputTextField
              value={phone}
              setValue={setPhone}
              placeholder="Phone"
              type={appMeta.textFieldTypes.PHONE}
              isValid={isValidPhone}
              setIsValid={setIsValidPhone}
              isHintRequired={true}
            />
          )} */}
        {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Role name
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={selectedRow?.role_details?.role_name}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditRolename}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditRoleName ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditRoleName && (
            <Box
              sx={{
                minWidth: 120,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                onClick={handleOpenDropdown}
                variant="outlined"
                color="secondary"
              >
                Select Role Name <ExpandMoreRoundedIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseDropdown}
              >
                {rolesList?.map((list) => (
                  <MenuItem
                    key={list.role_name}
                    onChange={() => {
               
                      setRoleName(list.role_name);
                    }}
                  >
                    {list.role_name}
                  </MenuItem>
                ))}
            
                {hasMoreData && (
                  <MenuItem
                    onClick={handleLoadMore}
                    sx={{ fontWeight: "bold" }}
                    disabled={getMoreRoles < lastMore ? false : true}
                  >
                    More <ExpandMoreRoundedIcon />
                  </MenuItem>
                )}
              </Menu>

              {roleName && (
                <Box>
                  <Chip
                    label={`Selected Role Name: ${roleName}`}
                    variant="filled"
                    color="success"
                    sx={{ fontWeight: "bold" }}
                  />
                </Box>
              )}
            </Box>
          )} */}

        {/* {isEditRoleName && (
              <CustomInputTextField
                value={roleName}
                setValue={setRoleName}
                placeholder="Role name"
                type="text"
              />
            )} */}

        {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={!!isForceUpdate}
                  onChange={(event) => setIsForceUpdate(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isForceUpdate
                  ? "You are force updating"
                  : "Do you want to force update"
              }
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={!!isActive}
                  onChange={(event) => {
                    setIsActive(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isActive
                  ? "Active now, Uncheck to deactivate."
                  : "Deactivated, Check to Activate"
              }
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={!!isDeleted}
                  onChange={(event) => {
                    setIsDeleted(event.target.checked);
                    // setIsForceUpdate(event.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isDeleted
                  ? "You are forcing to delete this user"
                  : "Check to delete this user"
              }
            />
          </Box> */}
        {/* </DialogContent> */}
        {/* <DialogActions>
          <CustomButton
            handleClick={handleClose}
            label="Cancel"
            variant="text"
            color="error"
          />
          <CustomButton
            handleClick={handleUpdate}
            label="Update"
            variant="contained"
          />
        </DialogActions> */}
        {/* Handling closing and update buttons */}
      </Drawer>
    </div>
  );
}
