import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import appMeta from "../../AppMeta/appMeta";
import Html5QrcodePlugin1 from "./Html5QrcodePlugin";
import GeneratedQRBarcodeList from "./GereratedQRBarcodeList";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import CustomForm from "../../Components/CustomComp/CustomForm";
import { getInputForSelectFromArray } from "../../Components/Utils/CustomFormUtils";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/CustomComp/CustomButton";
import { GenerateQR, RegenerateQr } from "../../Redux/Actions/GenerateQrAction";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

function GenerateComp() {
  const [generate, setGenerate] = React.useState("GenerateQrCode");
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productData);
  const [capacity, setCapacity] = React.useState("");
  const [material, setMaterial] = React.useState({});
  const [productName, setProductName] = React.useState({});
  const [quantity, setQuantity] = React.useState("");
  const [batchId, setBatchId] = React.useState("");
  const [manufacturerName, setManufacturerName] = React.useState("");
  const [manufacturerAddress, setManufacturerAddress] = React.useState("");

  const [openToLink, setOpenToLink] = React.useState(false);

  const [value, setValue] = React.useState("one");

  React.useEffect(() => {
    dispatch(GetFinalProductSKU());
  }, []);
  const [isQrSwitch, setIsQrSwitch] = React.useState(true);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const handleChangeGenerateQRBarcode = (event) => {
  //   setGenerate(event.target.value);
  // };

  const handleChangeCapacity = (event) => {
    setCapacity(event.target.value);
  };

  const handleChangeMaterial = (event) => {
    console.log(event);
    setMaterial(event.target.value);
    const material = event.target.value;
    let duplicateElement = productDetails?.finalProduct?.find(
      (data) => data.product_details.id === material
    );

    if (Object.keys(duplicateElement).length > 0) {
      console.log("duplicateElement", {
        capacity: duplicateElement.product_details.capacity,
        material: duplicateElement.product_details.material,
      });
      setProductName({
        capacity: duplicateElement.product_details.capacity,
        material: duplicateElement.product_details.material,
      });
    }
  };

  const onNewScanResult = (decodedText, decodedResult) => {
    console.log("App [result]", decodedResult);
    console.log(decodedText);
    // if (decodedResult.result.format.formatName === "QR_CODE") {
    //   setScannedQrCode(decodedText);
    //   console.log(scanCount);
    // }
    // if (decodedResult.result.format.formatName === "CODE_128") {
    //   setScannedBarCode(decodedText);
    //   console.log(scanCount);
    // }
    // setDecodedResults((prev) => [...prev, decodedResult]);
  };

  const handleGenerateQrbarcode = () => {
    console.log(productName);
    const reqObj = {
      batch_id: batchId,
      container_capacity: productName.capacity,
      container_material: productName.material,
      mft_address: manufacturerAddress,
      mft_name: manufacturerName,
      quantity: parseInt(quantity),
      // is_url: isQrSwitch,
    };
    if (generate === "GenerateQrCode" || generate === "GenerateBarCode") {
      console.log("log", reqObj);
      dispatch(GenerateQR(reqObj));
      setTimeout(() => {
        setMaterial("");
        setManufacturerAddress("");
        setManufacturerName("");
        setBatchId("");
        setQuantity("");
      }, 1000);
    } else if (
      generate === "Re-GenerateQrCode" ||
      generate === "Re-GenerateBarCode"
    ) {
      console.log("Re-GenerateBarCode");
      dispatch(RegenerateQr(reqObj));
      setTimeout(() => {
        setMaterial("");
        setManufacturerAddress("");
        setManufacturerName("");
        setBatchId("");
        setQuantity("");
      }, 1000);
    }
  };
  useEffect(() => {
    console.log(generate);
  }, [generate]);

  const handleIsUrlSwitch = (event) => {
    setIsQrSwitch(event.target.checked);
  };

  return (
    <Box
      component={Paper}
      elevation={18}
      sx={{ padding: "10px", borderRadius: "15px" }}
    >
      {" "}
      <>
        {" "}
        <Box
          sx={{
            minWidth: 220,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: "10px",
          }}
        >
          <CustomSelect
            name="Select option"
            input={[
              { value: "GenerateQrCode", menuItem: "Generate QR Code" },
              { value: "GenerateBarCode", menuItem: "Generate Bar Code" },
              {
                value: "Re-GenerateQrCode",
                menuItem: "Re-generate QR Code",
              },
              {
                value: "Re-GenerateBarCode",
                menuItem: " Re-generate Bar Code",
              },
            ]}
            handleChange={(e) => {
              setGenerate(e.target.value);
            }}
            defaultValue={generate}
            variant="standard"
            sx={{ width: "11rem" }}
          />
          {/* </FormControl> */}
        </Box>
        <Divider />
        <Box sx={{ mb: "30px", mt: "10px" }}>
          <Typography>Is URL :</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              checked={isQrSwitch}
              onChange={handleIsUrlSwitch}
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography
              sx={{
                color: isQrSwitch === true ? "#30A2F3" : "black",
              }}
            >
              {isQrSwitch === true ? "Enable" : "Disable"}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            //   flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                minWidth: 220,
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Product</InputLabel>
                <Select
                  variant="standard"
                  value={material}
                  label="Product"
                  onChange={handleChangeMaterial}
                >
                  {productDetails?.finalProduct.map((data) => {
                    return (
                      <MenuItem value={data.product_details.id}>
                        {data.product_details?.capacity}/
                        {data.product_details?.material}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {/* <CustomForm
              // handleSubmit={handleCreateBatchClick}
              // handleClose={handleClose}
              hideCloseButton={true}
              data={[
                {
                  numberOfColumn: 3,
                  data: [
                    {
                      inputType: appMeta.inputFieldTypes.SELECT,
                      placeHolder: "Select Product",
                      name: "product",
                      input: getInputForSelectFromArray(
                        productDetails?.finalProduct,
                        "product_details.name",
                        "product_details.id"
                      ),
                    },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Enter Quantity",
                      defaultValue: "",
                      name: "quantity",
                      textFieldType: appMeta.textFieldTypes.NUMBER,
                      isHintRequired: true,
                      isRequired: true,
                    },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Enter Batch Id",
                      defaultValue: "",
                      name: "quantity",
                      textFieldType: appMeta.textFieldTypes.NUMBER,
                      isHintRequired: true,
                      isRequired: true,
                    },
                  ],
                },
                {
                  numberOfColumn: 2,
                  title: "",
                  data: [
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Enter Manufacturer Name",
                      defaultValue: "",
                      name: "quantity",
                      textFieldType: appMeta.textFieldTypes.NUMBER,
                      isHintRequired: true,
                      isRequired: true,
                    },
                    {
                      inputType: appMeta.inputFieldTypes.INPUT,
                      placeHolder: "Enter Manufacturer Address",
                      defaultValue: "",
                      name: "quantity",
                      textFieldType: appMeta.textFieldTypes.NUMBER,
                      isHintRequired: true,
                      isRequired: true,
                    },
                  ],
                },
              ]}
            /> */}
            </Box>

            <CustomInputTextField
              placeholder="Enter the quantity"
              value={quantity}
              setValue={setQuantity}
              type={appMeta.textFieldTypes.NUMBER}
              isHintRequired={true}
            />

            <CustomInputTextField
              placeholder="Enter the batch Id"
              value={batchId}
              setValue={setBatchId}
              type={appMeta.textFieldTypes.NUMBER}
              isHintRequired={true}
            />

            <CustomInputTextField
              placeholder="Manufacturer name"
              value={manufacturerName}
              setValue={setManufacturerName}
              type={appMeta.textFieldTypes.TEXT}
              // isHintRequired={true}
            />

            <CustomInputTextField
              placeholder="Manufacturer Address"
              value={manufacturerAddress}
              setValue={setManufacturerAddress}
              type={appMeta.textFieldTypes.TEXT}
              // isHintRequired={true}
            />
          </Box>
          {/* ) : generate === "GenerateBarCode" ||
            generate === "Re-GenerateQrCode" ||
            generate === "Re-GenerateBarCode" ? (
            <>
              <CustomInputTextField
                placeholder="Enter the quantity"
                value={quantity}
                setValue={setQuantity}
                type={appMeta.textFieldTypes.NUMBER}
                isHintRequired={true}
              />
            </>
          ) : (
            ""
          )} */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <CustomButton
            label="generate"
            handleClick={handleGenerateQrbarcode}
          />
        </Box>
        {/* <Box>
          <Button
            variant="contained"
            onClick={() => setOpenToLink(!openToLink)}
          >
            Link QR Barcodes
          </Button>
          <Dialog open={openToLink}>
            {openToLink && (
              <Html5QrcodePlugin1
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
              />
            )}
            <Button
              variant="outlined"
              onClick={() => setOpenToLink(!openToLink)}
            >
              Close
            </Button>
          </Dialog>
        </Box> */}
        {/* <Box sx={{ padding: "20px" }}>
          <GeneratedQRBarcodeList />
        </Box> */}
      </>
    </Box>
  );
}

export default GenerateComp;
