import {
  Box,
  Button,
  Divider,
  Drawer,
  Icon,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
  Stack,
  DialogContentText,
} from "@mui/material";
import React, { useContext } from "react";
import { sideBarData } from "./sideBarData";
import { useDispatch, useSelector } from "react-redux";
import actionTypes from "../../Redux/actionTypes";
import { toast } from "react-toastify";
import { Link, useHistory, Redirect } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTheme } from "@emotion/react";
import { ColorModeContext, tokens } from "../../AppMeta/theme";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import { GetProfile } from "../../Redux/Actions/profile";
import appStyle from "../../AppMeta/appStyle";
import SunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import ApiDocumentsMain from "../ApiDocuments/ApiDocumentsMain";
import { Logout } from "@mui/icons-material";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { buttons } from "../../AppMeta/appColors";

const Item = ({ title, to, icon, selected, setSelected, toggleDrawer }) => {
  const theme = useTheme();
  const history = useHistory();
  const colors = tokens(theme.palette.mode);
  const handleNavigator = () => {
    setSelected(title);
    history.push(to);
    toggleDrawer(false);
  };
  return (
    <ListItem
      disablePadding
      sx={{
        backgroundColor:
          selected === title &&
          appStyle.general.colors(colors).sideMenuBarActive,
      }}
      // onClick={() => {
      //   setSelected(title);
      // }}
    >
      <ListItemButton onClick={handleNavigator}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

const settings = [
  { title: "Profile", path: "/profile" },
  { title: "Account", path: "/account" },
  { title: "Dashboard", path: "/dashboard" },
  { title: "Logout", path: "/authentication" },
];

const topBarTitleName = {
  home: "Home",
  products: "Stock Keeping Unit",
  usermanagement: "User Management",
  waterstation: "Water Station",
  facilityordermanagement: "Facility Order Management",
  organisationordermanagement: "Organisation Order Management",
  organization_orders_List: "All Orders",
  bookwaterordermanagement: "Bookwater Order Management",
  codesManagement: "QR/Barcode Management",
  // organization: "Rewards and Offer",
  settings: "Settings",
  recycleOrders: "Recycle Orders",
  api_documentation: "API Documentation",
  profile: "Profile",
};

const pathnameToTitleModifier = (text = "") => {
  let word = "";
  if (text === "") {
    return;
  }
  for (let i = 0; i < text.length; i++) {
    if (text[i] !== "-") {
      word = word + text[i];
    }
    if (text[i] === "/") {
      word = "";
    }
  }
  return word;
};

function Topbar({ isVisible, setIsVisible }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData);
  const location = useLocation();

  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [selected, setSelected] = React.useState("DashboardMain");
  const [dashboard, setDashboard] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  // const isFacility = false;
  const [isLogout, setIsLogout] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [domain, setDomain] = React.useState("");
  const [isFacility, setIsFacility] = React.useState(false);
  const [openLogOutConfirmDialog, setOpenLogOutConfirmDialog] =
    React.useState(false);
  const [orgFacilityName, setOrgFacilityName] = React.useState("");
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  React.useEffect(() => {
    dispatch(GetProfile());
  }, []);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  React.useEffect(() => {
    console.log("profileData");
    console.log(profileData);
    setIsFacility(profileData?.profile?.is_facility);
    setDomain(profileData?.profile?.domain);
    setOrgFacilityName(profileData?.profile?.org_name);
  }, [profileData]);

  // React.useEffect(() => {
  //   console.log("isFacility", isFacility);
  //   console.log("domain", domain);
  //   console.log("orgFacilityName", orgFacilityName);
  //   if (domain === "BOOKWATER.COM") {
  //     setDashboard(sideBarData.bookWaterDashboard);
  //   } else if (isFacility === true) {
  //     setDashboard(sideBarData.facilityDashBoard);
  //   } else {
  //     setDashboard(sideBarData.organisationDashboard);
  //   }
  // }, [isFacility, domain, orgFacilityName]);
  React.useEffect(() => {
    // console.log("isFacility", isFacility);
    // console.log("domain", domain);
    // console.log("orgFacilityName", orgFacilityName);
    // if (domain === "BOOKWATER.COM") {
    // } else if (isFacility === true) {
    //   setDashboard(sideBarData.facilityDashBoard);
    // } else {
    //   setDashboard(sideBarData.organisationDashboard);
    // }

    setDashboard(sideBarData.bookWaterDashboard);
  }, [isFacility, domain, orgFacilityName]);

  const handleProfile = (title) => {
    if (title === "Logout") {
      setIsLogout(true);
      dispatch({
        type: actionTypes.SIGN_OUT,
      });
    } else {
      toast.error("Implementation in progress", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleLogOut = () => {
    setOpenLogOutConfirmDialog(true);
  };
  const handleLogOutConfirm = () => {
    setIsLogout(true);
    dispatch({
      type: actionTypes.SIGN_OUT,
    });
    // dispatch({
    //   type: actionType.RESET_PROFILE,
    // });
    // dispatch({
    //   type: actionType.RESET_LAB_CAR_DATA,
    // });
    // dispatch({
    //   type: actionType.RESET_PROJECT_CONFIG,
    // });
  };
  if (isLogout) {
    return <Redirect to="authentication" />;
  }

  return (
    <>
      {/* <Box
        sx={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}
      > */}
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <AppBar
        position="sticky"
        sx={{
          background: "linear-gradient(#02173a, #0b48a7)",
          // theme.palette.mode === "dark"
          //   ? "linear-gradient(#1f78ff, #0b48a7)"
          //   : "linear-gradient(#02173a, #0b48a7)",
          boxShadow: "none",

          width: "100%",
          padding: "10px",
          height: "60px",
          m: 0,
        }}
      >
        {/* <Toolbar> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between  ",
            width: "100%",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <MenuOutlinedIcon onClick={() => setIsVisible(!isVisible)} />
            </Box>
          ) : (
            <Box sx={{ ml: "40px", mt: "5px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  gap: "1px",
                }}
              >
                <img
                  alt=""
                  src={bwLogo}
                  style={{
                    cursor: "pointer",
                    height: "26px",
                    width: "24px",
                    marginTop: "-5px",
                  }}
                />
                <Typography
                  sx={{ color: "white", fontWeight: 800, fontSize: "18px" }}
                >
                  OOK WATER
                </Typography>
              </Box>
            </Box>
          )}
          <Box>
            <Typography
              // variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: "white",
                fontWeight: 800,
                fontSize: "16px",
              }}
            >
              {/* <DisplayTitle> */}
              {topBarTitleName[
                pathnameToTitleModifier(location.pathname)
              ]?.toUpperCase()}
              {/* </DisplayTitle> */}
            </Typography>
          </Box>
          <Box sx={{ right: 0, display: "flex" }}>
            {!isMobile && (
              <IconButton
                sx={{ width: "24px", mr: 2, color: "white" }}
                onClick={colorMode.toggleColorMode}
              >
                {theme.palette.mode === "dark" ? <DarkIcon /> : <SunnyIcon />}
              </IconButton>
            )}
            {/* <IconButton>
              <NotificationsIcon />
            </IconButton>*/}

            <Box>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ width: "24px", mr: 2, color: "white" }}
              >
                <SettingsIcon />
              </IconButton>
              {/* {isMobile && (
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  
                </Menu>
              )} */}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {
                  <>
                    {isMobile && (
                      <MenuItem onClick={colorMode.toggleColorMode}>
                        <IconButton color="inherit">
                          <Typography>Mode</Typography>
                          {theme.palette.mode === "dark" ? (
                            <DarkIcon sx={{ ml: 1 }} />
                          ) : (
                            <SunnyIcon sx={{ ml: 1 }} />
                          )}
                        </IconButton>
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleLogOut}>
                      <IconButton color="inherit" aria-label="logout">
                        <Typography>Logout</Typography>
                        <Logout sx={{ ml: 1 }} />
                      </IconButton>
                    </MenuItem>
                  </>
                }
              </Menu>
            </Box>
          </Box>
        </Box>

        <CustomConfirmationDialog
          open={openLogOutConfirmDialog}
          onClose={() => setOpenLogOutConfirmDialog(false)}
          dialogTitle={"Confirm Logout"}
          dialogContent={
            <DialogContentText>
              Are you sure you want to logout?
            </DialogContentText>
          }
          onConfirm={handleLogOutConfirm}
        />
        {/* </Toolbar> */}
      </AppBar>
      {/* </Box> */}

      {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {!isMobile && <Box></Box>}
          
        </Box> */}
      {/* </Box> */}

      <Divider />
    </>
  );
}

export default Topbar;
