import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Box } from "@mui/material";
import CustomSelectForTable from "./CustomSelectForTable";
import CustomButton from "./CustomButton";

function CustomFilter({
  name,
  startIcon,
  selectOption = [],
  handleFilterClose,
  type,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectValue, setSelectValue] = React.useState(0);
  const [filterSearchValue, setFilterSearchValue] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("ASC");

  const [filterSelectValue, setFilterSelectValue] = React.useState("");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };
  const handleClose = () => {
    let filterData = {};

    filterData = {
      value: selectOption[selectValue].value,
      searchText: type === "filter" ? filterSearchValue : sortOrder,
    };

    handleFilterClose !== undefined && handleFilterClose(filterData);
    setAnchorEl(null);
  };

  const handleReset = () => {
    setFilterSearchValue("");
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    setFilterSearchValue("");
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setFilterSearchValue("");
  };

  return (
    <Box>
      <CustomButton
        handleClick={handleClick}
        variant="contained"
        startIcon={startIcon}
        label={name}
        // sx={appStyle.general.button.blueButton}
      />
      {/* {name}
      </Button> */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        // MenuListProps={{
        //   "aria-labelledby": "basic-button",
        // }}
      >
        <Box>
          <Box sx={{ m: 1 }}>
            <InputLabel id="demo-customized-select-label">
              Select Option
            </InputLabel>
            <Select
              variant="standard"
              sx={{ width: "200px" }}
              value={selectValue}
              onChange={handleSelectChange}
            >
              {selectOption.map((e, i) => {
                return (
                  <MenuItem value={i} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          {/* Code for filter */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {type === "filter" &&
              (selectOption[selectValue]?.type === "select" ? (
                <CustomSelectForTable
                  name={selectOption[selectValue].label}
                  input={selectOption[selectValue].input}
                  defaultValue={filterSelectValue}
                  handleChange={(e) => {
                    setFilterSelectValue(e.target.value);
                    setFilterSearchValue(
                      selectOption[selectValue]?.input[e.target.value]?.value
                    );
                  }}
                  sx={{ width: "200px" }}
                />
              ) : (
                <TextField
                  id="filterSearch"
                  onKeyDown={(e) => e.stopPropagation()}
                  label={"Search" + " " + selectOption[selectValue]?.value}
                  variant="standard"
                  type="text"
                  value={filterSearchValue}
                  onChange={(e) => {
                    e.preventDefault();
                    setFilterSearchValue(e.target.value);
                  }}
                  sx={{ width: "200px" }}
                />
              ))}
          </Box>

          {/* code for sort */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {type === "sort" && (
              <ToggleButtonGroup
                value={sortOrder}
                exclusive
                onChange={handleSortChange}
                aria-label="text alignment"
              >
                <ToggleButton value="ASC" aria-label="left aligned">
                  ASC
                </ToggleButton>
                <ToggleButton value="DESC" aria-label="left aligned">
                  DESC
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CustomButton handleClick={handleReset} label={"Reset"} />

            <CustomButton
              handleClick={handleClose}
              label={
                type === "filter"
                  ? "Filter"
                  : type === "sort"
                  ? "sort"
                  : "Filter"
              }
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

export default CustomFilter;
