import { Dialog, DialogContent } from "@mui/material";
import React from "react";

function CustomDialog({ children, open, handleClose, handleSubmit }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
