/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Janarthanan
 * @description Role Manager main Page
 * @date 2/11/24
 * @version 0.0.1 - Initial Release
 */
import { Box, Button, Typography } from "@mui/material";
import React, { Component, useState } from "react";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import CustomFilter from "../../../Components/CustomComp/CustomFilter";
import CustomAddButton from "../../../Components/CustomComp/CustomAddButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddRole from "./AddRole";
import appStyle from "../../../AppMeta/appStyle";
import EditRole from "./EditRole";
import moment from "moment/moment";

function RoleManager({
  listOfRoles,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  isActive,
  setIsActive,
  ascOrDesc,
  setAscOrDesc,
  sortColumn,
  setSortColumn,
  setFilterColumn,
  setFilterSearchText,
  handleGetRolesAndAccessList,
}) {
  console.log("first");
  console.log("Role List", listOfRoles);

  //Table row set details
  const tableHead = [
    "ID",
    "Role",
    "Department",
    "Sub-Department",
    "Description",
    "Created At",
    "Updated At",
  ];
  const tableDataKey = [
    "id",
    // "role_name",
    {
      rowClickEnable: true,
      component: (props) => {
        console.log("propss", props);
        return (
          <Typography
            variant="h6"
            sx={{ textDecoration: "underline", textDecorationColor: "blue" }}
          >
            {" "}
            {props?.row?.role_name}
          </Typography>
        );
      },
    },
    "department",
    "sub_department",
    "description",
    {
      rowClickEnable: true,
      component: (props) => {
        console.log("propss", props);
        return moment(props?.row?.created_at).format("DD-MM-YYYY");
      },
    },
    {
      rowClickEnable: true,
      component: (props) => {
        console.log("propss", props);
        return moment(props?.row?.updated_at).format("DD-MM-YYYY");
      },
    },
  ];

  const [addRolePage, setAddRolePage] = useState(false);

  const [openEditRolesDialog, setOpenEditRolesDialog] = useState(false);
  console.log("openEditRolesDialog", openEditRolesDialog);
  const [selectedRow, setSelectedRow] = useState(null);

  ///Select option array
  const filterSelectOption = [
    {
      value: "role_name",
      label: "Role",
    },
    {
      value: "department",
      label: "Department",
    },
    {
      type: "select",
      value: "is_active",
      label: "Role Status",
      input: [
        { value: true, menuItem: "Active" },
        { value: false, menuItem: "In active" },
      ],
    },
  ];

  const sortSelectOption = [
    { value: "role_name", label: "Role" },
    { value: "department", label: "Department" },
    { value: "created_at", label: "Created At" },
    { value: "updated_at", label: "Updated At" },
  ];

  //Table top button functions
  const handleFilterClose = (data) => {
    // console.log("--------FilterData--------", data);
    setFilterColumn(data.value);
    setFilterSearchText(data.searchText);
  };
  const handleSortClose = (data) => {
    setSortColumn(data.value);
    setAscOrDesc(data.searchText);
  };
  const handleOnClickAddButton = () => {
    setAddRolePage(!addRolePage);
  };

  const handleTableRowClick = (index) => {
    // console.log("first", listOfRoles[index]);
    setOpenEditRolesDialog(true);
    setSelectedRow(listOfRoles[index]);
    // setOpenEditRolesDialog(true);
  };

  ///Table title list
  const filter = (
    <CustomFilter
      name="filter"
      type="filter"
      startIcon={<FilterAltIcon />}
      selectOption={filterSelectOption}
      handleFilterClose={handleSortClose}
    />
  );
  const sortBy = (
    <CustomFilter
      name="Sort By"
      type="sort"
      startIcon={<FilterAltIcon />}
      selectOption={sortSelectOption}
      handleFilterClose={handleFilterClose}
    />
  );

  const addButton = (
    <CustomAddButton handleOnClickButton={handleOnClickAddButton} />
  );

  const tableTitleButton = [filter, sortBy, addButton];
  return (
    <Box>
      {!addRolePage ? (
        <CustomTable
          tableHead={tableHead}
          tableData={listOfRoles}
          tableDataKey={tableDataKey}
          tableTitleButton={tableTitleButton}
          tableRowClickFunction={handleTableRowClick}
          filter={true}
          filterSelectOption={filterSelectOption}
          handleFilterClose={handleFilterClose}
          sort={true}
          sortSelectOption={sortSelectOption}
          handleSortClose={handleSortClose}
          addButton={true}
          handleOnClickAddButton={handleOnClickAddButton}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
        />
      ) : (
        <Box>
          <AddRole handleOnClickAddButton={handleOnClickAddButton} />
        </Box>
      )}

      {/* ////Dialoug to edit selected row in the table */}
      {openEditRolesDialog && (
        <Box sx={{ width: "425px" }}>
          <EditRole
            open={openEditRolesDialog}
            onClose={() => {
              setOpenEditRolesDialog(false);
            }}
            selectedRow={selectedRow}
            setOpenEditRolesDialog={setOpenEditRolesDialog}
            pageNumber={pageNumber}
            pageSize={pageSize}
            isActive={isActive}
            ascOrDesc={ascOrDesc}
            handleGetRolesAndAccessList={handleGetRolesAndAccessList}
            // setIsUpdate={setIsUpdate}
            // setOpenConfirmationDialog={setOpenConfirmationDialog}
            // isConfirmed={isConfirmed}
            // isUpdate={isUpdate}
            // setConfirmationDescription={setConfirmationDescription}
            // setRoleUpdateBtn={setRoleUpdateBtn}
            // setIsConfirmed={setIsConfirmed}
          />
        </Box>
      )}
    </Box>
  );
}

export default RoleManager;
