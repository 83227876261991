import React, { useEffect, useState } from "react";
import {
  AppBar,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useTheme,
  useMediaQuery,
  Icon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import AddIcon from "@mui/icons-material/Add";
import { appColors } from "../../AppMeta/appColors";
import { useDispatch } from "react-redux";
import {
  AddComponentSku,
  AddRawMaterials,
  GetComponents,
  GetProductSku,
  GetRawMaterials,
} from "../../Redux/Actions/ProductComponentsAction";
import RawMaterialsTable from "./RawMaterialsTable";
import FinishedGoodsTable from "./FinishedGoodsTable";
import AllProductsTable from "../Orders/PurchaseProduct/AllProductsTable";
import {
  AddFinalProductSKU,
  AddProductsAction,
  GetFinalProductSKU,
} from "../../Redux/Actions/productsAction";
import FinalProductsTable from "./FinalProductsTable";
import SKUProducts from "./SKUProducts";
import AddSKUProduct from "./AddSKUProduct";
import Crates from "./Crates";
import AddCrates from "./AddCrates";
import PincodesAndAres from "./PincodesAndAreas";
import AddUpdatePicodeAreaSku from "./AddUpdatePicodeSku";
import AddFinalSkuProduct from "./AddFinalSkuProduct";
import { GetCratesSKU } from "../../Redux/Actions/addCratesAction";
import { act } from "react-dom/test-utils";
import { GetPincodeAreas } from "../../Redux/Actions/pincodeAreasAction";
import { tokens } from "../../AppMeta/theme";
import appStyle from "../../AppMeta/appStyle";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
import CustomTable from "../../Components/CustomComp/CustomTable";
import appConstants from "../../AppMeta/appConstants";
import { tabValues } from "../../AppMeta/appData";
import moment from "moment/moment";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";

const rawMaterialsData = [
  {
    id: 1,
    name: "Plastic for Can Manufacturing",
    quantity: 100,
    unit: "Kilograms",
    description: "Material for making cans",
  },
  {
    id: 2,
    name: "Metal for Cap Manufacturing",
    quantity: 50,
    unit: "Kilograms",
    description: "Material for making caps",
  },
  {
    id: 3,
    name: "Sticker Material",
    quantity: 200,
    unit: "Sheets",
    description: "Material for printing stickers",
  },
  {
    id: 4,
    name: "Ink for Printing",
    quantity: 5,
    unit: "Liters",
    description: "Ink for printing labels",
  },
  {
    id: 5,
    name: "Cardboard for Packaging",
    quantity: 150,
    unit: "Sheets",
    description: "Material for packaging",
  },
];

const finishedGoodsData = [
  {
    id: 1,
    name: "Can",
    materials: ["Plastic for Can Manufacturing"],
    description: "Finished can product",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
  {
    id: 2,
    name: "Cap",
    materials: ["Metal for Cap Manufacturing"],
    description: "Finished cap product",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
  {
    id: 3,
    name: "Sticker",
    materials: ["Sticker Material"],
    description: "Roll of printed stickers",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
  {
    id: 4,
    name: "Ink Bottle",
    materials: ["Ink for Printing"],
    description: "Bottle of printing ink",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
  {
    id: 5,
    name: "Cardboard Box",
    materials: ["Cardboard for Packaging"],
    description: "Packaging cardboard box",
    cgst: 5,
    sgst: 5,
    quantity: 0, // Add stock quantity
  },
];

const finalProductsData = [
  {
    id: 1,
    name: "20 Liter Bubble Top Can",
    type: "Can",
    materials: ["Can"],
    description: "Final product description",
    quantity: 30, // Add stock quantity
  },
  {
    id: 2,
    name: "Branded Cap Set",
    type: "Cap",
    materials: ["Cap", "Sticker Roll"],
    description: "Set of branded caps with stickers",
    quantity: 15, // Add stock quantity
  },
  {
    id: 3,
    name: "Stickered Can Pack",
    type: "Can",
    materials: ["Can", "Sticker Roll"],
    description: "Pack of cans with printed stickers",
    quantity: 25, // Add stock quantity
  },
  {
    id: 4,
    name: "Printing Kit",
    type: "Sticker",
    materials: ["Sticker Roll", "Ink Bottle"],
    description: "Kit for printing stickers",
    quantity: 18, // Add stock quantity
  },
  {
    id: 5,
    name: "Packaging Bundle",
    type: "Cardboard Box",
    materials: ["Cardboard Box"],
    description: "Bundle of packaging boxes",
    quantity: 35, // Add stock quantity
  },
];

// ... (previous code)

const SKUManagementApp = ({
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  lastPageNumber,
  createdAtSort,
  available,
  setAvailable,
  setCreatedAtSort,
  allRawMaterials,
  allFinishedGoods,

  allProducts,
  productType,
  productName,
  productPrice,
  lifeSpan,
  productMaterial,
  setProductType,
  setProductName,
  setProductPrice,
  setLifeSpan,
  setProductMaterial,
  setIsConfirmed,
  isConfirmed,
  setSelectedProduct,
  allCrates,
  allPincodesAndAreas,
  tabProps = tabValues.rawMaterials,
  drawOpenProp,
  setDrawOpenProp,
  handleDrawerOpen,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tabValues.rawMaterials);
  const [isDrawerOpen, setDrawerOpen] = useState(drawOpenProp);
  const [itemName, setItemName] = useState("");
  const [itemType, setItemType] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [itemUnit, setItemUnit] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [itemMaterial, setItemMaterial] = useState("");
  const [itemMaterials, setItemMaterials] = useState([]);
  const [rawMaterials, setRawMaterials] = useState(rawMaterialsData);
  const [finishedGoods, setFinishedGoods] = useState(finishedGoodsData);
  const [finalProducts, setFinalProducts] = useState(finalProductsData);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openPincodeDialog, setOpenPicodeDialog] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState({});
  const [error, setError] = React.useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    dispatch(GetRawMaterials(pageNumber, pageSize, available, createdAtSort));
    dispatch(GetComponents(pageNumber, pageSize, available, createdAtSort));
    dispatch(
      GetFinalProductSKU(pageNumber, pageSize, available, createdAtSort)
    );
    dispatch(GetCratesSKU(pageNumber, pageSize, available, createdAtSort));
    dispatch(GetPincodeAreas(pageNumber, pageSize, available, createdAtSort));
    // dispatch(GetProductSku(pageNumber, pageSize, available, createdAtSort));
  }, [pageNumber, pageSize, available, createdAtSort]);

  React.useEffect(() => {
    // console.log(activeTab);
    setActiveTab(tabProps);
  }, [tabProps]);

  React.useEffect(() => {}, []);
  // const handleRowClick = (rowData) => {
  //   setSelectedRowData(rowData);
  //   setDrawerOpen(true);
  // };
  React.useEffect(() => {
    setItemName("");
    setItemQuantity("");
    setItemUnit("");
    setItemDescription("");
    setItemCgst("");
    setItemSgst("");
    setItemMaterial("");
    setItemMaterials([]);
  }, [activeTab]);

  const lowStockThreshold = 20;

  // const handleTabChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  // const handleDrawerOpen = () => {

  // };

  const handleDrawerClose = () => {
    // console.log("first");
    setDrawerOpen(false);
    setDrawOpenProp(false);
  };

  React.useEffect(() => {
    console.log(itemMaterials);
    setSelectedItems([...selectedItems, ...itemMaterials]);
  }, [itemMaterials]);

  useEffect(() => {
    if (activeTab !== 4) {
      setDrawerOpen(drawOpenProp);
    } else {
      setOpenPicodeDialog(drawOpenProp);
    }
  }, [drawOpenProp]);

  // const handleItemCgstChange = (event) => {
  //   const newValue = event.target.value;
  // };

  // const handleItemSgstChange = (event) => {
  //   const newValue = event.target.value;
  // };

  const isNumeric = (value) => {
    return /^\d{0,2}$/.test(value);
  };

  const disableSubmit = () => {
    return error !== "" || itemCgst === "" || itemSgst === "";
  };
  const getSubmitButton = (cb) => {};
  const getCloseButton = (cn) => {};
  const handleAddItem = (formData) => {
    console.log("formData", formData);

    if (activeTab === tabValues.rawMaterials && formData) {
      const reqObj = {
        name: formData.name,
        details: {
          description: formData.description,
          units: formData.units,
          tax_details: {
            gst: {
              CGST: formData.CGST,
              SGST: formData.SGST,
            },
          },
        },
      };
      console.log(reqObj);
      dispatch(AddRawMaterials(reqObj));
    } else if (activeTab === tabValues.finishedGoods) {
      console.log(formData);
      const reqObj = {
        name: formData.name,
        details: {
          description: formData.name,
          material: formData.material,
          tax_details: {
            gst: {
              CGST: formData.CGST,
              SGST: formData.SGST,
            },
          },
        },
        raw_materials: itemMaterials.map((material) => ({
          id: material.id,
          quantity: parseInt(material.quantity),
          units: material.unit,
        })),
      };
      console.log(reqObj);
      dispatch(AddComponentSku(reqObj));
    } else if (activeTab === tabValues.finalProducts) {
      const reqObj = {
        type: itemType,
        name: itemName,
        price: 30,
        life_span: 1,
        details: {
          material: itemMaterial,
          tax_details: {
            gst: {
              CGST: itemCgst,
              SGST: itemSgst,
            },
          },
        },
        components: itemMaterials.map((material) => ({
          id: material.id,
          quantity: parseInt(material.quantity),
          name: material.name,
        })),
      };
      console.log(reqObj);
      dispatch(AddProductsAction(reqObj));
    }

    console.log(
      "Adding item:",
      itemName,
      itemQuantity,
      itemUnit,
      itemDescription,
      itemCgst,
      itemSgst,
      itemMaterials
    );
    const newItem = {
      id: Date.now(),
      name: itemName,
      quantity: itemQuantity,
      unit: itemUnit,
      description: itemDescription,
      cgst: itemCgst,
      sgst: itemSgst,
      materials: itemMaterials.map((material) => ({
        id: material.id,
        name: material.name,
        quantity: material.quantity,
        units: material.unit,
      })),
    };
    // console.log(newItem);
    switch (activeTab) {
      case 0:
        setRawMaterials([...rawMaterials, newItem]);
        break;
      case 1:
        setFinishedGoods([...finishedGoods, newItem]);
        break;
      case 2:
        setFinalProducts([...finalProducts, newItem]);
        break;
      default:
        break;
    }
    setItemName("");
    setItemQuantity("");
    setItemUnit("");
    setItemDescription("");
    setItemCgst("");
    setItemSgst("");
    setItemMaterials([]);
    setDrawerOpen(false);
  };

  const renderTable = () => {
    let data;
    switch (activeTab) {
      case tabValues.rawMaterials:
        data = rawMaterialsData;
        break;
      case tabValues.finishedGoods:
        data = finishedGoodsData;
        break;
      case tabValues.finalProducts:
        data = finalProductsData;
        break;
      case tabValues.crates:
        data = finalProductsData;
        break;
      case tabValues.pincode:
        data = finalProductsData;
        break;
      default:
        return null;
    }

    return (
      <>
        {activeTab === tabValues.rawMaterials && (
          <RawMaterialsTable
            allRawMaterials={allRawMaterials}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPageNumber={lastPageNumber}
            createdAtSort={createdAtSort}
            available={available}
            setDrawOpenProp={setDrawOpenProp}
          />
        )}

        {activeTab === tabValues.finishedGoods && (
          <FinishedGoodsTable
            allFinishedGoods={allFinishedGoods}
            setDrawOpenProp={setDrawOpenProp}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
          />
        )}

        {/* {activeTab === 2 && <FinalProductsTable allProducts={allProducts}/>} */}
        {activeTab === tabValues.finalProducts && (
          <SKUProducts
            allProducts={allProducts}
            setDrawOpenProp={setDrawOpenProp}
          />
        )}
        {activeTab === tabValues.crates && (
          <Crates allCrates={allCrates} setDrawOpenProp={setDrawOpenProp} />
        )}
        {activeTab === tabValues.pincode && (
          <PincodesAndAres
            openPincodeDialog={openPincodeDialog}
            setOpenPicodeDialog={setOpenPicodeDialog}
            currentRow={currentRow}
            setCurrentRow={setCurrentRow}
            allPincodesAndAreas={allPincodesAndAreas}
            allProducts={allProducts}
            setDrawOpenProp={setDrawOpenProp}
          />
        )}
      </>
    );
  };

  const renderDrawerFields = () => {
    switch (activeTab) {
      case tabValues.rawMaterials:
        return (
          <>
            <Box sx={appStyle.general.drawField.drawFieldBox}>
              <CustomForm
                data={[
                  {
                    inputType: appMeta?.inputFieldTypes?.INPUT,
                    placeHolder: "Item Name",
                    defaultvalue: "",
                    name: "name",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Unit",
                    defaultvalue: "",
                    name: "units",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Description",
                    defaultvalue: "",
                    name: "description",
                    textFieldType: appMeta.textFieldTypes.TEXT,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "CGST(%)",
                    defaultvalue: "",
                    name: "CGST",
                    textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "SGST(%)",
                    defaultvalue: "",
                    name: "SGST",
                    textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                    isHintRequired: true,
                    isRequired: true,
                  },
                ]}
                handleSubmit={handleAddItem}
                handleClose={handleDrawerClose}
                // hideCloseButton={true}
                // getSubmitButton={getSubmitButton}
                // getCloseButton={getCloseButton}
              />
              {/* <TextField
                label="Item Name"
                variant="outlined"
                fullWidth
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
              <TextField
                label="Unit"
                variant="outlined"
                fullWidth
                value={itemUnit}
                onChange={(e) => setItemUnit(e.target.value)}
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={itemDescription}
                onChange={(e) => setItemDescription(e.target.value)}
              />
              <TextField
                type="number"
                label="CGST"
                variant="outlined"
                error={error !== ""}
                helperText={error}
                fullWidth
                value={itemCgst}
                onChange={(e) => setItemCgst(e.target.value)}
                inputProps={{ maxLength: 2, max: 99 }}
              />
              <TextField
                type="number"
                label="SGST"
                variant="outlined"
                fullWidth
                error={error !== ""}
                helperText={error}
                value={itemSgst}
                onChange={(e) => setItemSgst(e.target.value)}
                inputProps={{ maxLength: 2, max: 99 }}
              /> */}
            </Box>
          </>
        );
      case tabValues.finishedGoods:
        return (
          <>
            <Box sx={appStyle.general.drawField.drawFieldBox}>
              <CustomForm
                data={[
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Item Name",
                    defaultvalue: "",
                    name: "name",
                    // textFieldType: appMeta.textFieldTypes.EMAIL,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Material",
                    defaultvalue: "",
                    name: "material",
                    // textFieldType: appMeta.textFieldTypes.EMAIL,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "Description",
                    defaultvalue: "",
                    name: "description",
                    // textFieldType: appMeta.textFieldTypes.EMAIL,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "CGST(%)",
                    defaultvalue: "",
                    name: "CGST",
                    // textFieldType: appMeta.textFieldTypes.EMAIL,
                    isHintRequired: true,
                    isRequired: true,
                  },
                  {
                    inputType: appMeta.inputFieldTypes.INPUT,
                    placeHolder: "SGST(%)",
                    defaultvalue: "",
                    name: "SGST",
                    // textFieldType: appMeta.textFieldTypes.EMAIL,
                    isHintRequired: true,
                    isRequired: true,
                  },
                ]}
                handleSubmit={handleAddItem}
                handleClose={handleDrawerClose}
              />
              {/* <TextField
                label="Item Name"
                variant="outlined"
                fullWidth
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
              />
              <TextField
                label="Material"
                variant="outlined"
                fullWidth
                value={itemMaterial}
                onChange={(e) => setItemMaterial(e.target.value)}
              />
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={itemDescription}
                onChange={(e) => setItemDescription(e.target.value)}
              />
              <TextField
                label="CGST"
                variant="outlined"
                fullWidth
                value={itemCgst}
                onChange={(e) => setItemCgst(e.target.value)}
              />
              <TextField
                label="SGST"
                variant="outlined"
                fullWidth
                value={itemSgst}
                onChange={(e) => setItemSgst(e.target.value)}
              /> */}
            </Box>
            {/* Selected items table */}
            {itemMaterials.length > 0 && (
              <>
                <TableContainer component={Paper} style={{ marginTop: "24px" }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: appColors.secondary }}>
                      <TableRow>
                        <TableCell>Selected Item</TableCell>
                        <TableCell>Unit</TableCell>
                        <TableCell>Quantity/Finished Good</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemMaterials.map((item) => (
                        <TableRow key={item.name} style={{ color: "green" }}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.unit}</TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={item.quantity || 0}
                              onChange={(e) =>
                                handleQuantityChange(e.target.value, item.name)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        );
      case tabValues.finalProducts:
        return (
          <>
            <AddFinalSkuProduct
              pageNumber={pageNumber}
              pageSize={pageSize}
              available={available}
              createdAtSort={createdAtSort}
              handleDrawerClose={handleDrawerClose}
            />
          </>
        );
      case tabValues.crates:
        return (
          <>
            <AddCrates setDrawOpenProp={setDrawOpenProp} />
            {/* <AddFinalProductSKU /> */}
          </>
        );
      case tabValues.pincode:
        return (
          <>
            <AddUpdatePicodeAreaSku
              openPincodeDialog={openPincodeDialog}
              setOpenPicodeDialog={setOpenPicodeDialog}
              updatePincodeData={currentRow}
              setupdatePincodeData={setCurrentRow}
              setDrawOpenProp={setDrawOpenProp}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleQuantityChange = (quantity, materialName) => {
    setItemMaterials((prevMaterials) =>
      prevMaterials.map((material) =>
        material.name === materialName
          ? { ...material, quantity: quantity }
          : material
      )
    );
  };

  return (
    <div>
      <CssBaseline />

      {/* <AppBar
        position="relative"
        sx={{
          backgroundColor:
            appStyle.general.colors(colors).secondarySkuTabBar.background,
          color: colors.primary[200],
          ...appStyle.sku.secondLevelTab.appBar,
        }}
      >
        <Toolbar>
          <Tabs
            variant="scrollable"
            allowScrollButtonsMobile
            value={activeTab}
            onChange={handleTabChange}
            aria-label="SKU Management Tabs"
            sx={{
              color: colors.primary[400],
              indicatorColor:
                appStyle.general.colors(colors).secondarySkuTabBar
                  .indicatorColor,
            }}
          >
            <Tab sx={appStyle.general.tab.secondLevel} label="Raw Materials" />
            <Tab
              sx={appStyle.general.tab.secondLevel}
              label="Finished Goods (Components)"
            />
            <Tab sx={appStyle.general.tab.secondLevel} label="Final Products" />
            <Tab sx={appStyle.general.tab.secondLevel} label="Crates" />
            <Tab
              sx={appStyle.general.tab.secondLevel}
              label="Pincodes and Areas"
            />
          </Tabs>
        </Toolbar>
      </AppBar> */}
      {/* <Box sx={appStyle.general.button.addButtonBox}>
        <Button
          onClick={handleDrawerOpen}
          variant="contained"
          color="secondary"
          sx={appStyle.general.button.addButton}
          endIcon={<AddIcon />}
          size="small"
        >
          Add{" "}
          {activeTab === 0
            ? "Raw Material"
            : activeTab === 1
            ? "Finished Good"
            : activeTab === 2
            ? "Final Product"
            : activeTab === 3
            ? "Crates"
            : " pincodes and areas"}
        </Button>
      </Box> */}

      {/* <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          style: {
            width: isMobile ? "75%" : "500px",
            padding: "20px",
          },
        }}
      > */}
      <CustomDrawer
        open={isDrawerOpen}
        title={
          activeTab === tabValues.rawMaterials
            ? "Add Raw Material"
            : activeTab === tabValues.finishedGoods
            ? "Add Finished Good"
            : activeTab === tabValues.finalProducts
            ? "Add Final Product"
            : activeTab === tabValues.crates
            ? "Add Crates"
            : activeTab === tabValues.pincode
            ? "Add pincodes and areas"
            : ""
        }
        width={
          activeTab === tabValues.finalProducts ? (isMobile ? 300 : 500) : 300
        }
      >
        <div>
          {/* <Typography variant="h6">
            Add{" "}
            
          </Typography> */}
          <form style={{ margin: "16px" }}>
            {renderDrawerFields()}
            {/* {activeTab !== 2 && activeTab !== 3 ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleAddItem}
                style={appStyle.general.button.blueButton}
              >
                Add{" "}
                {activeTab === 0
                  ? "Raw Material"
                  : activeTab === 1
                  ? "Finished Good"
                  : activeTab === 2
                  ? "Final Product"
                  : activeTab === 3
                  ? "Crates"
                  : "Add pincodes and areas"}
              </Button>
            ) : (
              ""
            )} */}
          </form>
        </div>
        {/* </Drawer> */}
      </CustomDrawer>

      <Container style={appStyle.general.table.containerForTable}>
        {renderTable()}
      </Container>
    </div>
  );
};

export default SKUManagementApp;
