import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  useTheme,
  TablePagination,
  Typography,
} from "@mui/material";
import { appColors } from "../../AppMeta/appColors";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { SettingsSuggestRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import {
  GetRawMaterials,
  UpdateRawMaterial,
} from "../../Redux/Actions/ProductComponentsAction";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import appFilter from "../../AppMeta/appFilterData";
import CustomTable from "../../Components/CustomComp/CustomTable";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";
import CustomDrawer from "../../Components/CustomComp/CustomDrawer";

const RawMaterialsTable = ({
  allRawMaterials,
  pageNumber,
  pageSize,
  lastPageNumber,
  createdAtSort,
  available,
  setDrawOpenProp,
  setPageNumber,
  setPageSize,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [editRMDialog, setEditRMDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getOrders, setGetOrders] = React.useState([]);
  const [name, setName] = React.useState("");
  const [itemDescription, setItemDescription] = React.useState("");
  const [unit, setUnit] = React.useState("");
  const [cgst, setCgst] = React.useState("");
  const [sgst, setSgst] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [error, setError] = React.useState("");
  const [minBookQty, setMinBookQty] = React.useState("");
  const [maxBookQty, setMaxBookQty] = React.useState("");

  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [description, setDescription] = React.useState("");
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const [nameSearch, setNameSearch] = React.useState("");
  const [unitSearch, setUnitSearch] = React.useState("");

  const [availableFilter, setAvailableFilter] = React.useState("all");

  const [tableData, setTableData] = useState([]);

  const handleItemCgstChange = (event) => {
    setItemCgst(event.target.value);
    if (event.target.value !== setItemSgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must not be greater than two digit number");
    } else {
      setItemCgst(newValue);
      setError("");
    }
  };
  React.useEffect(() => {
    console.log("All Raw Materials");
    console.log(allRawMaterials);
  }, []);

  const isNumeric = (value) => {
    return /^\d{0,2}$/.test(value);
  };

  const disableSubmit = () => {
    return error !== "" || itemCgst === "" || itemSgst === "";
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeOrders = () => {
    setGetOrders(allRawMaterials);
  };

  const handleItemSgstChange = (event) => {
    setItemSgst(event.target.value);
    if (event.target.value !== setItemCgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must be a number less than or equal to 99");
    } else {
      setItemSgst(newValue);
      setError("");
    }
  };

  const handleFilterChange = (event) => {
    setAvailableFilter(event.target.value);
  };

  const handleClickRow = (index, material) => {
    setSelectedRow(material);

    //
    setName(material?.name);
    setItemDescription(material?.details.description);
    setUnit(material?.details.units);
    setItemCgst(material?.details?.tax_details?.gst?.CGST);
    setItemSgst(material?.details.tax_details.gst.CGST);
    //
    setEditRMDialog(true);
  };

  // React.useEffect(() => {
  //   console.log("selectedRow", selectedRow);
  //   setName(selectedRow?.name);
  //   setItemDescription(selectedRow?.details.description);
  //   setUnit(selectedRow?.details.units);
  //   setItemCgst(selectedRow?.details?.tax_details?.gst?.CGST);
  //   setItemSgst(selectedRow?.details.tax_details.gst.CGST);
  // }, [selectedRow]);

  // const handleUpdate = (data) => {
  //   console.log(data);
  //   setName(data?.name);
  //   setItemDescription(data?.description);
  //   setUnit(data?.unit);
  //   setItemCgst(data?.cgst);
  //   setItemSgst(data?.sgst);
  //   setDescription("Do you want to edit the raw material details?");
  //   setOpenConfirmationDialog(true);
  // };

  const handleUpdate = (data) => {
    const reqObj = {
      id: selectedRow.id,
      name: data?.name,
      is_force_update: data?.isConfirmed,
      is_available: true,
      details: {
        description: data?.description,
        units: data?.unit,
        tax_details: {
          gst: {
            CGST: data?.cgst,
            SGST: data?.sgst,
          },
        },
      },
    };
    dispatch(UpdateRawMaterial(reqObj));
    setTimeout(() => {
      dispatch(GetRawMaterials(pageNumber, pageSize, available, createdAtSort));
    }, 1000);
    setEditRMDialog(false);
  };

  // React.useEffect(() => {
  //   if (isConfirmed) {
  //     const reqObj = {
  //       id: selectedRow.id,
  //       name: name,
  //       is_force_update: true,
  //       is_available: true,
  //       details: {
  //         description: itemDescription,
  //         units: unit,
  //         tax_details: {
  //           gst: {
  //             CGST: cgst,
  //             SGST: sgst,
  //           },
  //         },
  //       },
  //     };
  //     dispatch(UpdateRawMaterial(reqObj));
  //     setTimeout(() => {
  //       dispatch(
  //         GetRawMaterials(pageNumber, pageSize, available, createdAtSort)
  //       );
  //     }, 1000);
  //     setOpenConfirmationDialog(false);
  //     setEditRMDialog(false);
  //   }
  // }, [isConfirmed]);

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setItemDescription(event.target.value);
  };

  const handleChangeUnit = (event) => {
    setUnit(event.target.value);
  };

  const handleChangeCgst = (event) => {
    setCgst(event.target.value);
  };

  const handleChangeSgst = (event) => {
    setSgst(event.target.value);
  };

  const handleSearch = () => {
    // Implement your search logic here
    // Dispatch an action to fetch filtered data based on nameSearch and unitSearch
    console.log("Search by Name:", nameSearch);
    dispatch(
      GetRawMaterials(
        pageNumber,
        pageSize,
        available,
        createdAtSort,
        nameSearch,
        unitSearch
      )
    );
    console.log("Search by Unit:", unitSearch);
  };

  // const handleFilter = () => {
  //   console.log("handle filter");
  //   dispatch(
  //     GetRawMaterials(
  //       pageNumber,
  //       pageSize,
  //       available,
  //       createdAtSort,
  //       nameSearch,
  //       unitSearch,
  //       availableFilter
  //     )
  //   );
  // };

  const getFilterData = (data) => {
    console.log("Output data from filter", data);
    setTableData(data);
  };
  const handleRawMaterialFilterClose = ({ value, serachText }) => {
    // console.log(data);
  };
  const handleRawMaterialSortClose = ({ value, serachText }) => {
    // console.log(data);
  };
  const handleAddRawMaterial = () => {
    setDrawOpenProp(true);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;
  return (
    <>
      <Box>
        {/* <CustomFilter
          filterData={appFilter.rawMaterialTableFilter}
          dispatchFunction={GetRawMaterials}
        /> */}
        <CustomTable
          tableHead={["Name", "Description", "Units", "CGST", "SGST"]}
          tableDataKey={[
            "name",
            "details.description",
            "details.units",
            "details.tax_details.gst.CGST",
            "details.tax_details.gst.SGST",
          ]}
          tableData={allRawMaterials}
          tableRowClickFunction={handleClickRow}
          pagination={true}
          filter={true}
          filterSelectOption={[
            { value: "role_name", label: "Roll Name" },
            { value: "fullname", label: "Full name" },
          ]}
          handleFilterClose={handleRawMaterialFilterClose}
          sort={true}
          sortSelectOption={[{ value: "role_name", label: "Roll Name" }]}
          handleSortClose={handleRawMaterialSortClose}
          addButton={true}
          handleOnClickAddButton={handleAddRawMaterial}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
        />

        {/* <TableContainer sx={appStyle.general.table.tableContainer}>
          <Table stickyHeader>
            <TableHead
              sx={{
                backgroundColor:
                  appStyle.general.colors(colors).table.tableHeadBackground,
                color: colors.primary[300],
                ...appStyle.general.table.tableHead,
              }}
            >
              <TableRow>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Name
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Description
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Units
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  {" "}
                  CGST
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  SGST
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Status
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: colors.primary[600] }}>
              {" "}
              {(rowsPerPage > 0
                ? allRawMaterials.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : allRawMaterials
              ).map((material, index) => (
                <TableRow
                  key={index}
                  onChange={handleChangeOrders}
                  // onClick={() => handleClickRow(material)}
                >
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {material.name}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {material.details.description}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {material.units}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {material.details.tax_details.gst.CGST}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {material.details.tax_details.gst.SGST}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {material.is_available ? "Available" : "Unavailable"}
                  </TableCell>
                  <TableCell>
                    <Button
                      color={appFonts.tablePrimaryButtonColor}
                      variant={appFonts.tablePrimaryButtonVarient}
                      sx={appStyle.general.button.editButton}
                      onClick={() => handleClickRow(material)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={appStyle.general.table.pagination}>
          <TablePagination
            rowsPerPageOptions={[25, 100, 500, 1000]}
            component="div"
            count={allRawMaterials.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box> */}
      </Box>

      <CustomDrawer title={"Edit Raw Material"} open={editRMDialog}>
        <CustomForm
          configConfirmation={{
            isConfirmationRequired: true,
            dialogContent: "Are you sure you want to update changes?",
          }}
          data={[
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Name",
              defaultValue: selectedRow?.name,
              name: "name",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Description",
              defaultValue: selectedRow?.details.description,
              name: "description",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "Unit",
              defaultValue: selectedRow?.details.units,
              name: "unit",
              textFieldType: appMeta.textFieldTypes.TEXT,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "CGST(%)",
              defaultValue: selectedRow?.details?.tax_details?.gst?.CGST,
              name: "cgst",
              textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              isHintRequired: true,
              isRequired: true,
            },
            {
              inputType: appMeta.inputFieldTypes.INPUT,
              placeHolder: "SGST(%)",
              defaultValue: selectedRow?.details?.tax_details?.gst?.SGST,
              name: "sgst",
              textFieldType: appMeta.textFieldTypes.PERCENTAGE,
              isHintRequired: true,
              isRequired: true,
            },
          ]}
          handleSubmit={handleUpdate}
          handleClose={() => {
            setEditRMDialog(false);
          }}
        />
      </CustomDrawer>

      {/* <Dialog open={editRMDialog} onClose={() => setEditRMDialog(false)}>
        <DialogTitle>Edit Raw Material Details</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              padding: "10px",
            }}
          >
            <CustomForm
              data={[
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Name",
                  defaultValue: name,
                  name: "name",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Description",
                  defaultValue: itemDescription,
                  name: "description",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Unit",
                  defaultValue: unit,
                  name: "unit",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "CGST(%)",
                  defaultValue: itemCgst,
                  name: "cgst",
                  textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "SGST(%)",
                  defaultValue: itemSgst,
                  name: "sgst",
                  textFieldType: appMeta.textFieldTypes.PERCENTAGE,
                  isHintRequired: true,
                  isRequired: true,
                },
              ]}
              handleSubmit={handleUpdate}
              handleClose={() => {
                setEditRMDialog(false);
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          open={openConfirmationDialog}
          dialogContent={description}
          onClose={() => setOpenConfirmationDialog(false)}
          onConfirm={handleEditOnConfirm}
        />
      )} */}
    </>
  );
};

export default RawMaterialsTable;
