/**
 * @author Tahir Shaik
 * @description All App Constants which are mentioned from Backend
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param {}
 * @returns {UI og Authetication Page with LogIn & SignUp Components}
 */

const appConstants = {
  appMessageType: {
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning",
    INFO: "info",
  },

  accessMenuOption: [
    "HOME",
    "SKU",
    "USERS MANAGEMENT",
    "WATER STATION",
    "FACILITY ORDERS",
    "ORGANIZATION ORDERS",
    "BOOKWATER ORDERS",
    "QR/BARCODES",
    "REWARDS AND OFFERS",
    "REPORTS",
    "SUPPORT",
    "REWARDS AND OFFERS",
    "RECYCLE ORDERS",
    "API DOCUMENTATION",
    "PROFILE",
  ],
  featureAccess: {
    userManagement: {
      profile: [
        {
          title: "Delete the user",
          value: "CREATE_ROLEsadf",
        },
        {
          title: "Change the role of the user",
          value: "CREATE_ROLdfaE",
        },
        {
          title: "List of users and details",
          value: "CREATE_ROLdfaE",
        },
      ],
      featureAndAccess: [
        {
          title: "View the list of features",
          value: "CREATE_ROLEasferer",
        },
        {
          title: "Add the new feature (Only for developers)",
          value: "CREATE_ROLafdfE",
        },
      ],
      roles: [
        {
          title: "Create/Add a new role",
          value: "CREATE_ROLsdfsaE",
        },
        {
          title: "Change the status of the role (Enable/Disable)",
          value: "CREATE_ROLErgsdzf",
        },
        {
          title: "Delete the role",
          value: "CREATE_ROLEerwasc",
        },
        {
          title: "Update the details of the role",
          value: "CREATE_ROLEefsd",
        },
        {
          title: "View the list of roles and details",
          value: "CREATE_ROLEerwad",
        },
      ],
      roleAndAccess: [
        {
          title: "Add the Page access to the role",
          value: "CREATE_ROLErefvz",
        },
        {
          title: "Remove the Page access to the role",
          value: "CREATE_ROLEfef",
        },
        {
          title: "Add the features access to the role",
          value: "CREATE_ROLEsad",
        },
        {
          title: "Remove the features access to the role",
          value: "CREATE_ROLExsz",
        },
        {
          title: "Remove legal chapter",
          value: "CREATE_ROLE1",
        },
      ],
    },
  },
};

export default appConstants;
