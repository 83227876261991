import React from "react";
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
  FormControl,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  Divider,
  Snackbar,
  Paper,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CheckStock } from "./CheckStock";
import {
  Cancel,
  Inventory,
  KeyboardBackspace,
  Margin,
} from "@mui/icons-material";
import appStyle from "../../../../AppMeta/appStyle";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import ListAlt from "@mui/icons-material/ListAlt";
import TwentyLiter from "../../../../Assests/FOM/bookwaternewcan.png";
import AddCircleOutline from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutlineOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { OrderSummary } from "./OrderSummary";

import CurrencyRupee from "@mui/icons-material/CurrencyRupee";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CratesImg from "../../../../Assests/FOM/crates2.png";
import Quantity from "@mui/icons-material/SpaceBar";
import Order from "@mui/icons-material/ShoppingBag";
import { PriceDetails } from "./PriceDetails";
import MenuIcon from "@mui/icons-material/Menu";
import { tokens } from "../../../../AppMeta/theme";
import WaterCan from "../../../../AppMeta/AppImages/WaterCanImg.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { GetFinalProductSKU } from "../../../../Redux/Actions/productsAction";
import CustomButton from "../../../../Components/CustomComp/CustomButton";
import CustomSelect from "../../../../Components/CustomComp/CustomSelect";

const Card = ({
  name,
  rate,
  count,
  returnCount,
  onClickMinus,
  onClickPlus,
  onClickReturnMinus,
  onClickReturnPlus,
  capacity,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChangeButton = () => {
    setButtonChange((prevState) => !prevState);
  };
  const [buttonChange, setButtonChange] = React.useState(false);
  return (
    <Box
      mb="5px"
      backgroundColor={colors.primary[400]}
      sx={{
        width: { xs: "100%", sm: "350px" },
        height: "120px",
        boxShadow: 3,
        borderRadius: "10px",
        display: "flex",
        padding: "10px 0 0 10px",
      }}
    >
      {/* <img
        src={WaterCan}
        style={{
          objectFit: "contain",
        }}
      /> */}
      <Box sx={{ flexGrow: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            width: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </Typography>
        <Typography variant="h5"> &#8377;{rate}</Typography>
        {buttonChange && (
          <IconButton
            onClick={handleChangeButton}
            aria-label="delete"
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        )}
        {!buttonChange && (
          <>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Qty: {capacity}
            </Typography>
            <Typography>Description:</Typography>
          </>
        )}
      </Box>
      <Box>
        <>
          <Box>
            <Typography variant="h6" fontWeight="bold">
              Required
            </Typography>
            <Box
              sx={{
                display: "flex",
                border: 1,
                justifyContent: "space-around",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  height: "1.8rem",
                  width: "1.8rem",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "0 0.3rem .3rem 0",
                  color: "#fff",
                }}
              >
                <Button size="small" color="secondary" onClick={onClickMinus}>
                  -
                </Button>
              </Box>
              <input
                value={count}
                style={{ width: "2rem", textAlign: "center" }}
              />
              <Box
                sx={{
                  height: "1.8rem",
                  width: "1.8rem",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "0 0.3rem .3rem 0",
                  color: "#fff",
                }}
              >
                <Button color="secondary" size="small" onClick={onClickPlus}>
                  <AddIcon
                    sx={{
                      fontSize: ".8rem",
                      fontWeight: "600",
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography variant="h6" fontWeight="bold">
              Return
            </Typography>
            <Box
              sx={{
                display: "flex",
                border: 1,
                justifyContent: "space-around",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  height: "1.8rem",
                  width: "1.8rem",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "0 0.3rem .3rem 0",
                  color: "#fff",
                }}
              >
                <Button
                  size="small"
                  color="secondary"
                  onClick={onClickReturnMinus}
                >
                  -
                </Button>
              </Box>
              <input
                value={returnCount}
                style={{ width: "2rem", textAlign: "center" }}
              />
              <Box
                sx={{
                  height: "1.8rem",
                  width: "1.8rem",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "0 0.3rem .3rem 0",
                  color: "#fff",
                }}
              >
                <Button
                  color="secondary"
                  size="small"
                  onClick={onClickReturnPlus}
                >
                  <AddIcon
                    sx={{
                      fontSize: ".8rem",
                      fontWeight: "600",
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      </Box>
    </Box>
  );
};

export function NewOrder({ open, handleCloseNewOrder }) {
  //datas
  const datas = {
    error: false,
    msg: "Successfully got the Details!",
    data: [
      {
        product_details: {
          id: 62,
          name: "1litre Glass bottle",
          image: "",
          price: 1,
          amount: 1,
          refill: {
            sp: 1,
            mrp: 1,
            deposit: 1,
            refund_type: 100,
            refund_amount: 1,
          },
          sku_qty: [3],
          capacity: "1litre",
          hsn_code: "22110",
          material: "GLASS",
          cgst_perc: 6,
          igst_perc: 6,
          life_span: 5,
          sgst_perc: 6,
          created_at: "11:17:29.449964",
          order_type: 100,
          updated_at: "2024-03-08T11:17:29.449964",
          description: "This is a 1litre glass bottle",
          booking_type: 2,
          max_book_qty: 99,
          min_book_qty: 1,
          one_time_sale: {
            sp: 1,
            mrp: 1,
            deposit: 1,
            refund_type: 100,
            refund_amount: 0,
          },
          facility_price: {
            deposit_price: 65,
          },
        },
        crate_details: [
          {
            id: 25,
            name: "1litre Glass bottle crate",
            image: "",
            price: 50,
            capacity: 3,
            description: "This is a 1litre glass bottle",
          },
        ],
      },
      {
        product_details: {
          id: 65,
          name: "20 Liter can",
          image: "",
          price: 1,
          amount: 1,
          refill: {
            sp: 1,
            mrp: 1,
            deposit: 1,
            refund_type: 100,
            refund_amount: 1,
          },
          sku_qty: [1],
          capacity: "20_Liters",
          hsn_code: "66787",
          material: "PET MATERIAL",
          cgst_perc: 9,
          igst_perc: 9,
          life_span: 15,
          sgst_perc: 9,
          created_at: "11:29:15.708096",
          order_type: 400,
          updated_at: "2024-03-08T11:29:15.708096",
          description: "20 litre can fill can",
          booking_type: 2,
          max_book_qty: 1000,
          min_book_qty: 2,
          one_time_sale: {
            sp: 50,
            mrp: 70,
            deposit: 150,
            refund_type: 100,
            refund_amount: 150,
          },
          facility_price: {
            deposit_price: 200,
          },
        },
        crate_details: [
          {
            id: 27,
            name: "Aluminium bottle crate",
            image: "",
            price: 120,
            capacity: 3,
            description: "Aluminium bottle crate",
          },
        ],
      },
      {
        product_details: {
          id: 64,
          name: "ALUMINUM Bottle",
          image: "",
          price: 1,
          amount: 1,
          refill: {
            sp: 1,
            mrp: 1,
            deposit: 1,
            refund_type: 100,
            refund_amount: 1,
          },
          sku_qty: [3],
          capacity: "300 ml",
          hsn_code: "66787",
          material: "ALUMINUM",
          cgst_perc: 9,
          igst_perc: 9,
          life_span: 15,
          sgst_perc: 9,
          created_at: "11:26:12.927201",
          order_type: 400,
          updated_at: "2024-03-08T11:26:12.927201",
          description: "Aluminum bottle ",
          booking_type: 2,
          max_book_qty: 50,
          min_book_qty: 2,
          one_time_sale: {
            sp: 40,
            mrp: 50,
            deposit: 180,
            refund_type: 100,
            refund_amount: 150,
          },
          facility_price: {
            deposit_price: 50,
          },
        },
        crate_details: [
          {
            id: 27,
            name: "Aluminium bottle crate",
            image: "",
            price: 120,
            capacity: 3,
            description: "Aluminium bottle crate",
          },
        ],
      },
      {
        product_details: {
          id: 63,
          name: "500 ML Steel Bottle(M)",
          image: "",
          price: 1,
          amount: 1,
          refill: {
            sp: 1,
            mrp: 1,
            deposit: 1,
            refund_type: 100,
            refund_amount: 1,
          },
          sku_qty: [3],
          capacity: "500 ML",
          hsn_code: "22110",
          material: "STEEL",
          cgst_perc: 9,
          igst_perc: 9,
          life_span: 5,
          sgst_perc: 9,
          created_at: "11:19:42.610985",
          order_type: 100,
          updated_at: "2024-03-08T11:19:42.610985",
          description: "500 ML Steel Bottle",
          booking_type: 2,
          max_book_qty: 10,
          min_book_qty: 5,
          one_time_sale: {
            sp: 1,
            mrp: 1,
            deposit: 1,
            refund_type: 100,
            refund_amount: 0,
          },
          facility_price: {
            deposit_price: 80,
          },
        },
        crate_details: [
          {
            id: 26,
            name: "500 ML Steel Bottle Crate (M)",
            image: "",
            price: 40,
            capacity: 4,
            description: "500 ML steel bottle crate",
          },
        ],
      },
    ],
  };
  //1.useState
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [products, setProducts] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [returnCount, setReturnCount] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [productName, setProductName] = React.useState("");
  const [productId, setProductId] = React.useState("");
  const [totalPrize, setTotalPrize] = React.useState(0);
  const [openOrderSummary, setOrderSummary] = React.useState(false);
  const [capacity, setCapacity] = React.useState("");
  const [openProductDetails, setProductDetails] = React.useState(false);

  const [crateTotalPrize, setCrateTotalPrize] = React.useState(0);
  const [selectedCrates, setSelectedCrates] = React.useState({});
  const [orderData, setOrderData] = React.useState(null);
  const [priceDetails, setPriceDetails] = React.useState({});
  const [nameChange, setNameChange] = React.useState("Order");
  const [disableSelect, setDisableSelect] = React.useState(true);
  const [stock, setStock] = React.useState(true);
  const [showCratesDetails, setShowCratesDetails] = React.useState(true);
  const [showProductsDetails, setShowProductsDetails] = React.useState(true);
  const [dialogMaxWidth, setDialogMaxWidth] = React.useState("sm");
  const [openDialogStock, setOpenDialogStock] = React.useState(false);
  const [totalCratesPrize, setTotalCratesPrize] = React.useState("");
  const [productCgst, setProductCGST] = React.useState("");
  const [productSgst, setProductSGST] = React.useState("");

  const dispatch = useDispatch();

  const finalProducts = useSelector((state) => state.productData);

  React.useEffect(() => {
    dispatch(GetFinalProductSKU());
  }, []);

  React.useEffect(() => {
    console.log("finalProducts");
    console.log(finalProducts);
  }, [finalProducts]);

  //2.useEffect
  React.useEffect(() => {
    setProducts(finalProducts.finalProduct);
  }, [finalProducts.finalProduct]);

  React.useEffect(() => {
    console.log("SKU Products");
    console.log(products);
  }, [products]);

  React.useEffect(() => {
    console.log("Selected Products");
    console.log(selectedProduct);
    setPrice(selectedProduct?.product_details?.facility_price?.deposit_price);
    setProductName(selectedProduct?.product_details?.name);
    setCapacity(selectedProduct?.product_details?.capacity);
    console.log("CGST");
    console.log(selectedProduct?.product_details?.cgst_perc);
    console.log("SGST");
    console.log(selectedProduct?.product_details?.sgst_perc);
    setProductCGST(selectedProduct?.product_details?.cgst_perc);
    setProductSGST(selectedProduct?.product_details?.sgst_perc);
  }, [selectedProduct]);

  React.useEffect(() => {
    console.log("Product ID");
    console.log(productId);
    products.map((item) => {
      console.log("Item");
      console.log(item);
      if (item.product_details.id === productId) {
        console.log("item set");
        console.log(item);
        setSelectedProduct(item);
      }
    });
  }, [productId]);

  React.useEffect(() => {
    setTotalPrize(count * price);
    setCrateTotalPrize();
  }, [count]);

  React.useEffect(() => {
    if (
      finalProducts.finalProduct !== undefined &&
      finalProducts.finalProduct !== null &&
      finalProducts.finalProduct.length > 0
    ) {
      console.log(
        "productssss",
        finalProducts.finalProduct[0]?.product_details?.id
      );
      setProductId(() => finalProducts.finalProduct[0]?.product_details?.id);
      setProductDetails(true);
      setOrderSummary(false);
    }
  }, [finalProducts.finalProduct]);

  //3.Functions
  const handleProductChange = (event) => {
    setProductId(event.target.value);
    setProductDetails(true);
    setOrderSummary(false);
  };

  const handleQuantityChange = (value) => {
    setCount(Math.max(0, count + value));
  };

  const handleReturnQuantityChange = (value) => {
    setReturnCount(Math.max(0, returnCount + value));
  };

  const handleClickStock = () => {
    setOpenDialogStock(true);
  };

  const handleClickOrder = () => {
    setOrderSummary(true);
    setProductDetails(false);
    setNameChange("Order Summary and Price Details");
    setDisableSelect(false);
    setStock(false);
    setDialogMaxWidth("md");

    const order = {
      container_master_id: selectedProduct.product_details.id,
      container_material: selectedProduct.product_details.material,
      container_capacity: selectedProduct.product_details.capacity,
      order_qty: count,
      return_qty: 0,
      payment_type: 100,
      payment_method: 3,
      po_file: "",
      total_price: Object.keys(selectedCrates).reduce(
        (total, crateId) =>
          total +
          (selectedCrates[crateId]?.quantity || 0) *
            (selectedCrates[crateId]?.price || 0),
        0
      ),
      crates: Object.keys(selectedCrates).map((crateId) => {
        const crate = selectedCrates[crateId];
        return {
          name: crate.name,
          order_qty: crate.quantity,
          //   price: crate.price,
        };
      }),
    };

    const priceDetails = {
      container_material: selectedProduct?.product_details?.material,
      container_capacity: selectedProduct?.product_details?.capacity,
      product_price:
        selectedProduct?.product_details?.facility_price?.deposit_price,
      total_product_price: totalPrize,
      order_qty: count,
      crates: Object.keys(selectedCrates).map((crateId) => {
        const crate = selectedCrates[crateId];
        return {
          name: crate.name,
          order_qty: crate.quantity,
          price: crate.price,
          total_price: selectedCrates[crateId]?.quantity * crate.price,
        };
      }),
    };
    console.log("priceDetails");
    console.log(priceDetails);
    setOrderData(order);
    setPriceDetails(priceDetails);
    console.log("order");
    console.log(order);
    const totalCratesPrize = () => {
      let sum = 0;
      for (let i = 0; i < priceDetails.crates.length; i++) {
        sum += priceDetails.crates[i].total_price;
      }
      return sum;
    };
    console.log("Total Crate Prize");
    console.log(totalCratesPrize());
    setTotalCratesPrize(totalCratesPrize());
  };

  const handleCrateQuantityChange = (crateId, crateName, value, price) => {
    setSelectedCrates((prevState) => {
      const updatedCrates = { ...prevState };
      const newQuantity = Math.max(
        0,
        (updatedCrates[crateId]?.quantity || 0) + value
      ); // Ensure crate quantity is not negative
      updatedCrates[crateId] = {
        id: crateId,
        name: crateName,
        quantity: newQuantity,
        price: price,
      };
      return updatedCrates;
    });
  };

  const handleBackClick = () => {
    setProductDetails(true);
    setOrderSummary(false);
    setDisableSelect(true);
    setDialogMaxWidth("sm");
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div>
        <Dialog
          fullScreen={window.innerWidth < 600 ? true : false}
          maxWidth={dialogMaxWidth}
          open={open}
          onClose={handleCloseNewOrder}
          PaperProps={{
            style: {
              // backgroundColor: colors.primary[400],
              backgroundColor: appStyle.general.button.blueButton,
              m: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              boxShadow: 3,
              margin: 0,
              backgroundColor: appStyle.general.button.blueButton,
            }}
          >
            <Typography
              color="white"
              variant="h3"
              ml="10px"
              mt="2px"
              fontWeight="bold"
              sx={{
                textAlign: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {nameChange}
            </Typography>
            {/* <Button
              color="error"
              size="small"
              sx={{ borderRadius: "20px" }}
              backgroundColor="red"
              variant="contained"
              onClick={handleCloseNewOrder}
            >
              <CloseIcon />
            </Button> */}
            <CustomButton
              handleClick={handleCloseNewOrder}
              // label="Confirm"
              color="error"
              variant="contained"
              startIcon={<CloseIcon />}
              borderRadius="20px"
            />
          </Box>

          <DialogContent>
            {/* {stock && (
              <>
            
                <CustomButton
                  handleClick={handleClickStock}
                  label="Check Stock"
                  color="secondary"
                  variant="outlined"
                  startIcon={<CheckStock />}
                  // borderRadius="20px"
                />
              </>
            )} */}
            <Box
              width="100%"
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
            >
              <Box sx={{ width: "100%", alignSelf: "center" }}>
                {disableSelect && (
                  <>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        color: "royalblue",
                      }}
                    >
                      Select Your Product Here!!!!
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      {/* <FormControl
                        variant="outlined"
                        sx={{
                          width: "220px",
                        }}
                      >
                        <InputLabel>Select Products</InputLabel>
                        <Select
                          label="Select Products"
                          value={productId}
                          onChange={handleProductChange}
                          fullWidth
                        >
                          {products?.map((item) => (
                            <MenuItem
                              sx={{ fontWeight: "bold", borderRadius: "10px" }}
                              key={item.product_details.id}
                              value={item.product_details.id}
                            >
                              {item.product_details.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      {/* <CustomSelect
                        name="Select Product"
                        input={products?.map((role) => {
                          return {
                            value: role.product_details.id,
                            menuItem: role.product_details.name,
                          };
                        })}
                        defaultValue={productId}
                        handleChange={handleProductChange}
                        variant="standard"
                        sx={{ width: "11rem" }}
                      /> */}
                    </Box>
                  </>
                )}
                {/* {productId && openProductDetails && (
                  <>
                    <Typography>Products</Typography>
                    <Card
                      name={productName}
                      rate={price}
                      count={count}
                      returnCount={returnCount}
                      capacity={1}
                      onClickMinus={() => handleQuantityChange(-1)}
                      onClickPlus={() => handleQuantityChange(+1)}
                      onClickReturnMinus={() => handleReturnQuantityChange(-1)}
                      onClickReturnPlus={() => handleReturnQuantityChange(+1)}
                    />
                  </>
                )} */}

                {productId &&
                  openProductDetails &&
                  products?.map((data) => {
                    return (
                      <>
                        <Card
                          name={data.product_details?.name}
                          rate={
                            data.product_details?.facility_price?.deposit_price
                          }
                          capacity={data.capacity}
                          count={
                            selectedCrates[data.product_details?.id]
                              ?.quantity || 0
                          }
                          onClickMinus={() =>
                            handleCrateQuantityChange(
                              data.product_details?.id,
                              data.product_details?.name,
                              -1,
                              data.product_details?.facility_price
                                ?.deposit_price
                            )
                          }
                          onClickPlus={() =>
                            handleCrateQuantityChange(
                              data.product_details?.id,
                              data.product_details?.name,
                              +1,
                              data.product_details?.facility_price
                                ?.deposit_price
                            )
                          }
                        />
                      </>
                    );
                  })}
              </Box>
            </Box>
            {openProductDetails && (
              <Box display="flex" p="15px" justifyContent="space-between">
                {/* <Button
                  startIcon={<Cancel />}
                  variant="outlined"
                  size="large"
                  color="inherit"
                  onClick={handleCloseNewOrder}
                >
                  Cancel
                </Button> */}
                <CustomButton
                  handleClick={handleCloseNewOrder}
                  label="close"
                  color="error"
                  variant="contained"
                  startIcon={<Cancel />}
                />
                {/* <Button
                  startIcon={<ShoppingCart />}
                  variant="contained"
                  size="large"
                  onClick={handleClickOrder}
                  color="secondary"
                >
                  CheckOut
                </Button> */}
                <CustomButton
                  handleClick={handleClickOrder}
                  label="Check Out"
                  color="primary"
                  variant="contained"
                  startIcon={<ShoppingCart />}
                />
              </Box>
            )}

            {productId && openOrderSummary && (
              <>
                <CustomButton
                  handleClick={handleBackClick}
                  label="Back"
                  color="primary"
                  variant="contained"
                  startIcon={<KeyboardBackspace />}
                />
                {/* <Box
                    sx={{ display: "flex" }}
                    flexDirection={isMobile ? "column" : "row"}
                  > */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={7}>
                    <OrderSummary
                      setShowProductsDetails={setShowProductsDetails}
                      showProductsDetails={showProductsDetails}
                      productName={productName}
                      price={price}
                      selectedCrates={selectedCrates}
                      priceDetails={priceDetails}
                      setShowCratesDetails={setShowCratesDetails}
                      showCratesDetails={showCratesDetails}
                    />
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <PriceDetails
                      priceDetails={priceDetails}
                      selectedProduct={selectedProduct}
                      selectedCrates={selectedCrates}
                      totalCratesPrize={totalCratesPrize}
                      count={count}
                      returnCount={returnCount}
                      price={price}
                      productCgst={productCgst}
                      productSgst={productSgst}
                      totalPrize={totalPrize}
                      handleCloseNewOrder={handleCloseNewOrder}
                    />
                  </Grid>
                </Grid>
                {/* </Box> */}
              </>
            )}
          </DialogContent>
        </Dialog>
        {openDialogStock && (
          <CheckStock open={openDialogStock} close={setOpenDialogStock} />
        )}
      </div>
    </>
  );
}
