/**
 * @author Vinay
 * @date 14-11-2024
 * @description This is the Virtual Labcar Page for the Application
 */
//Importing the required components/functions from the libraries

import actionTypes from "../actionTypes";
/**
 * @author Vinay
 * @date 14-11-2024
 * @description Action to load/start the spinner
 * @param {number} count
 * @returns dispatch an action to the reducer
 */
export const startLoading = (count) => {
  return {
    type: actionTypes.SPINNER_LOADING,
    payload: { totalLoadingCounts: count ? count : 1 },
  };
};
/**
 * @author Vinay
 * @date 14-11-2024
 * @description Action to stop the spinner
 * @param {} count
 * @returns dispatch an action to the reducer
 */
export const stopLoading = () => {
  return {
    type: actionTypes.SPINNER_LOADED,
  };
};
