import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

function CustomAutoComplete({ field, handleInputChange, errors }) {
  const [selectedData, setSelectedData] = useState();
  const [searchText, setSearchText] = useState();
  useEffect(() => {
    console.log("---AutoComplete input---", field);
  }, [searchText]);
  return (
    <Box>
      <Autocomplete
        id="country-select-demo"
        options={field.input || []}
        autoHighlight
        getOptionLabel={(option) => {
          return option?.[field.optionKey];
        }}
        onChange={(_, selectedOption) => {
          setSelectedData(selectedOption?.[field.optionKey]);
          handleInputChange(field.name, selectedOption?.[field.outputKey]);
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;

          return (
            <Box
              key={key}
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...optionProps}
            >
              {`${option?.[field.optionKey]}`}
            </Box>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              fullWidth={false}
              label={field.label}
              variant="standard"
              onChange={(e) => {
                setSearchText(e.target.value);
                field.searchAndUpdateAPI(e.target.value);
              }}
              error={errors[field.name]}
              value={searchText}
              sx={{
                minWidth: "25ch",
              }}
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                },
              }}
            />
          );
        }}
      />
    </Box>
  );
}

export default CustomAutoComplete;
