import { Button } from "@mui/material";
import React from "react";
import appStyle from "../../AppMeta/appStyle";
import { useTheme } from "@emotion/react";

/**
 * @author : Janarthanan
 * @Date : 6/11/24
 * @description Reusable button component.
 * @param { size, variant, btnName, handleOnClick, btnStyle }
 * @return The rendered button component.
 */
function CustomButton({
  endIcon,
  startIcon,
  size,
  variant,
  btnName,
  handleOnClick,
  borderRadius,
  handleClick,
  disabled = false,
  color,
  label,
  style,
}) {
  const theme = useTheme();
  return (
    <Button
      size={size}
      variant={variant}
      onClick={handleOnClick ? handleOnClick : handleClick}
      disabled={disabled}
      sx={{
        borderRadius: "20rem",
        ...(color === "error"
          ? appStyle.general.button.redButton
          : appStyle.general.button.blueButton),
        // theme.palette.mode === "dark"
        // ? appStyle.general.button.blueButtonDarkTheme
        // : appStyle.general.button.blueButton),
        // background: "linear-gradient(#1f78ff, #0b48a7)",
        // margin: "1rem",
        // color: "white",
        // ":hover": {
        //   background: "linear-gradient(#0b48a7,#1f78ff)",
        // },
        ...style,
      }}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {btnName ? btnName : label}
    </Button>
  );
}

export default CustomButton;
