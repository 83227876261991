import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AddCratesAction } from "../../Redux/Actions/addCratesAction";
import appStyle from "../../AppMeta/appStyle";
import CustomForm from "../../Components/CustomComp/CustomForm";
import appMeta from "../../AppMeta/appMeta";

function AddCrates({ setDrawOpenProp }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [price, setPrice] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [igst, setIgst] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [error, setError] = React.useState("");
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [itemIgst, setItemIgst] = useState("");
  const [error1, setError1] = React.useState("");
  const [error2, setError2] = React.useState("");
  const [error3, setError3] = React.useState("");

  const handleAddCrates = (formData) => {
    console.log("addCrates");
    const reqObj = {
      name: formData.name,
      capacity: formData.capacity,
      description: formData.description,
      price: formData.price,
      image: image,
      hsn_code: formData.hsnCode,
      cgst_perc: formData.CGST,
      sgst_perc: formData.SGST,
      igst_perc: formData.IGST,
    };
    dispatch(AddCratesAction(reqObj));
  };

  const handlePriceChange = (event) => {
    const newValue = event.target.value;
    if (newValue < 0) {
      setError2("Negative values are not allowed");
    } else {
      setPrice(newValue);
      setError2("");
    }
  };

  const handleHSNChange = (event) => {
    const newValue = event.target.value;
    if (newValue < 0) {
      setError3("Negative values are not allowed");
    } else {
      setHsnCode(newValue);
      setError3("");
    }
  };

  return (
    <Box>
      <CustomForm
        data={[
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Name",
            defaultvalue: "",
            name: "name",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Capacity",
            defaultvalue: "",
            name: "capacity",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Price",
            defaultvalue: "",
            name: "price",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "HSN Code",
            defaultvalue: "",
            name: "hsnCode",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "CGST(%)",
            defaultvalue: "",
            name: "CGST",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "SGST(%)",
            defaultvalue: "",
            name: "SGST",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "IGST(%)",
            defaultvalue: "",
            name: "IGST",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
          },
          {
            inputType: appMeta.inputFieldTypes.INPUT,
            placeHolder: "Description",
            defaultvalue: "",
            name: "description",
            // textFieldType: appMeta.textFieldTypes.EMAIL,
            isHintRequired: true,
            isRequired: true,
          },
        ]}
        handleSubmit={handleAddCrates}
        handleClose={() => setDrawOpenProp(false)}
      />
      {/* <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Name"
        type="text"
        fullWidth
        variant="standard"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        margin="dense"
        id="capacity"
        label="Capacity"
        type="number"
        fullWidth
        variant="standard"
        value={capacity}
        onChange={(e) => setCapacity(e.target.value)}
      />
      <TextField
        margin="dense"
        id="price"
        label="Price"
        type="number"
        fullWidth
        variant="standard"
        value={price}
        error={error2 !== ""}
        helperText={error2}
        onChange={handlePriceChange}
      />
      <TextField
        margin="dense"
        id="hsn_code"
        label="HSN Code"
        type="text"
        fullWidth
        variant="standard"
        value={hsnCode}
        error={error3 !== ""}
        helperText={error3}
        onChange={handleHSNChange}
      />
      <TextField
        margin="dense"
        id="cgst_perc"
        label="CGST (%)"
        fullWidth
        variant="standard"
        value={itemCgst}
        onChange={(e) => setItemCgst(e.target.value)}
      />
      <TextField
        margin="dense"
        id="sgst_perc"
        label="SGST (%)"
        fullWidth
        variant="standard"
        value={itemSgst}
        onChange={(e) => setItemSgst(e.target.value)}
      />
      <TextField
        margin="dense"
        id="igst_perc"
        label="IGST (%)"
        fullWidth
        variant="standard"
        value={itemIgst}
        onChange={(e) => setItemIgst(e.target.value)}
      />
      <TextField
        margin="dense"
        id="description"
        label="Description"
        type="text"
        fullWidth
        variant="standard"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      /> */}
      {/* <Button
        variant="contained"
        color="secondary"
        onClick={handleAddCrates}
        sx={appStyle.general.button.blueButton}
      >
        Add Crates
      </Button> */}
    </Box>
  );
}

export default AddCrates;
