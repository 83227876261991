import React from "react";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import {
  TextField,
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  GetIotDevicesFacility,
  GetLinesFacility,
  UpdateLinesFacility,
} from "../../../Redux/Actions/addFacilityWFSFillingAction";
import { useDispatch, useSelector } from "react-redux";
import useScreenSize from "../../../Hooks/CustomHooks/useScreenSize";
import { GetIotDevices } from "../../../Redux/Actions/iotDeviceAction";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import { getInputForSelectFromArray } from "../../../Components/Utils/CustomFormUtils";
import appMeta from "../../../AppMeta/appMeta";
import { GetIotDevicesList } from "../../../Redux/Actions/adddCreateBatchAction";

export function LinkIOTtoFacilityFillingLines({ allFacilities }) {
  //Datas
  // React.useEffect(() => {
  //   console.log("allFacilities check");
  //   console.log(allFacilities);
  // }, [allFacilities]);
  const data = [
    {
      line_id: "PDW83WFS2",
      iot_device_number: "",
      is_active: true,
      created_at: "2023-10-28T08:58:43.000Z",
    },
    {
      line_id: "PDW83WFS3",
      iot_device_number: "",
      is_active: true,
      created_at: "2023-10-31T06:03:07.000Z",
    },
    {
      line_id: "PDW83WFS1",
      iot_device_number: "TN01BW0055",
      is_active: true,
      created_at: "2023-10-28T08:58:27.000Z",
    },
  ];

  const datam = {
    lineIds: ["PDW1WFS1", "PDW1WFS2", "PDW1WFS3", "PDW1WFS4", "PDW1WFS5"],
    iotDeviceNumbers: [null, null, null, "DEVICE49", "DEVICE28"],
  };

  const addFillingLinesFacilities = useSelector(
    (state) => state.addFillingLinesFacilities
  );
  const WfsData = useSelector((state) => state.batchData);
  const [selectFacility, setSelectFacility] = React.useState("");
  const [lineId, setLineId] = React.useState("");
  const [flagPrint, setFlagPrint] = React.useState(false);
  const [deviceNumber, setDeviceNumber] = React.useState("");

  const [lineIds, setLineIds] = React.useState([]);
  const [iotDevices, setIotDevices] = React.useState([]);
  const dispatch = useDispatch();

  // console.log("iotDevices", iotDevices);
  React.useEffect(() => {
    dispatch(
      GetIotDevicesList({
        page_number: 1,
        page_size: 500,
      })
    );
  }, []);

  React.useEffect(() => {
    // console.log("addFillingLinesFacilities");
    // console.log(addFillingLinesFacilities);
    if (addFillingLinesFacilities.error === false) {
      // console.log(
      //   "addFillingLinesFacilities",
      //   addFillingLinesFacilities.iotDevices
      // );
      setLineIds(addFillingLinesFacilities.lines.lineIds);
      setIotDevices(addFillingLinesFacilities.iotDevices);
      console.log("iotDevices", iotDevices);
    }
  }, [addFillingLinesFacilities]);

  React.useEffect(() => {
    console.log("selectFacility");
    console.log(selectFacility);
    dispatch(GetIotDevicesFacility(selectFacility));
    if (selectFacility !== "") {
      dispatch(GetLinesFacility(selectFacility));
    }
  }, [selectFacility]);

  const handleClickLink = (data) => {
    const reqObj = {
      facility_id: parseInt(data.facility),
      line_id: data.lineID,
      iot_device_number: data.device_id,
    };
    console.log("reqObj");
    console.log(reqObj);
    dispatch(UpdateLinesFacility(reqObj));
  };

  const handleFacilityChange = (event) => {
    const selectedFacility = event.target.value;
    setSelectFacility(selectedFacility);
  };

  const iotdeviceIds = [{ device_id: "TN01BW0066" }];

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Paper elevation={23}>
            <Box sx={{ padding: "10px", minWidth: 150 }}>
              <CustomForm
                handleSubmit={(formData) => {
                  handleClickLink(formData);
                }}
                data={[
                  {
                    data: [
                      {
                        inputType: appMeta.inputFieldTypes.SELECT,
                        placeHolder: "Select Facility",
                        onChange: (name, value) => {
                          setSelectFacility(value);
                        },
                        name: "facility",
                        input: getInputForSelectFromArray(
                          allFacilities?.data,
                          "org_name",
                          "id"
                        ),
                      },
                      {
                        inputType: appMeta.inputFieldTypes.SELECT,
                        placeHolder: "Select Line ID",
                        name: "lineID",
                        input: lineIds?.map((line) => {
                          return { menuItem: line, value: line };
                        }),
                      },
                      {
                        inputType: appMeta.inputFieldTypes.SELECT,
                        placeHolder: "Select Iot Device Id",
                        name: "device_id",
                        input: WfsData?.IotData?.map((line) => {
                          console.log("----iotdeviceIds----", line);
                          return {
                            menuItem: line.device_id,
                            value: line.device_id,
                          };
                        }),
                      },
                    ],
                  },
                ]}
              />
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Facility
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label=" Select Facility"
                  value={selectFacility}
                  onChange={handleFacilityChange}
                >
                  {allFacilities.map((facility) => {
                    return (
                      <MenuItem value={facility.id}>
                        {facility.org_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
            </Box>
            {/* <Box sx={{ padding: "10px", minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Line ID
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Line Id"
                  value={lineId}
                  onChange={(event) => {
                    setLineId(event.target.value);
                  }}
                >
                  {lineIds?.map((line) => {
                    return <MenuItem value={line}>{line}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ padding: "10px", minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select IOT Device
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Filling Station"
                  value={deviceNumber}
                  onChange={(event) => {
                    setDeviceNumber(event.target.value);
                  }}
                >
                  {iotDevices?.map((iot) => {
                    return <MenuItem value={iot}>{iot}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <CustomButton
                label="Link"
                variant="contained"
                handleClick={handleClickLink}
              />
            </Box> */}
          </Paper>
        </Box>
      </>
    </>
  );
}
