/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "../actionTypes";

const initialProfileState = {
  error: true,
  fileName: "",
  msg: "",
};

const fileHandlingReducer = (state = initialProfileState, action) => {
  console.log(action);
  switch (action.type) {
    case actionType.GET_FILES:
      return {
        error: action.payload.data.error,
        fileName: action.payload.data.data.file_name,
      };
    case actionType.UPLOAD_FILE:
      return {
        error: action.payload.data.error,
        fileName: action?.payload?.data?.data?.file_name,
      };

    default:
      return state;
  }
};

export default fileHandlingReducer;
