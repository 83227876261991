import axios from "axios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import actionType from "../actionTypes";

export const CreateFacilityAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  console.log(reqObj);

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/POPM/containers/facility/create_order`,
        reqObj
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CREATE_FACILITY_ORDER,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
export const GetFacilityAction = (props) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/POPM/containers/facility/get_orders`, {
        params: props,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_FACILITY_ORDER,
          payload: response.data,
        });
        if (response.data.msg) {
          // toast.success(response.data.msg, {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        } else {
          // toast.success(response.data.msg, {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };
};
export const CancelFacilityAction = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  console.log(reqObj);

  return async (dispatch) => {
    try {
      let response = await api.put(
        `/POPM/containers/facility/cancel_order`,
        reqObj
      );
      if (response.status == httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CANCEL_FACILITY_ORDER,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

const facitlityApis = {
  // get_facility_order
  get_facility_order: {
    api_name: "/POPM/containers/facility/get_orders",
    https_method: "GET",
    action_type: actionType.GET_FACILITY_ORDER,
    description: "",
  },
  //cancel order
  cancel_facility_order: {
    api_name: "/POPM/containers/facility/cancel_order",
    https_method: "GET",
    action_type: actionType.CANCEL_FACILITY_ORDER,
    description: "",
  },
};
export default facitlityApis;
