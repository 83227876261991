/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Actions for create, update and get for organization details
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import axios from "axios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import actionType from "../actionTypes";
import { toast } from "react-toastify";

export const CreateOrganization = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  console.log(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/PSCM/organization`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADD_ORGANIZATION,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
        toast.error(response.data.msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetOrganizationList1 = (
  page_number,
  page_size,
  isActive,
  ascOrDesc
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/PSCM/organization?created_at_sort=${
          ascOrDesc === null ? "" : ascOrDesc
        }&page_number=${page_number}&page_size=${page_size}&is_active=${
          isActive === null ? "" : isActive
        }`
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ORGANIZATION_LIST,
          payload: response.data,
        });
        if (response.data.msg) {
          // toast.success(response.data.msg, {
          //   position: "top-right",
          //   autoClose: 1000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
        }
      } else {
        // toast.error(response.data.msg, {
        //   position: "top-right",
        //   autoClose: 1000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const GetOrganizationList = (
  page_number,
  page_size,
  isActive,
  ascOrDesc
) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  let reqObj = {}; // Initialize request object

  if (
    page_size !== undefined &&
    isActive !== undefined &&
    page_number !== undefined &&
    ascOrDesc !== undefined
  ) {
    reqObj = {
      page_number,
      page_size,
      is_active: isActive,
      created_at_sort: ascOrDesc,
    };
  }

  return async (dispatch) => {
    try {
      const response = await api.get("/PSCM/organization", {
        params: {
          ...reqObj,
          created_at_sort: ascOrDesc === null ? "" : ascOrDesc,
          is_active: isActive === null ? "" : isActive,
        },
      });

      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ORGANIZATION_LIST,
          payload: { data: response.data, error: false },
        });
      } else {
        // Handle error cases
      }
    } catch (error) {
      console.log(error);
      // Handle error cases
    }
  };
};

export const UpdateOrganization = (reqObj) => {
  console.log("---API entry----");
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.put(`/PSCM/organization`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        console.log("---API Success----");

        dispatch({
          type: actionType.UPDATE_ORGANIZATION,
          payload: { data: response.data, error: false },
        });
        if (response.data.msg) {
          toast.success(response.data.msg, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        // setUpdateError(true);

        toast.error(response.data.msg, {
          position: "top-left",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      console.log("---API error----");
      dispatch({
        type: actionType.UPDATE_ORGANIZATION,
        payload: { data: [], error: true },
      });
      toast.error(error.response?.data?.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};
