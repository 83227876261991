import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
  Avatar,
  Button,
  useTheme,
  TablePagination,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { appColors } from "../../AppMeta/appColors";
import EditDialogCrates from "./EditDialogCrates";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";
import CustomTable from "../../Components/CustomComp/CustomTable";

// const data = [
//   {
//     id: "27",
//     name: "200 ML steel bottle (M)",
//     description: "200 ML steel Bottle crate (M)",
//     price: 40,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "080319",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-01T10:54:19.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 6,
//     image: "jKvKcduFr.png",
//     updated_at: "2024-02-01T05:24:19.704Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "23",
//     name: "500 ML Steel Bottle Crate (M)",
//     description: "9ssjhhsjhsdjhhsdjh",
//     price: 40,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "080319",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-01T01:24:40.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 5,
//     image: "b5hMOhmHF.jpg",
//     updated_at: "2024-02-01T06:55:21.000Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "41",
//     name: "50Crate",
//     description: "Crate for 250ml capacity",
//     price: 500,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "12333",
//     cgst_perc: 12,
//     sgst_perc: 12,
//     igst_perc: 12,
//     created_at: "2024-02-05T13:11:24.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 12,
//     image: "",
//     updated_at: "2024-02-05T07:41:24.893Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "9",
//     name: "Bookwater catrates",
//     description: "Plastic premium crate",
//     price: 124,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "13444",
//     cgst_perc: 12,
//     sgst_perc: 12,
//     igst_perc: 12,
//     created_at: "2024-01-23T00:55:51.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 12,
//     image: "",
//     updated_at: "2024-01-23T13:21:45.000Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "7",
//     name: "Bookwater Premium",
//     description: "Sample cate by Bookwater",
//     price: 123,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "43233",
//     cgst_perc: 12,
//     sgst_perc: 12,
//     igst_perc: 1,
//     created_at: "2024-01-23T06:22:55.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 12,
//     image: "O0l7W8cS3.png",
//     updated_at: "2024-01-23T00:52:55.615Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "42",
//     name: "BookwaterPremiumCrate",
//     description: "Bookwater Crate Details",
//     price: 200,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "32334",
//     cgst_perc: 12,
//     sgst_perc: 12,
//     igst_perc: 12,
//     created_at: "2024-02-05T13:17:37.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 12,
//     image: "",
//     updated_at: "2024-02-05T07:47:37.097Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "18",
//     name: "BW 1l plastic crate",
//     description: "BW 1l plastic crates",
//     price: 50,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "39239",
//     cgst_perc: 6,
//     sgst_perc: 6,
//     igst_perc: 6,
//     created_at: "2024-01-31T08:23:38.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 24,
//     image: "",
//     updated_at: "2024-02-01T06:27:02.000Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "25",
//     name: "BW_200ml crate",
//     description: "BW_200ml crate  ",
//     price: 40,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "70109",
//     cgst_perc: 6,
//     sgst_perc: 6,
//     igst_perc: 6,
//     created_at: "2024-02-01T01:27:53.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 12,
//     image: "R1uWqBpWG.png",
//     updated_at: "2024-02-01T10:02:25.000Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "19",
//     name: "BW_500 ml plastic crate",
//     description: "BW_500 ml plastic crate",
//     price: 50,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "39239",
//     cgst_perc: 6,
//     sgst_perc: 6,
//     igst_perc: 6,
//     created_at: "2024-02-01T01:01:41.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 24,
//     image: "5jmmZtk79.png",
//     updated_at: "2024-02-01T10:02:51.000Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "20",
//     name: "BW_500 ml wooden crate",
//     description: "BW_500 ml wooden crate",
//     price: 100,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "44151",
//     cgst_perc: 6,
//     sgst_perc: 6,
//     igst_perc: 6,
//     created_at: "2024-02-01T06:33:59.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 24,
//     image: "",
//     updated_at: "2024-02-01T01:03:59.143Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "22",
//     name: "BW_common crate 500ml/1l",
//     description: "common crate 500ml/1l",
//     price: 100,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "39239",
//     cgst_perc: 6,
//     sgst_perc: 6,
//     igst_perc: 6,
//     created_at: "2024-02-01T06:49:32.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 36,
//     image: "",
//     updated_at: "2024-02-01T01:19:33.014Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "13",
//     name: "cratePremium",
//     description: "wdgvfcdsfdcxsfdrfds",
//     price: 123,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "1234321",
//     cgst_perc: 12,
//     sgst_perc: 12,
//     igst_perc: 1,
//     created_at: "2024-01-23T11:46:55.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 122,
//     image: "zjJdLAT26.jpg",
//     updated_at: "2024-01-23T06:16:55.272Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "40",
//     name: "For add more",
//     description: "98675dtyfgufddfgfh",
//     price: 500,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "75678",
//     cgst_perc: 7,
//     sgst_perc: 7,
//     igst_perc: 14,
//     created_at: "2024-02-02T12:42:26.000Z",
//     created_by: "diEc2Wi8O",
//     capacity: 6,
//     image: "",
//     updated_at: "2024-02-02T07:12:26.965Z",
//     updated_by: "diEc2Wi8O",
//   },
//   {
//     id: "28",
//     name: "MCrate 1",
//     description: "MCrate 1 liter ",
//     price: 45,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "99999",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:27:11.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T06:57:11.923Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "37",
//     name: "Mcrate 11",
//     description: "Mcrate 11 liter ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "929292",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:35:17.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T07:05:17.586Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "38",
//     name: "Mcrate 12",
//     description: "Mcrate 12 liter ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "922929",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:36:24.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 20,
//     image: "",
//     updated_at: "2024-02-02T07:06:24.057Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "39",
//     name: "MCrate 13",
//     description: "MCrate 13 jdjdjdj",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "929292",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T07:08:43.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T13:12:54.000Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "29",
//     name: "MCrate 2",
//     description: "MCrate 2 liter ",
//     price: 30,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "939393",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:28:48.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T06:58:48.136Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "30",
//     name: "MCrate 3",
//     description: "Mcrate 3 liter ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "929292",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:29:51.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T06:59:51.319Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "31",
//     name: "MCrate 4",
//     description: "MCrate 4 liter  ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "929292",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:30:38.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T07:00:38.219Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "32",
//     name: "MCrate 5",
//     description: "MCrate 5 liter  ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "929292",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:31:26.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T07:01:26.820Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "33",
//     name: "MCrate 6",
//     description: "CMcrate 7 liter ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "828282",
//     cgst_perc: 2,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:32:21.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T07:02:21.556Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "34",
//     name: "MCrate 7",
//     description: "MCrate 7 liter ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "292929",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:33:08.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T07:03:08.832Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "35",
//     name: "MCrate 8",
//     description: "Mcrate 8 liter ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "992929",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:33:47.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 2,
//     image: "",
//     updated_at: "2024-02-02T07:03:47.031Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "36",
//     name: "MCrate 9",
//     description: "MCrate 9 liter ",
//     price: 20,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "929292",
//     cgst_perc: 9,
//     sgst_perc: 9,
//     igst_perc: 9,
//     created_at: "2024-02-02T12:34:31.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 20,
//     image: "",
//     updated_at: "2024-02-02T07:04:31.762Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "17",
//     name: "One bottle down crate",
//     description: "kuch add kiya jaye",
//     price: 200,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "39233",
//     cgst_perc: 20,
//     sgst_perc: 30,
//     igst_perc: 1,
//     created_at: "2024-02-01T05:26:44.000Z",
//     created_by: "diEc2Wi8O",
//     capacity: 3,
//     image: "KS4Kagr09.jpg",
//     updated_at: "2024-01-31T23:56:44.623Z",
//     updated_by: "diEc2Wi8O",
//   },
//   {
//     id: "6",
//     name: "Plasticd",
//     description: "You can return the glass bottle and earn the Rewards",
//     price: 180,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "125466",
//     cgst_perc: 6,
//     sgst_perc: 6,
//     igst_perc: 2,
//     created_at: "2024-01-22T04:13:27.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 1,
//     image: "",
//     updated_at: "2024-01-22T12:41:05.000Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "12",
//     name: "premium",
//     description: "bvcdgvfcdxsgvfcdx",
//     price: 245,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "33233",
//     cgst_perc: 12,
//     sgst_perc: 12,
//     igst_perc: 1,
//     created_at: "2024-01-23T11:46:00.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 22,
//     image: "zjJdLAT26.jpg",
//     updated_at: "2024-01-23T06:16:00.313Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "1",
//     name: "Sttrong Paper",
//     description: "You can return the glass bottle and earn the Rewards",
//     price: 70,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "125466",
//     cgst_perc: 6,
//     sgst_perc: 6,
//     igst_perc: 2,
//     created_at: "2023-12-19T10:36:15.000Z",
//     created_by: "TbUjhM7nr",
//     capacity: 1,
//     image: "",
//     updated_at: "2024-01-22T03:39:41.132Z",
//     updated_by: "",
//   },
//   {
//     id: "15",
//     name: "test add crate",
//     description: "yrtfytvyeteryetry",
//     price: 10,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "67658",
//     cgst_perc: 4,
//     sgst_perc: 7,
//     igst_perc: 7,
//     created_at: "2024-01-23T02:15:06.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 199,
//     image: "gPOO78-3H.png",
//     updated_at: "2024-01-23T13:16:31.000Z",
//     updated_by: "Th3HxTlYA",
//   },
//   {
//     id: "16",
//     name: "WaterCrate",
//     description: "fbgdbgdbgdfsdvdvfv",
//     price: 122,
//     is_active: true,
//     is_deleted: false,
//     hsn_code: "23321",
//     cgst_perc: 12,
//     sgst_perc: 14,
//     igst_perc: 12,
//     created_at: "2024-01-31T10:18:37.000Z",
//     created_by: "Th3HxTlYA",
//     capacity: 12,
//     image: "",
//     updated_at: "2024-01-31T04:48:37.277Z",
//     updated_by: "Th3HxTlYA",
//   },
// ];

const Crates = ({ allCrates, setDrawOpenProp }) => {
  // const [open, setOpen] = useState(Array(data.length).fill(false));
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  // const handleClick = (index) => {
  //   const newOpen = [...open];
  //   newOpen[index] = !newOpen[index];
  //   setOpen(newOpen);
  // };

  const handleEditClick = (item) => {
    setCurrentItem(item);
    setEditDialogOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setEditDialogOpen(false);
    setCurrentItem(null); // Clear the current item when dialog is closed
  };

  const handleCratesFilterClose = ({ value, searchText }) => {};

  const handleCratesSortClose = ({ value, searchText }) => {};

  const handleAddCrates = () => {
    setDrawOpenProp(true);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={appStyle.general.table.containerForTable}>
      <CustomTable
        tableHead={[
          "",
          "Name",
          "Capacity",
          "Material",
          "Life Span",
          "Order Type",
          "Image",
        ]}
        tableDataKey={[
          "",
          "name",
          "capacity",
          {
            type: "text",
            component: () => {
              return "Material Placeholder";
            },
          },
          {
            type: "text",
            component: () => {
              return "Life span space holder";
            },
          },
          {
            type: "text",
            component: () => {
              return "Order type placeholder";
            },
          },
          {
            type: "text",
            component: (row) => {
              return (
                !row.image && <Avatar src={`/path/to/images/${row.image}`} />
              );
            },
          },
        ]}
        tableData={allCrates}
        tableRowClickFunction={(index, row) => handleEditClick(row)}
        pagination={true}
        filter={true}
        filterSelectOption={[
          { value: "Roll Name", label: "role_name" },
          { value: "Full Name", label: "fullname" },
        ]}
        handleFilterClose={handleCratesFilterClose}
        sort={true}
        sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
        handleSortClose={handleCratesSortClose}
        addButton={true}
        handleOnClickAddButton={handleAddCrates}
        // setPageNumber
        // setPageSize
      />
      {/* <TableContainer
        sx={{
          borderRadius: "10px",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell} />
              <TableCell sx={appStyle.general.table.headderRowCell}>
                S.no
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Name
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Capacity
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Material
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Life Span
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Order Type
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Image
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.primary[600] }}>
            {(rowsPerPage > 0
              ? allCrates.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : allCrates
            ).map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleClick(index)}
                    >
                      {open[index] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={appStyle.general.table.bodyRowCell}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.name}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.capacity}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    Material Placeholder
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {" "}
                    Life Span Placeholder
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    Order Type Placeholder
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.image ? (
                      <Avatar src={`/path/to/images/${row.image}`} />
                    ) : (
                      <Avatar>{row.name.charAt(0)}</Avatar>
                    )}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    <Button
                      color={appFonts.tablePrimaryButtonColor}
                      variant={appFonts.tablePrimaryButtonVarient}
                      onClick={() => handleEditClick(row)}
                      sx={appStyle.general.button.editButton}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={9}
                    sx={{
                      //  fontSize: { xs: "0.8rem", md: "0.9rem" },
                      backgroundColor: colors.primary[600],
                      textAlign: "start",
                    }}
                  >
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Details
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                HSN
                              </TableCell>
                              <TableCell>{row.hsn_code}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>CGST%</TableCell>
                              <TableCell>{row.cgst_perc}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>SGST%</TableCell>
                              <TableCell>{row.sgst_perc}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>IGST%</TableCell>
                              <TableCell>{row.igst_perc}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>SKU qty Placeholder</TableCell>
                              <TableCell>N/A</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Max Book qty Placeholder</TableCell>
                              <TableCell>N/A</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Min Book qty Placeholder</TableCell>
                              <TableCell>N/A</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={appStyle.general.table.pagination}>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000]}
          component="div"
          count={allCrates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box> */}

      {currentItem && (
        <EditDialogCrates
          open={editDialogOpen}
          onClose={handleClose}
          item={currentItem}
        />
      )}
    </Box>
  );
};

export default Crates;
