/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Dialog box for editing any organization details
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Box,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
// import { UpdateOrganization } from "../../Redux/Actions/organizationAction";
import {
  CreateOrganization,
  GetOrganizationList,
  UpdateOrganization,
} from "../../../Redux/Actions/organizationAction";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import appMeta from "../../../AppMeta/appMeta";
import CustomForm from "../../../Components/CustomComp/CustomForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditOrgDialogBox(props) {
  // Required variable declared
  const dispatch = useDispatch();
  const [orgId, setOrgId] = React.useState(props.selectedRow?.id);
  const [orgName, setOrgName] = React.useState(props.selectedRow?.org_name);
  const [isForceUpdate, setIsForceUpdate] = React.useState(false);
  const [domain, setDomain] = React.useState(props.selectedRow?.domain);
  console.log("selected Org row", props.selectedRow);
  const [isActive, setIsActive] = React.useState(props.selectedRow?.is_active);
  const [isDeleted, setIsDeleted] = React.useState(
    props.selectedRow?.is_deleted
  );

  const [isEditOrgId, setIsEditOrgId] = React.useState(false);
  const [isEditOrgName, setIsEditOrgName] = React.useState(false);
  const [isEditOrgDomain, setIsEditOrgDomain] = React.useState(false);

  const [isValidOrgId, setIsValidOrgId] = React.useState(true);
  console.log("selectedRow", props.selectedRow);
  const handleClose = () => {
    props.setEditOrgDialog(false);
  };
  // Handling the change in organization ID
  const handleChangeOrgId = (event) => {
    const newValue = event.target.value;
    setOrgId(newValue);
    console.log(newValue);
  };
  // Handling the change in Organization Name
  const handleChangeOrgName = (event) => {
    const newValue = event.target.value;
    setOrgName(newValue);
    console.log(newValue);
  };
  // Handling the change in Domain
  const handleChangeDomain = (event) => {
    const newValue = event.target.value;
    setDomain(newValue);
    console.log(newValue);
  };
  // This function will call the Edit organization API call with request object
  const handleConfirmEdit = (formData) => {
    props.handleConfirmEdit({
      org_id: props.selectedRow?.id,
      org_name: props.selectedRow?.org_name,
      is_force_update: formData.isConfirmed,
      domain: props.selectedRow?.domain,
      new_domain:
        props.selectedRow?.domain !== formData.domain
          ? formData.domain
          : undefined,
      new_org_name:
        props.selectedRow?.org_name !== formData.org_name
          ? formData.org_name
          : undefined,
      is_active: formData.is_active,
      is_deleted: formData.is_deleted,
    });
    // const reqObj = {
    //   org_id: orgId,
    //   org_name: selectedRow?.org_name,
    //   is_force_update: isForceUpdate,
    //   domain: selectedRow?.domain,
    //   new_domain: isEditOrgDomain ? domain : undefined,
    //   new_org_name: isEditOrgName ? orgName : undefined,
    //   is_active: isActive,
    //   is_deleted: isDeleted,
    // };
    // dispatch(UpdateOrganization(reqObj));
    // setTimeout(() => {
    //   props.getAllOrganization();
    //   // props.setEditOrgDialog(false);
    // }, 1000);
  };

  // Toggle button for editing the fields
  const handleClickEditOrgId = () => {
    setIsEditOrgId(!isEditOrgId);
  };

  const handleClickEditOrgName = () => {
    setIsEditOrgName(!isEditOrgName);
  };

  const handleClickEditOrgDomain = () => {
    setIsEditOrgDomain(!isEditOrgDomain);
  };

  return (
    <div>
      <Drawer
        open={props.editOrgDialog}
        anchor="right"
        PaperProps={{
          sx: {
            width: 250, // Set the width here
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>Edit Organization</DialogTitle> */}
        {/* <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            padding: "20px",
          }}
        > */}
        <Typography
          variant="h4"
          textAlign="center"
          m="10px"
          sx={{ fontWeight: 700 }}
        >
          Edit Facility
        </Typography>
        <Divider sx={{ border: "2px solid #e3e9f3" }} />
        <CustomForm
          configConfirmation={{
            isConfirmationRequired: true,
            dialogContent: "Are you sure you want to edit data?",
          }}
          handleSubmit={handleConfirmEdit}
          handleClose={handleClose}
          data={[
            {
              data: [
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Organization ID",
                  defaultValue: props.selectedRow?.id,
                  name: "id",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                  disabled: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Organization Name",
                  defaultValue: props.selectedRow?.org_name,
                  name: "org_name",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Organization Domain",
                  defaultValue: props.selectedRow?.domain,
                  name: "domain",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.SWITCH,
                  placeHolder: {
                    on: "Active",
                    off: "Active",
                  },
                  name: "is_active",
                  defaultValue: props.selectedRow.is_active,
                },
                {
                  inputType: appMeta.inputFieldTypes.SWITCH,
                  placeHolder: {
                    on: "Delete",
                    off: "Delete",
                  },
                  name: "is_deleted",
                  defaultValue: props.selectedRow.is_deleted,
                },
              ],
            },
          ]}
        />
        {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Organization Id
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={orgId}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditOrgId}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditOrgId ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditOrgId && (
            <CustomInputTextField
              placeholder="Organization Id"
              size="medium"
              value={orgId}
              setValue={setOrgId}
              isHintRequired={true}
              isValid={isValidOrgId}
              setIsValid={setIsValidOrgId}
              type={appMeta.textFieldTypes.NUMBER}
              // handleChange={handleChangeOrgId}
            />
          )} */}

        {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Organization Name
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={orgName}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditOrgName}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditOrgName ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditOrgName && (
            <CustomInputTextField
              placeholder="Organization Name"
              size="medium"
              value={orgName}
              setValue={setOrgName}
              // handleChange={handleChangeOrgName}
            />
          )} */}

        {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">
              Organization Domain
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={domain}
              disabled={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEditOrgDomain}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {isEditOrgDomain ? (
                      <Tooltip title="Cancel" placement="top">
                        <CancelRoundedIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <EditRoundedIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {isEditOrgDomain && (
            <CustomInputTextField
              placeholder="Organization Domain"
              size="medium"
              value={domain}
              setValue={setDomain}
              // handleChange={handleChangeDomain}
            />
          )} */}

        {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={isForceUpdate}
                  onChange={(event) => setIsForceUpdate(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isForceUpdate
                  ? "You are force updating"
                  : "Do you want to force update"
              }
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={isActive}
                  onChange={(event) => setIsActive(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isActive
                  ? "Active now, Uncheck to deactivate."
                  : "Deactivated, Check to activate"
              }
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={isDeleted}
                  onChange={(event) => setIsDeleted(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                isDeleted
                  ? "This organization will be deleted"
                  : "Check to  delete this organization"
              }
            />
          </Box> */}
        {/* </DialogContent> */}
        {/* <DialogActions>
          <CustomButton
            handleClick={handleClose}
            label="Cancel"
            color="error"
            variant="text"
          />
          <CustomButton
            handleClick={handleConfirmEdit}
            label="Confirm"
            color="primary"
            variant="contained"
          />
        </DialogActions> */}
      </Drawer>
    </div>
  );
}
