import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Backdrop,
  Paper,
} from "@mui/material";

import React, { useState } from "react";
import { appColors } from "../../../AppMeta/appColors";
import { useDispatch } from "react-redux";
import { UpdateEmptyCanOrder } from "../../../Redux/Actions/addEmptyCanOrderAction";
import Update from "@mui/icons-material/Update";
import Cancel from "@mui/icons-material/Cancel";
import CustomButton from "../../../Components/CustomComp/CustomButton";

export function EditDialogEmptyCanOrder({ open, onClose, item }) {
  const data = [
    {
      product_details: [
        {
          id: 57,
          name: "20 Liter Water Can",
          image: "",
          price: 70,
          amount: 70,
          refill: {
            sp: 1,
            mrp: 1,
            deposit: 0,
            refund_type: 100,
            refund_amount: 0,
          },
          sku_qty: [12],
          capacity: "20 Liter",
          hsn_code: "125466",
          material: "PET MATERIAL",
          cgst_perc: 6,
          igst_perc: 2,
          life_span: 1000,
          sgst_perc: 6,
          created_at: "15:03:11.44838",
          order_type: 100,
          updated_at: "2024-02-26T15:03:11.44838",
          description: "20 liter water can",
          booking_type: 1,
          max_book_qty: 1,
          min_book_qty: 1,
          one_time_sale: {
            sp: 1,
            mrp: 1,
            deposit: 0,
            refund_type: 100,
            refund_amount: 0,
          },
          facility_price: null,
        },
      ],
      crate_details: [
        {
          id: null,
          name: null,
          image: null,
          price: null,
          capacity: null,
          description: null,
        },
      ],
    },
    {
      product_details: [
        {
          id: 53,
          name: "20 Liter water can New",
          image: "",
          price: 180,
          amount: 180,
          refill: {
            sp: 55,
            mrp: 100,
            deposit: 200,
            refund_type: 100,
            refund_amount: 180,
          },
          sku_qty: [1],
          capacity: "20 Liter",
          hsn_code: "341231",
          material: "PET NEW",
          cgst_perc: 4,
          igst_perc: 8,
          life_span: 100,
          sgst_perc: 4,
          created_at: "17:02:28.015354",
          order_type: 400,
          updated_at: "2024-02-21T17:02:28.015354",
          description: "Glass bottle",
          booking_type: 3,
          max_book_qty: 10000,
          min_book_qty: 10,
          one_time_sale: {
            sp: 200,
            mrp: 250,
            deposit: 200,
            refund_type: 100,
            refund_amount: 180,
          },
          facility_price: {
            deposit_price: 200,
          },
        },
        {
          id: 53,
          name: "20 Liter water can New",
          image: "",
          price: 180,
          amount: 180,
          refill: {
            sp: 55,
            mrp: 100,
            deposit: 200,
            refund_type: 100,
            refund_amount: 180,
          },
          sku_qty: [1],
          capacity: "20 Liter",
          hsn_code: "341231",
          material: "PET NEW",
          cgst_perc: 4,
          igst_perc: 8,
          life_span: 100,
          sgst_perc: 4,
          created_at: "17:02:28.015354",
          order_type: 400,
          updated_at: "2024-02-21T17:02:28.015354",
          description: "Glass bottle",
          booking_type: 3,
          max_book_qty: 10000,
          min_book_qty: 10,
          one_time_sale: {
            sp: 200,
            mrp: 250,
            deposit: 200,
            refund_type: 100,
            refund_amount: 180,
          },
          facility_price: {
            deposit_price: 200,
          },
        },
      ],
      crate_details: [
        {
          id: 23,
          name: "PLASTIC",
          image: "",
          price: 180,
          capacity: 1,
          description: "You can return the glass bottle and earn the Rewards",
        },
        {
          id: 22,
          name: "PAPER",
          image: "",
          price: 70,
          capacity: 1,
          description: "You can return the glass bottle and earn the Rewards",
        },
      ],
    },
    {
      product_details: [
        {
          id: 50,
          name: "500ml Glass Bottle",
          image: "",
          price: 1,
          amount: 1,
          refill: {
            sp: 1,
            mrp: 1,
            deposit: 1,
            refund_type: 100,
            refund_amount: 1,
          },
          sku_qty: [10],
          capacity: "500ml",
          hsn_code: "53412312",
          material: "GLASS",
          cgst_perc: 4,
          igst_perc: 8,
          life_span: 100,
          sgst_perc: 4,
          created_at: "16:29:06.538468",
          order_type: 400,
          updated_at: "2024-02-21T16:29:06.538468",
          description: "Glass Bottle",
          booking_type: 1,
          max_book_qty: 1000,
          min_book_qty: 10,
          one_time_sale: {
            sp: 1,
            mrp: 1,
            deposit: 1,
            refund_type: 100,
            refund_amount: 1,
          },
          facility_price: null,
        },
      ],
      crate_details: [
        {
          id: null,
          name: null,
          image: null,
          price: null,
          capacity: null,
          description: null,
        },
      ],
    },
    {
      product_details: [
        {
          id: 3,
          name: "750ml Glass Bottle-3",
          image: "",
          price: 70,
          amount: 70,
          refill: {
            sp: 1,
            mrp: 1,
            deposit: 0,
            refund_type: 100,
            refund_amount: 0,
          },
          sku_qty: [12],
          capacity: "750ml-3",
          hsn_code: "125466",
          material: "GLASS",
          cgst_perc: 6,
          igst_perc: 2,
          life_span: 1000,
          sgst_perc: 6,
          created_at: "16:32:26.72659",
          order_type: 100,
          updated_at: "2024-02-06T16:32:26.72659",
          description: "You can return the glass bottle and earn the Rewards",
          booking_type: 1,
          max_book_qty: 1,
          min_book_qty: 1,
          one_time_sale: {
            sp: 1,
            mrp: 1,
            deposit: 0,
            refund_type: 100,
            refund_amount: 0,
          },
          facility_price: null,
        },
      ],
      crate_details: [
        {
          id: null,
          name: null,
          image: null,
          price: null,
          capacity: null,
          description: null,
        },
      ],
    },
  ];
  const dispatch = useDispatch();
  //1.useState Hook
  const [orderId, setOrderId] = useState("");
  const [containerId, setContainerId] = useState("");
  const [containerMaterial, setContainerMaterial] = useState("");
  const [containerCapacity, setContainerCapacity] = useState("");
  const [returnQuantity, setReturnQuantity] = useState("");
  const [orderQuantity, setOrderQuantity] = useState("");
  const [productName, setProductName] = useState("");

  const [name, setName] = useState("");
  const [orderQuantity1, setOrderQuantity1] = useState([]);

  //2.useEffect
  React.useEffect(() => {
    console.log("item1");
    console.log(item);
    if (item) {
      setOrderId(item.ec_order_id);
      setContainerId(item.container_id);
      setContainerMaterial(item.container_material);
      setContainerCapacity(item.container_capacity);
      setOrderQuantity(item.new_container_qty);
      setReturnQuantity(item.return_containers_submitting);

      // Checking the container id and setting the name
      data.map((product) => {
        console.log("product");
        console.log(
          product.product_details[0].name,
          product.product_details[0].id
        );
        if (item.container_id == product.product_details[0].id) {
          console.log(product.product_details[0].name);
          setProductName(product.product_details[0].name);
        }
      });

      console.log("items");
      console.log(item);

      console.log("item.crates");
      // console.log(item.crates[0].id);
    }
  }, [item]);

  React.useEffect(() => {
    const orderQuantities = item.crates.map((crate) => crate.order_qty);
    setOrderQuantity1(orderQuantities);
  }, [item.crates]);

  const handleOrderQuantityChange = (index) => (event) => {
    const newOrderQuantities = [...orderQuantity1];
    newOrderQuantities[index] = event.target.value;
    setOrderQuantity1(newOrderQuantities);
  };

  //3.Functions
  const handleUpdate = () => {
    const reqObj = {
      orderId: item.ec_order_id,
      containerId: item.container_id,
      containerMaterial: item.container_material,
      containerCapacity: item.container_capacity,
      orderQuantity: item.new_container_qty,
      returnQuantity: item.return_containers_submitting,
      crates: {
        name: item.crates.container_material,
        orderQuantity1: item.crates.order_qty,
      },
    };
    console.log("reqObj");
    console.log(reqObj);
    dispatch(UpdateEmptyCanOrder(reqObj));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle
          id="form-dialog-title"
          variant="h2"
          sx={{
            fontWeight: "bold",
          }}
        >
          Edit Product
        </DialogTitle>
        <Divider sx={{ margin: "10px" }} />
        <Box>
          <DialogContent
            sx={{
              // margin: "10px",
              padding: "10px",
              // backgroundImage:
              //   "linear-gradient(-216deg, rgba(255,254,254,1) 64%, rgba(8,144,226,1) 71%, rgba(51,183,251,1) 75%, rgba(51,255,251,1) 79%, rgba(255,255,255,1) 83%, rgba(80,146,251,1) 89%, rgba(80,146,255,1) 93%)",
            }}
          >
            <Paper
              sx={{
                padding: "20px",
                boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                sx={{
                  mt: 2,
                  mb: 1,

                  fontSize: "30px",
                  fontWeight: "Regular 400",
                }}
                margin="dense"
              >
                {/* {product.product_details[0].name} */}
                {productName}
              </Typography>

              <TextField
                margin="dense"
                label="Container Material"
                fullWidth
                variant="outlined"
                sx={{ fontFamily: "Montserrat" }}
                value={containerMaterial}
                onChange={(e) => setContainerMaterial(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Container Capacity"
                type="text"
                fullWidth
                variant="outlined"
                value={containerCapacity}
                onChange={(e) => setContainerCapacity(e.target.value)}
              />
              <TextField
                margin="dense"
                id="cgst_perc"
                label="Order Quantity"
                fullWidth
                variant="outlined"
                onChange={(e) => setOrderQuantity(e.target.value)}
                value={orderQuantity}
              />
              <TextField
                margin="dense"
                id="sgst_perc"
                label="Return Quantity"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => setReturnQuantity(e.target.value)}
                value={returnQuantity}
              />

              <TableContainer>
                <Table sx={{ border: 2 }}>
                  <TableHead sx={{ backgroundColor: appColors.secondary }}>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.crates.map((crate, index) => (
                      <TableRow key={crate.id}>
                        <TableCell>{crate.container_material}</TableCell>
                        <TextField
                          value={orderQuantity1[index]}
                          onChange={handleOrderQuantityChange(index)}
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <DialogActions>
              <CustomButton
                btnName="Update"
                size="large"
                color="error"
                handleClick={onClose}
                startIcon={<Cancel />}
              />
              <CustomButton
                btnName="Update"
                size="large"
                handleClick={handleUpdate}
                startIcon={<Update />}
              />
            </DialogActions>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
