import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Paper,
  Box,
  Dialog,
  Menu,
  Chip,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AddFacilityInchargeDomain from "../../../Redux/Reducers/addFacilityInchargeReducers";
import appStyle from "../../../AppMeta/appStyle";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import userManagementApis from "../../../Redux/Actions/userManagement";
import CustomForm from "../../../Components/CustomComp/CustomForm";
import appMeta from "../../../AppMeta/appMeta";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px",
    gap: "10px",
    // gap: theme.spacing(2),
    maxWidth: "400px",
    margin: "0 auto",
  },
  // button: {
  //   alignSelf: "flex-end",
  // },
}));

export function FacilityInchargeDetails({
  addFacilityInchargeDialog,
  setAddFacilityInchargeDialog,
  facility,
  setRoleSearch,
  listOfRoles,
}) {
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.rolesData);

  const addUserRolesDomainDetails = useSelector(
    (state) => state.addUserRolesDomainDetails
  );
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    roleName: "",
    organizationName: "",
    fullName: "",
    email: "",
    phone: "",
  });

  // Required variabled declared here
  const [addUserDialog, setAddUserDialog] = React.useState(false);
  const [userDetailsList, setUserDetailsList] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [isActive, setIsActive] = React.useState(true);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [password, setPassword] = React.useState("");

  // For checking the validation for textfield variables
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidPhone, setInvalidPhone] = React.useState(false);

  const [rolesList, setRolesList] = React.useState([]);
  const [lastMore, setLastMore] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    console.log(rolesData);
    if (rolesData.error === false) {
      if (rolesData?.rolesList?.data?.length > 0) {
        console.log(rolesData.rolesList.data);
        // setRolesList(rolesData.rolesList.data);
        setLastMore(rolesData.rolesList.last_page_number);
        setRolesList((prevRolesList) =>
          prevRolesList
            ? [...prevRolesList, ...rolesData.rolesList.data]
            : rolesData.rolesList.data
        );
      }
    }
  }, [rolesData]);

  React.useEffect(() => {
    console.log("Facility");
    console.log(facility);
  });

  // Hitting the GET Roles API to show all the role for the user to select
  React.useEffect(() => {}, [pageNumber, pageSize, ascOrDesc, isActive]);

  // To access the roles reducer store data
  React.useEffect(() => {
    console.log(addUserRolesDomainDetails);
    if (addUserRolesDomainDetails.error === false) {
      setLastPageNumber(addUserRolesDomainDetails.userDetails.last_page_number);
      if (addUserRolesDomainDetails?.userDetails?.data?.length > 0) {
        setUserDetailsList(addUserRolesDomainDetails.userDetails.data);
      }
    }
  }, [addUserRolesDomainDetails]);

  // Checking the validation for email and phone number
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  // Common handle change function for textfields for adding users
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    // Email validation check
    if (name === "email") {
      if (!isValidEmail(value)) {
        console.log("Invalid email");
        setInvalidEmail(true);
      } else {
        setInvalidEmail(false);
      }
    }
    // Phone number validation check
    if (name === "phone") {
      if (!isValidPhoneNumber(value)) {
        console.log("Invalid phone Number");
        setInvalidPhone(true);
      } else {
        setInvalidPhone(false);
      }
    }
    // Setting the formvalues
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // This function will hit the Add User API with Request object required
  const handleSubmit = (formData) => {
    // Handle form submission here

    const reqObj = {
      role_name: formData.role_name,
      org_name: facility.org_name,
      fullname: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      facility_id: facility.id,
    };
    console.log("ReqObj");
    console.log(reqObj);

    setAddFacilityInchargeDialog(false);
    dispatch(
      HandleApiActions({
        ...userManagementApis.add_facility_incharge,
        params: reqObj,
        show_toast: false,
      })
    );
    // setTimeout(() => {
    //   dispatch(AddFacilityInchargeDomain(reqObj));
    // }, 1000);
  };

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        padding: "30px",
      }}
    >
      <Dialog
        open={addFacilityInchargeDialog}
        onClose={setAddFacilityInchargeDialog}
        sx={{ borderRadius: "30px", padding: "30px" }}
      >
        <CustomForm
          handleClose={() => setAddFacilityInchargeDialog(false)}
          handleSubmit={handleSubmit}
          data={[
            {
              numberOfColumn: 1,
              data: [
                {
                  inputType: appMeta.inputFieldTypes.AUTO_COMPLETE,
                  optionKey: "role_name",
                  outputKey: "role_name",
                  searchAndUpdateAPI: (searchText) => {
                    setRoleSearch(searchText);
                  },
                  input: listOfRoles || [],
                  name: "role_name",
                  label: "Enter Role Name",
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Full Name",
                  defaultValue: "",
                  name: "fullName",
                  textFieldType: appMeta.textFieldTypes.TEXT,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Email",
                  defaultValue: "",
                  name: "email",
                  textFieldType: appMeta.textFieldTypes.EMAIL,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Phone",
                  defaultValue: "",
                  name: "phone",
                  textFieldType: appMeta.textFieldTypes.PHONE,
                  isHintRequired: true,
                  isRequired: true,
                },
                {
                  inputType: appMeta.inputFieldTypes.INPUT,
                  placeHolder: "Password",
                  defaultValue: "",
                  name: "password",
                  textFieldType: appMeta.textFieldTypes.PASSWORD,
                  isHintRequired: true,
                  isRequired: true,
                },
              ],
            },
          ]}
        />

        {/* <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box sx={{ minWidth: 120 }}>
              <Button onClick={handleOpenDropdown} variant="outlined">
                Select Role Name
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseDropdown}
              >
                {rolesList?.map((list) => (
                  <MenuItem
                    key={list.role_name}
                    onClick={() => {
                      handleCloseDropdown();
                      setSelectedRole(list.role_name);
                    }}
                  >
                    {list.role_name}
                  </MenuItem>
                ))}
                {hasMoreData && (
                  <MenuItem
                    onClick={handleLoadMore}
                    sx={{ fontWeight: "bold" }}
                    disabled={getMoreRoles < lastMore ? false : true}
                  >
                    More <ExpandMoreRoundedIcon />
                  </MenuItem>
                )}
              </Menu>
            </Box>
            {selectedRole !== "" && (
              <Box>
                <Chip
                  label={`Selected Role Name: ${selectedRole}`}
                  variant="filled"
                  color="success"
                  sx={{ fontWeight: "bold" }}
                />
              </Box>
            )}
          </Box> */}
        {/* <TextField
            label="Facility Name"
            value={facility.orgName}
            onChange={handleChange}
            required
            disabled
          /> */}

        {/* <TextField
            label="Full Name"
            name="fullName"
            value={formValues.fullName}
            onChange={handleChange}
            required
          />

          <TextField
            label="Email"
            name="email"
            type="email"
            helperText={invalidEmail === true ? "Enter Valid Email" : ""}
            error={invalidEmail}
            value={formValues.email}
            onChange={handleChange}
            required
          />

          <TextField
            label="Phone"
            name="phone"
            helperText={invalidPhone ? "Enter Valid Phone Number" : ""}
            error={invalidPhone}
            value={formValues.phone}
            onChange={handleChange}
            required
          />
          <FormControl variant="outlined" sx={{ width: "11.5rem" }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={formValues.password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <Box sx={{ display: "flex", padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={invalidEmail || invalidPhone}
              sx={appStyle.general.button.blueButton}
            >
              Submit
            </Button>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomButton
                label="Cancel"
                handleClick={() => setAddFacilityInchargeDialog(false)}
                variant="text"
                color="error"
              />
            </Box>
          </Box>
        */}
      </Dialog>
    </Box>
  );
}
